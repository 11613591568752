import Config from "../../config/env";
const { colorPrimary, colorSecondary } = Config;
export const markerTooltip = (type,color) => {
  return `<span style=\"display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${
   !!color ?color: type === "production" ? colorPrimary : colorSecondary
  };\"></span>`;
};

export const markerTooltipComp = (type,color) => {
  return <span 
  style={{display:"inline-block", marginRight:4,borderRadius:10, width:10, height:10, background:`${!!color ?color : type === "production" ? colorPrimary : colorSecondary}`}}
  >
  </span>
};

