import MainChart from "@/components/Chart";
import { parseFormatedNumber } from "@/helper/parseFormatedNumber";
import { useFontSize } from "@/hooks/useDimension";
import React, { Fragment, useMemo } from "react";
import Config from "@/config/env";
const { colorSecondary } = Config;

function CTUltilizationRate({ data, loading }) {
  const fontSize = useFontSize();
  console.log({ data });
  const max = 1724.85;
  const sumOfCapacity = 2299.8;
  const avgProd = 1039.33;

  const dataUtility = useMemo(() => {
    const maxDec = data?.max / data?.capacity_per_day;
    const sumDec = data?.capacity_per_day / data?.capacity_per_day;
    const avgPercentage = (data?.avg_per_day / data?.capacity_per_day) * 100;
    return {
      maxDec,
      sumDec,
      avgPercentage,
      capacity: data?.capacity_per_day,
      avgProd: data?.avg_per_day,
    };
  }, [data]);

  const option = {
    series: [
      {
        type: "gauge",
        min: loading ? "" : 0,
        max: loading ? "" : dataUtility.capacity,
        splitNumber: 1,
        axisLine: {
          lineStyle: {
            width: 30,
            color: [
              [loading ? 0.5 : dataUtility?.maxDec?.toFixed(2), colorSecondary],
              [loading ? 1.5 : dataUtility?.sumDec?.toFixed(2), "red"],
            ],
          },
        },
        pointer: {
          icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
          length: "70%",
          width: 12,
          offsetCenter: [0, "5%"],
          itemStyle: {
            color: "red",
          },
        },
        axisTick: {
          distance: -30,
          length: 8,
          lineStyle: {
            color: "#fff",
            width: 2,
          },
        },
        splitLine: {
          distance: -30,
          length: 30,
          lineStyle: {
            color: "#fff",
            width: 2,
          },
        },
        axisLabel: {
          color: "inherit",
          distance: 40,
          fontSize: fontSize.sm,
        },
        detail: {
          valueAnimation: true,
          fontSize: fontSize.sm,
          formatter: (params, e) => {
            return loading
              ? "Loading . . ."
              : `${params} (${dataUtility?.avgPercentage?.toFixed(2)}%)`;
          },
          color: "inherit",
          offsetCenter: [5, "75%"],
        },
        data: [
          {
            value: dataUtility?.avgProd?.toFixed(2),
          },
        ],
      },
    ],
  };
  return (
    <Fragment>
      <MainChart
        height={fontSize.md * 20}
        title={<Fragment>CT Utilization Rate</Fragment>}
        fullscreen
        option={option}
      />
    </Fragment>
  );
}

export default CTUltilizationRate;
