import { Col, Form, Input, InputNumber, Row, Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import FormPage from "@/components/pages/FormPage";
import AutoComplete from "@/components/autocomplete/Offline";
import AutoCompleteOnline from "@/components/autocomplete/Online";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { loadAirportsAp1 } from "@/redux/reducer/apps";
import { formatedOptions } from "@/helper/formatedOptions";
const DefModel = {
  airport_id: null,
  tahun: "",
  month: "",

  dom_out_rka: 0,
  dom_inc_rka: 0,
  inter_out_rka: 0,
  inter_inc_rka: 0,

  dom_out_jaster: 0,
  dom_inc_jaster: 0,
  inter_out_jaster: 0,
  inter_inc_jaster: 0,

  dom_out_jaster_rka: 0,
  dom_inc_jaster_rka: 0,
  inter_out_jaster_rka: 0,
  inter_inc_jaster_rka: 0,
  pjkp2u_out_dom_rka: 0,
  pjkp2u_inc_dom_rka: 0,
  pjkp2u_out_int_rka: 0,
  pjkp2u_inc_int_rka: 0,

  konsesi: 0,
  konsesi_rka: 0,
};

export default () => {
  const dispatch = useDispatch();
  const { airportAp1Cache } = useSelector((state) => state.apps);
  const convertOpt = formatedOptions(airportAp1Cache, ["code", "name"], "_id");
  const sizeMonth = Array.from(Array(12).keys());
  const convertOptMonth = sizeMonth.map((item) => ({
    label: moment().month(item).format("MMMM"),
    value: item,
  }));

  const [model, setModel] = useState(DefModel);

  useEffect(() => {
  }, []);

  return (
    <FormPage
      url={"api/v1/cabang_param"}
      callbackPath={"master/cabang-params"}
      formValid={false}
      model={model}
      setModel={setModel}
      wrapperCol={{ span: 24 }}
      labelCol={{ span: 24 }}
      title={"Cabang"}
      onSaveEvent={(val) => ({ ...val, airport_id: val?.airport_id._id })}
      emptyModel={DefModel}
    >
      <Row gutter={16}>
        <Col lg={8}>
          <Form.Item
            style={{ width: "100%" }}
            // name={"airport_id"}
            label="Airport"
            rules={[{ required: true }]}
          >
            <Select
              autoFocus
              showSearch
              style={{ width: "100%" }}
              value={model?.airport_id?._id}
              placeholder="Search to Select"
              optionFilterProp="children"
              onChange={(val) => {
                const obj = airportAp1Cache.find((item) => item._id === val);
                setModel({ ...model, airport_id: obj });
              }}
              filterOption={(input, option) => {
                return input.length > 2
                  ? (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  : false;
              }}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={convertOpt}
            />
          </Form.Item>
        </Col>
        <Col lg={4}>
          <Form.Item name={"tahun"} label="Year" style={{ width: "100%" }}>
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col lg={4}>
          <Form.Item name={"month"} label="Month" style={{ width: "100%" }}>
            <Select
              showSearch
              style={{ width: "100%" }}
              value={model.month}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return input.length > 2
                  ? (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  : false;
              }}
              options={convertOptMonth}
            />
          </Form.Item>
        </Col>
        <Col lg={4}>
          <Form.Item name={"konsesi"} label="Konsesi" style={{ width: "100%" }}>
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col lg={4}>
          <Form.Item
            name={"konsesi_rka"}
            label="Konsesi_RKA"
            style={{ width: "100%" }}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col lg={6}>
        <Form.Item
          name={"dom_out_rka"}
          label="Dom. Out. RKA"
          style={{ width: "100%" }}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        </Col>
        <Col lg={6}>
        <Form.Item
          name={"dom_inc_rka"}
          label="Dom. Inc. RKA"
          style={{ width: "100%" }}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        </Col>
        <Col lg={6}>
        <Form.Item
          name={"inter_out_rka"}
          label="Inter. Out. RKA"
          style={{ width: "100%" }}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        </Col>
        <Col lg={6}>
        <Form.Item
          name={"inter_inc_rka"}
          label="Inter. Inc. RKA"
          style={{ width: "100%" }}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        </Col>

        <Col lg={6}>
        <Form.Item
          name={"dom_out_jaster"}
          label="Dom. Out. Jaster"
          style={{ width: "100%" }}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        </Col>
        <Col lg={6}>
        <Form.Item
          name={"dom_inc_jaster"}
          label="Dom. Inc. Jaster"
          style={{ width: "100%" }}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        </Col>
        <Col lg={6}>

        <Form.Item
          name={"inter_out_jaster"}
          label="Inter. Out. Jaster"
          style={{ width: "100%" }}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        </Col>
        <Col lg={6}>
        <Form.Item
          name={"inter_inc_jaster"}
          label="Inter. Inc. Jaster"
          style={{ width: "100%" }}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        </Col>
        <Col lg={6}>
        <Form.Item
          name={"dom_out_jaster_rka"}
          label="Dom. Out. Jaster RKA"
          style={{ width: "100%" }}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        </Col>

        <Col lg={6}>
        <Form.Item
          name={"dom_inc_jaster_rka"}
          label="Dom. Inc. Jaster RKA"
          style={{ width: "100%" }}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        </Col>
        <Col lg={6}>
        <Form.Item
          name={"inter_out_jaster_rka"}
          label="Inter. Out. Jaster RKA"
          style={{ width: "100%" }}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        </Col>
        <Col lg={6}>
        <Form.Item
          name={"inter_inc_jaster_rka"}
          label="Inter. Inc. Jaster RKA"
          style={{ width: "100%" }}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        </Col>
        <Col lg={6}>
        <Form.Item
          name={"pjkp2u_out_dom_rka"}
          label="Dom. Out. PJKP2U RKA"
          style={{ width: "100%" }}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>

        </Col>
        <Col lg={6}>
        <Form.Item
          name={"pjkp2u_inc_dom_rka"}
          label="Dom. Inc. PJKP2U RKA"
          style={{ width: "100%" }}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        </Col>
        <Col lg={6}>
        <Form.Item
          name={"pjkp2u_out_int_rka"}
          label="Inter. Out. PJKP2U RKA"
          style={{ width: "100%" }}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>

        </Col>
        <Col lg={6}>
        <Form.Item
          name={"pjkp2u_inc_int_rka"}
          label="Inter. Inc. PJKP2U RKA"
          style={{ width: "100%" }}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        </Col>
       
        
       

        
       

       
       

        
       

       
        

        
        
      </Row>
    </FormPage>
  );
};
