import { Button, Divider, Input, Typography } from "antd";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { RiRefreshLine, RiAddCircleLine } from "react-icons/ri";
import Table from "react-data-table-component";
import { green, grey, yellow } from "@ant-design/colors";
import { useHistory, useNavigate } from "react-router-dom";
import moment from "moment";
import { useFetch } from "../../hooks/useFetch";
import CONFIG from "../../config/env";
import Page from "./Page";
import MainCard from "../MainCard";
import { createUseStyles } from "react-jss";
import { useFontSize } from "@/hooks/useDimension";
const { Title } = CONFIG;

const useStyles = createUseStyles({
  root: {
    "& .rdt_TableHeadRow": {
      backgroundColor: "#f1f1f1",
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    "& .rdt_TableCell": {
      padding: "0px 2px",
    },
    "& .rdt_TableRow": {
      padding: "2px 0px",
      fontSize: ({ fontSize }) => fontSize.sm,
    },
    "& .rdt_TableCol": {
      padding: "2px 0px",
    },
  },
});

export default ({
  title,
  columns,
  actionPosition,
  url,
  editPath,
  createPath,
  HeaderComponent,
  otherAction,
  refresh,
  conditionalRows,
  ExpandComponent,
  otherFilter,
  noSearch,
  highlightOnSearch
}) => {
  const fontSize = useFontSize();
  const classes = useStyles({ fontSize });
  const [qry, setQry] = useState({});
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const searchHighlighter = (val) => {
    let el = document.querySelectorAll("[data-tag='allowRowEvents']");
    for (let iii = 0; iii < el.length; iii++) {
      const element = el[iii];
      if (element.innerText.indexOf(val) !== -1) {
        element.innerHTML = element.innerText.replace(
          new RegExp(val + "(?!([^<]+)?<)", "gi"),
          "<mark>$&</mark>"
        );
        element.innerHTML=`<span id="allowRowEvents">${element.innerHTML}</span>`;
      }
    }
  };


  const [search, setSearch] = useState("");
  const [localRefresh, setLocalRefresh] = useState(moment().unix());
  const cols = useMemo(() => {
    if (!!editPath) {
      const action = {
        selector: (row) => {
          return (
            <Fragment>
              <Button
                shape="circle"
                icon={<FaEdit color={grey.primary} />}
                type="link"
                title="Edit Data"
                onClick={() => {
                  navigate(editPath, {
                    state: { ...row, isCreate: false },
                  });
                }}
              />
              {!!otherAction &&
                typeof otherAction === "function" &&
                otherAction(row)}
            </Fragment>
          );
        },
        id: "_action",
        name: "Action",
        center: true,
        compact: true,
      };
      return actionPosition === "first"
        ? [action, ...columns]
        : [...columns, action];
    } else if (!!otherAction && typeof otherAction === "function") {
      const action = {
        selector: otherAction,
        id: "_action",
        name: "Action",
        center: true,
        compact: true,
      };
      return actionPosition === "first"
        ? [action, ...columns]
        : [...columns, action];
    }
    return columns;
  }, [columns, editPath, actionPosition, otherAction]);
  const uri = useMemo(() => {
    const parentRefresh = parseInt(refresh) || 0;
    const r = localRefresh > parentRefresh ? localRefresh : parentRefresh;
    return `${url}?page=${page}&perPage=${perPage}&search=${search}&search2=${JSON.stringify(
      qry
    )}&timestamp=${r}`;
  }, [url, refresh, localRefresh, qry, search, page, perPage]);
  useEffect(() => {
    document.title = `${Title} | ${title}`;
  }, []);

  const [data, loading] = useFetch(uri);

  useEffect(() => {
    if(!!search && !!highlightOnSearch){
      searchHighlighter(search);
    }
  }, [search,data]);

  return (
    <Page title={title}>
      {!!HeaderComponent && (
        <HeaderComponent onSubmitSearch={(aQry) => setQry(aQry)} />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: !!otherFilter ? "space-between" : "flex-end",
          margin: "16px 0px",
        }}
      >
        {!!otherFilter && (
          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            Filter :{" "}
            {otherFilter({
              state: qry,
              onChangeFilter: (val) => setQry({ ...qry, ...val }),
            })}
            <Button type="link" onClick={() => setQry({})}>
              clear all
            </Button>
          </div>
        )}
        <div>
          {!noSearch && (
            <Input.Search
              allowClear
              onSearch={(str) => {
                // searchHighlighter(str)
                setSearch(str);
              }}
              style={{ width: 240, paddingInline: 8 }}
            />
          )}
          <Button
            icon={<RiRefreshLine size={20} color={yellow[7]} />}
            shape={"round"}
            style={{ paddingInline: 8 }}
            type="link"
            onClick={() => setLocalRefresh(moment().unix())}
          />
          {!!createPath && (
            <Button
              icon={<RiAddCircleLine size={20} color={green[7]} />}
              style={{ paddingInline: 8 }}
              shape={"round"}
              type="link"
              onClick={() =>
                navigate(createPath, { state: { isCreate: true } })
              }
            />
          )}
        </div>
      </div>
      <MainCard>
        <div style={{ padding: 16 }}>
          <Table
            className={classes.root}
            columns={cols}
            data={data?.data || []}
            noHeader={true}
            dense={true}
            striped={true}
            persistTableHead={true}
            progressPending={loading}
            pagination={true}
            highlightOnHover={true}
            expandableRowsComponent={ExpandComponent}
            expandableRows={!!ExpandComponent}
            expandOnRowClicked={!!ExpandComponent}
            paginationServer={true}
            paginationTotalRows={data?.total || 0}
            onChangeRowsPerPage={(pp, pg) => {
              setPerPage(pp);
              setPage(pg);
            }}
            onChangePage={(pg) => setPage(pg)}
            conditionalRowStyles={conditionalRows}
          />
        </div>
      </MainCard>
    </Page>
  );
};
