import { Row, Col, Radio, Layout } from "antd";
import React, { useState, Fragment, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import getPreviousMonth from "../../helper/getPrevMonth";
import { createUseStyles } from "react-jss";
import Config from "../../config/env";
import MainHeader from "../../components/Layout/MainHeader";
import { FilterList } from "../../components/Filter/FIlterList";
import CardSummaries from "./CardSummaries";
import Trends from "./Trends";
import Pareto from "./Pareto";
import ShippingMaps from "./ShippingMaps";
const { Content } = Layout;
const { colorPrimary } = Config;

const useStyles = createUseStyles({
  cardContainer: {
    // border: '1px solid',
    borderRadius: 8,
    background: "white",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
  },

  content: {
    padding: "4px 12px",
    minHeight: "calc(100vh - 150px)",
    background: "",
  },
});

function Overview({ state }) {
  const classes = useStyles();
  const [value, setValue] = useState("daily");
  const monthFormat = "YYYY/MM";
  const [route, setRoute] = useState({});
  const [direct, setDirect] = useState({});
  const [airline, setAirline] = useState(null);
  const [category, setCategory] = useState("");
  const [values, setValues] = useState([]);
  const [gap, setGap] = useState(2);

  const [rangeDateFormated, comparatorDateFormated] = useMemo(() => {
    const [date1, date2] = state.selectedDate;
    const [compareDate1, compareDate2] = state.comparatorDate;

    return [
      `${dayjs(date1).format("MMM-YYYY")}/${dayjs(date2).format("MMM-YYYY")}`,
      `${dayjs(compareDate1).format("MMM-YYYY")}/${dayjs(compareDate2).format(
        "MMM-YYYY"
      )}`,
    ];
  }, [state]);

  const handleDelete = (del) => {
    const filter = values.filter((item) => {
      return item !== del;
    });
    setValues(filter);
  };

  const handleChangeFilterList = (type, value) => {
    switch (type?.toLowerCase()) {
      case "routes":
        return setRoute(value);
      case "direction":
        return setDirect(value);
      case "empu":
        return setRoute(value);
      case "airline":
        return setAirline(value);
      default:
        return;
    }
  };

  // console.log({ rangeDateFormated, comparatorDateFormated });

  return (
    <Fragment>
      <FilterList onChange={handleChangeFilterList} />
      <div className={classes.content}>
        <CardSummaries
          filterValue={{
            route: route.value?.toLowerCase() || "domestic",
            direction: direct?.value?.toLowerCase() || "outgoing",
            month_selected: rangeDateFormated,
            month_comparator: comparatorDateFormated,
            airports: [state.airport],
            empus: [],
            airlines: !!airline?.value ? [airline?.value] : [],
          }}
        />
        <Trends
          filterValue={{
            subtype: value,
            route: route.value?.toLowerCase() || "domestic",
            direction: direct?.value?.toLowerCase() || "outgoing",
            month_selected: rangeDateFormated,
            month_comparator: comparatorDateFormated,
            airports: [state.airport],
            empus: [],
            // empus: [],
            airlines: !!airline?.value ? [airline?.value] : [],
            // airlines: [],
          }}
        />
        <Row style={{ marginTop: 10 }} gutter={16}>
          <Col span={12}>
            <Pareto
              className={classes}
              filterValue={{
                route: route.value?.toLowerCase() || "domestic",
                direction: direct?.value?.toLowerCase() || "outgoing",
                month_selected: rangeDateFormated,
                month_comparator: comparatorDateFormated,
                airports: [state.airport],
                empus: [],
                airlines: !!airline?.value ? [airline?.value] : [],
              }}
            />
          </Col>
          <Col span={12}>
            <ShippingMaps
              filterValue={{
                route: route.value?.toLowerCase() || "domestic",
                direction: direct?.value?.toLowerCase() || "outgoing",
                month_selected: rangeDateFormated,
                month_comparator: comparatorDateFormated,
                airports: [state.airport],
                empus: [],
                airlines: !!airline?.value ? [airline?.value] : [],
              }}
            />
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

export default Overview;
