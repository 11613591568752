import React, { Fragment, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useSelector } from "react-redux";
import Aos from "aos";
import MainRouter from "./content/MainRouter";
import { ConfigProvider } from "antd";
import Config from "./config/env";
import { useFontSize } from "./hooks/useDimension";
import numeral from 'numeral';
import "leaflet/dist/leaflet.css";
import "react-toastify/dist/ReactToastify.css";



LoadingOverlay.propTypes = undefined;

function App() {
  const { loading } = useSelector((state) => state.apps);
  Aos.init({
    duration: 1500,
  });

  const fontSize = useFontSize()
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: Config.colorPrimary,
          borderRadius: 8,
          fontSize:fontSize.sm,
          fontFamily: "Inter"
        },
      }}
    >
      <Fragment>
        <LoadingOverlay active={loading} spinner text="Loading . . .">
          <MainRouter />
        </LoadingOverlay>
      </Fragment>
    </ConfigProvider>
  );
}

export default App;
