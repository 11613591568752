export const levels = [
    {
        level: 0x1000,
        role: 'Master User'
    },
    {
        level: 0x01,
        role: 'Acceptance',
    },
    {
        level: 0x02,
        role: 'Cashier'
    },
    {
        level: 0x04,
        role: 'Avsec' // 0x1034
    },
    {
        level: 0x08,
        role: 'Checker'
    },
    {
        level: 0x10,
        role: 'Finance'
    },
    {
        level: 0x20,
        role: 'Supervisor'
    },
    {
        level: 0x40,
        role: 'Manager'
    }
];

export const getRoles = (level) => {
    return levels.filter((role) => role.level < level);
}

export const levelToRoles = (level) => {
    const result = [];
    for (let iii = 0; iii < levels.length; iii++) {
        const role = levels[iii];
        
        if ((level & role.level) > 0) result.push(role.role);
    }
    return result.join(', ');
}