import React, { useState, useEffect, useMemo, Fragment, useRef } from "react";
import { createUseStyles } from "react-jss";
import { Spin } from "antd";
import { useFontSize, useHeightCard } from "@/hooks/useDimension";
import Config from "@/config/env";
import { getParetoData } from "@/helper/getParetoData";
import { parseFormatedNumber } from "@/helper/parseFormatedNumber";
import numeral from "numeral";
import MainChart from "@/components/Chart";
import { resetStyleTooltips } from "@/components/Chart/resetStyleTooltip";
import { marker, markerTooltip } from "@/components/Chart/markerTooltip";
import tooltipFormatter from "@/components/Chart/Tooltip";

const { colorPrimary, colorSecondary } = Config;

const options = [
  { title: "Airline", value: "airline" },
  { title: "Origin", value: "origin" },
  { title: "Dest", value: "dest" },
  { title: "JPT", value: "shipper" },
  { title: "Commodity", value: "komoditi" },
  // { label: "Sub Komoditi", value: "sub-komoditi" },
];

const useStyles = createUseStyles({
  container: {
    padding: "8px",
    background: "white",
    borderRadius: 8,
    height: "100%",
  },
  selectForm: {
    "&.ant-select": {
      "& .ant-select-selector": {
        border: "none",
        background: `${colorPrimary} !important`,
        borderRadius: 8,
        "& .ant-select-selection-item": {
          fontWeight: "bold",
          color: "white",
          fontSize: ({ fontSize }) => fontSize.sm,
        },
      },
      "&.form-active": {
        fontWeight: "bold",
        color: `${colorPrimary}`,

        "& .ant-select-selection-item": {
          fontWeight: "bold",
          color: `${colorPrimary}`,
        },
      },
      "& .ant-select-arrow": {
        color: `white`,
      },
    },
  },
  swiperStyle: {
    display: "flex",
    // border: "1px solid red",
    padding: "0px",
  },
});

function ParetoChart({
  dataSource,
  title,
  loading,
  fullscreen,
  isMobile = false,
}) {
  const fontSize = useFontSize();
  const classes = useStyles({ fontSize });
  const [view, setView] = useState("airline");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { trendKgs: dataset } = useMemo(() => {
    return getParetoData([], dataSource?.pareto || [], view);
  }, [dataSource, view]);
  const handleChange = (value) => {
    setView(value);
  };

  const paretoOption = {
    grid: {
      x: !!isFullScreen ? fontSize.xl * 8 : fontSize.xl * 3,
      x2: fontSize.xl * 2,
      y: fontSize.xs,
      y2: fontSize.lg,
    },
    dataset,
    tooltip: {
      trigger: "axis",
      // triggerOn: "click",
      position: function (pos, params, dom, rect, size) {
        // tooltip will be fixed on the right if mouse hovering on the left,
        // and on the left if hovering on the right.
        var obj = { top: 60 };
        obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 5;
        return obj;
      },
      ...resetStyleTooltips,
      formatter: (params) => {
        return tooltipFormatter(
          `${params[0].name} ${
            (params[0].value[3] && `(${params[0].value[3]})`) || ""
          }`,
          params[0].value[0],
          params[0].value[1]
        )
      },
    },
    xAxis: {
      show: false,
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
    },
    yAxis: {
      type: "category",
      axisLabel: {
        show: true,
        formatter: (params) =>
          `${
            !isFullScreen
              ? params.length > 7
                ? `${params.substring(0, 5)}...`
                : params
              : params
          }`,
      },
    },
    series: [
      {
        name: "category",
        label: {
          show: true,
          position: "right",
          formatter: (params) => `${parseFormatedNumber(params.value[0])}`,
        },
        encode: { x: "kgs", y: "code" },
        // data: sortedData?.map((item) => parseInt(item.curr)),
        type: "bar",
        color: colorPrimary,
        datasetIndex: 1,
        itemStyle: {
          borderRadius: [0, 4, 4, 0],
        },
      },
    ],
  };

  return (
    <MainChart
      title={
        <Fragment>
          {title} {loading && <Spin />}
        </Fragment>
      }
      option={paretoOption}
      view={view}
      height={fontSize.md * 18}
      onFullScreen={(val) => setIsFullScreen(val)}
      fullscreen
      onChange={(val) => setView(val)}
      filterComp={options}
    />
  );
}

export default ParetoChart;
