import { Button, Checkbox, Col, Dropdown, Row, Select, Typography } from "antd";
import { blue } from "@ant-design/colors";
import React, { useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import Config from "../../config/env";
import { MdClose } from "react-icons/md";
import { TbFilter, TbPlus } from "react-icons/tb";
import { useSelector } from "react-redux";
import { formatedOptions } from "../../helper/formatedOptions";

const { Theme, colorPrimary, colorPrimaryTransparat } = Config;

const useStyles = createUseStyles({
  selectForm: {
    "&.ant-select": {
      "&.form-active": {
        "& .ant-select-selector ": {
          background: `${blue[1]} !important`,
          // "&.form-active":{
          // }
          "& .ant-select-selection-search": {
            "& .ant-select-selection-search-input": {
              color: `${colorPrimary}`,
              fontWeight: "bold",
            },
          },
        },
        "& .ant-select-selector .ant-select-selection-item": {
          fontWeight: "bold",

          color: `${colorPrimary}`,
        },
        "& .ant-select-arrow": {
          color: `${colorPrimary}`,
        },
      },
      "& .ant-select-selector .ant-select-selection-item": {
        minWidth: "10rem",
      },
      // color: "white",
    },
  },
});

const defaultValue = {
  routes: {
    isChecked: true,
    value: "domestic",
    label: "Domestic",
    // value: "all",
    // label: "All Routes",
  },
  direction: {
    isChecked: true,
    // value: "all",
    // label: "All Direction",
    value: "outgoing",
    label: "Outgoing",
  },
  empu: {
    isChecked: true,
    value: "all",
    label: "All Empu",
  },
  airline: {
    isChecked: true,
    value: "all",
    label: "All Airline",
  },
};

export const FilterList = ({ onChange }) => {
  const { airlineCache } = useSelector((state) => state.apps);
  const classes = useStyles();

  const airlaneOpt = useMemo(() => {
    return formatedOptions(airlineCache, ["code", "name"], "code");
  }, [airlineCache]);

  const [filters, setFilters] = useState(defaultValue);
  const onChangeCheckBox = (e, idx) => {
    const keyFilter = Object.keys(filters)[idx];
    setFilters((state) => ({
      ...state,
      ...(state[keyFilter].isChecked = e.target.checked),
    }));
  };

  const items = [
    {
      label: (
        <div style={{ width: 200 }}>
          {Object.keys(filters).map((item, idx) => (
            <div
              key={idx}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "0.5rem",
              }}
            >
              <Typography.Text>
                {item} {filters[item].value}
              </Typography.Text>
              <Checkbox
                checked={filters[item].isChecked}
                onChange={(e) => onChangeCheckBox(e, idx)}
              />
            </div>
          ))}
        </div>
      ),
    },
  ];

  const [openDropDown, setOpenDropDown] = useState(false);

  const handleDropDown = () => {
    setOpenDropDown(!openDropDown);
  };

  const isFilteredFormItem = useMemo(() => {
    let result = false;
    for (const key in filters) {
      if (Object.hasOwnProperty.call(filters, key)) {
        const element = filters[key];
        if (element?.value !== "all") return (result = true);
      }
    }
    return false;
  }, [filters]);

  return (
    <Row
      style={{
        padding: "2px 16px",
        alignItems: "center",
        background: "#EDEDED",
      }}
      gutter={8}
    >
      {/* <Col>
        <Dropdown
          arrow
          trigger={["click"]}
          open={openDropDown}
          onOpenChange={handleDropDown}
          autoFocus={false}
          getPopupContainer={() => document.getElementById("btnPlus")}
          menu={{
            items,
          }}
          placement="bottomLeft"
        >
          <Button
            id="btnPlus"
            className="iconButton"
            type="primary"
            icon={<TbFilter size={20} />}
          />
        </Dropdown>
      </Col> */}

      <Col span={3}>
        <Select
          showSearch
          size="small"
          className={`${classes.selectForm} ${
            filters.routes.value !== "all" && "form-active"
          }`}
          style={{ width: "100%" }}
          placeholder="Search to Select"
          optionFilterProp="children"
          value={filters.routes}
          filterOption={(input, option) =>
            (option?.label.toLowerCase() ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          onChange={(val, opt) => {
            onChange("routes", opt);
            setFilters((state) => ({
              ...state,
              routes: { ...state.routes, ...opt },
            }));
          }}
          options={[
            {
              value: "all",
              label: "All Routes",
              disabled: true,
            },
            {
              value: "domestic",
              label: "Domestic",
            },
            {
              value: "international",
              label: "International",
            },
          ]}
        />
      </Col>

      <Col span={3}>
        <Select
          showSearch
          size="small"
          className={`${classes.selectForm} ${
            filters.direction.value !== "all" && "form-active"
          }`}
          style={{ width: "100%" }}
          placeholder="Search to Select"
          optionFilterProp="children"
          value={filters.direction}
          filterOption={(input, option) =>
            (option?.label.toLowerCase() ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          onChange={(val, opt) => {
            onChange("direction", opt);
            setFilters((state) => ({
              ...state,
              direction: { ...state.direction, ...opt },
            }));
          }}
          options={[
            {
              value: "all",
              label: "All Direction",
              disabled: true,
            },
            {
              value: "outgoing",
              label: "Outgoing",
            },
            {
              value: "incoming",
              label: "Incoming",
            },
          ]}
        />
      </Col>

      <Col span={3}>
        <Select
          showSearch
          disabled
          size="small"
          className={`${classes.selectForm} ${
            filters.empu.value !== "all" && "form-active"
          }`}
          style={{ width: "100%" }}
          placeholder="Search to Select"
          optionFilterProp="children"
          value={filters.empu}
          filterOption={(input, option) =>
            (option?.label.toLowerCase() ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          onChange={(val, opt) =>
            setFilters((state) => ({
              ...state,
              empu: { ...state.empu, ...opt },
            }))
          }
          options={[
            {
              value: "all",
              label: "All Direction",
            },
            {
              value: "outgoing",
              label: "Outgoing",
            },
            {
              value: "incoming",
              label: "Incoming",
            },
          ]}
        />
      </Col>

      <Col span={3}>
        <Select
          showSearch
          size="small"
          allowClear
          className={`${classes.selectForm} ${
            filters.airline.value !== "all" && "form-active"
          }`}
          style={{ width: "100%" }}
          placeholder="Search to Select"
          optionFilterProp="children"
          onClear={() => {
            onChange("airline", defaultValue.airline);
            setFilters((state) => ({
              ...state,
              airline: defaultValue.airline,
            }));
          }}
          value={filters.airline}
          filterOption={(input, option) =>
            (option?.label?.toLowerCase() ?? "").includes(input?.toLowerCase())
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          onChange={(val, opt) => {
            onChange("airline", opt);
            setFilters((state) => ({
              ...state,
              airline: { ...state.airline, ...opt },
            }));
          }}
          options={[
            {
              value: "all",
              label: "All Airline",
            },
            ...airlaneOpt,
          ]}
        />
      </Col>

      {/* {!!isFilteredFormItem && (
        <Col>
          <Button
            className="iconButton"
            onClick={() => setFilters(defaultValue)}
            type="primary"
            size="small"
            icon={<MdClose size={20} />}
            danger
          />
        </Col>
      )} */}
    </Row>
  );
};
