import {
  MapContainer,
  TileLayer,
  Popup,
  CircleMarker,
  Circle,
  useMap,
} from "react-leaflet";
import React, { useEffect } from "react";
import { Typography } from "antd";
import numeral from "numeral";
import { parseFormatedNumber } from "../../helper/parseFormatedNumber";
import { markerTooltipComp } from "../Chart/markerTooltip";

const ComponentResize = () => {
  const map = useMap();
  useEffect(() => {
    setTimeout(() => {
      map?.invalidateSize();
      return () => map.remove();
    }, 0);
  }, []);
  return null;
};

const FlightMap = ({
  destinationMaps,
  height = 100,
  isRouting = false,
  data,
  loading,
}) => {
  const mapCenter = [-0.6796426813208343, 115.81464264503218];
  const maxRadius = 120000;
  const logaritRadius = (value) => {
    const radius = (Math.log(value + 1) / Math.log(1000000 + 1)) * maxRadius;
    return radius;
  };
  return (
    <div
      style={{ width: "100%", padding: "8px 0px", height: "100%", flexGrow: 1 }}
    >
      <MapContainer
        attributionControl={false}
        scrollWheelZoom={false}
        center={mapCenter}
        zoom={1}
        style={{ width: "100%", zIndex: 99, height: "100%", borderRadius: 8 }}
      >
        <ComponentResize />
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {!!data &&
          data.map((item, index) => (
            <React.Fragment key={index}>
              <Circle
                center={[item?.airport?.lat || 0, item?.airport?.lon || 0]}
                pathOptions={{ fillColor: "blue" }}
                radius={logaritRadius(item?.total_charge_weight)}
              >
                <Popup className="popup-style">
                  <div className="main-tooltip" style={{ padding: 0 }}>
                    <Typography style={{ color: "white", marginBottom: 4 }}>
                      <b>
                        {item?.airport?.code},{item?.airport?.kota},
                        {item?.airport?.country}
                      </b>
                    </Typography>
                    <div className="container-tooltip">
                      <div className="item-tooltip">
                        <div>
                          {markerTooltipComp("production")} Production Kg
                        </div>
                        <div className="text-right text-bold">
                          {numeral(item?.total_charge_weight).format("0,000")}
                        </div>
                      </div>
                      <div className="item-tooltip">
                        <div>{markerTooltipComp()} Revenue IDR</div>
                        <div className="text-right text-bold">
                          {numeral(item?.total_pjkp2u).format("0,000")}
                        </div>
                      </div>
                    </div>
                  </div>
                </Popup>
              </Circle>
            </React.Fragment>
          ))}
      </MapContainer>
    </div>
  );
};

export default FlightMap;
