import React, { Fragment } from 'react'
import TablePage from '@/components/pages/TablePage'
import moment from 'moment'
export default () => {
    const columns=[
        {
            selector:(row)=>row?.code,
            name:"Code",
            id:'code',
            width:"100px"
        },
        {
            selector:(row)=>(
                <Fragment>
                    {row?.categories?.join(", ")}
                    <p>{row?.bab_penjelasan?.toString().toLowerCase()}</p>
                </Fragment>
            ),
            name:"Categories",
            id:'categories',
            width:"300px",
            wrap:true,

        },
        // {
        //     selector:(row)=>row?.bab_penjelasan,
        //     name:"Description",
        //     id:'bab_penjelasan'
        // },
        {
            selector:(row)=>row?.id_name,
            name:"Name(ID)",
            id:'id_name',
            width:"400px",
            wrap:true

        },
        {
            selector:(row)=>row?.en_name,
            name:"Name(ENG)",
            id:'en_name',
            width:"400px",
            wrap:true
        },
        
       
        // {
        //     selector:(row)=>(row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
        //     name:"CreatedAt",
        //     id:'createdAt'
        // },
    ]
    return (
        <TablePage
            title={"Master HS Code"}
            url="api/v1/hscode"
            actionPosition={'first'}
            createPath="create"
            editPath={"edit"}
            columns={columns}  
            highlightOnSearch          
        />
    )
}