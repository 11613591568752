import React, { useEffect, useState } from "react";
import LayoutComponent from "../../components/Layout/Layout";
import { Routes, Route } from "react-router-dom";
import Overview from "../overview";
import StakeHolder from "../dashboard-v2/pages/stakeholder";
import Commodity from "../dashboard-v2/pages/commodity";
import Airport from "../dashboard-v2/pages/airport";
import Perform from "../dashboard-v2/pages/perform";
import dayjs from "dayjs";
import MainHeader from "../../components/Layout/MainHeader";
import { useDispatch } from "react-redux";
import { loadAirlines, loadAirportsAp1 } from "../../redux/reducer/apps";
import { Layout } from "antd";

const { Content } = Layout;

const RouterPage = ({ state }) => {
  return (
    <Routes>
      <Route path="/overview" element={<Overview state={state} />} />
      <Route path="/dev/stakeholders" element={<StakeHolder state={state} />} />
      <Route path="/dev/commodity" element={<Commodity state={state} />} />
      <Route path="/dev/airport" element={<Airport />} state={state} />
      <Route path="//devperform" element={<Perform />} state={state} />
    </Routes>
  );
};

const DEF_GAP_MONTH = 2;

function DashboardPage() {
  const dispatch = useDispatch();
  const [airport, setAirport] = useState("LOP");

  const [range, setRange] = useState([
    // dayjs("2021-01-01").add(Math.abs(DEF_GAP_MONTH) * -1, "month"),
    dayjs("2021-01-01"),
    dayjs("2021-03-01"),
  ]);

  const [compare, setCompare] = useState([
    // dayjs().month(dayjs(range[0]).get("month") - (DEF_GAP_MONTH + 1)),
    // dayjs(range[0]).add(-1, "month"),
    dayjs("2020-01-01"),
    dayjs("2020-03-01"),
  ]);

  const handleChangeFilterHeader = (type, value) => {
    switch (type) {
      case "selectedDate":
        return setRange(value);
      case "comparatorDate":
        return setCompare(value);
      case "airport":
        return setAirport(value);
      default:
        return;
    }
  };

  useEffect(() => {
    dispatch(loadAirportsAp1());
    dispatch(loadAirlines());
  }, []);

  return (
    <LayoutComponent>
      <MainHeader
        filterValue={{
          airport: airport,
          selectedDate: range,
          comparatorDate: compare,
        }}
        onChange={handleChangeFilterHeader}
      />
      <Content
        style={{
          minheight: "calc(100vh - 60px)",
          background: "#EDEDED",
          marginTop: "5rem",
          padding: 6,
        }}
      >
        <RouterPage
          state={{
            airport: airport,
            selectedDate: range,
            comparatorDate: compare,
          }}
        />
      </Content>
    </LayoutComponent>
  );
}

export default DashboardPage;
