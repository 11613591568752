import { Col, Row, Typography } from "antd";
import React, { useMemo, useState } from "react";
import { useFontSize } from "@/hooks/useDimension";
import MultiSelectComponent from "@/components/CustomSelect/MultiSelect";

export default function FiltersStakeholder({
  onChange,
  state,
  isMobile = false,
  data,
}) {
  const fontSize = useFontSize();
  const [airlines, setAirlines] = useState([]);
  const [agents, setAgents] = useState([]);

  const [airlinesOpt, agentsOpt] = useMemo(() => {
    if (!data) return [[], []];
    const { connected_airlines, connected_agent } = data;
    const airlines = connected_airlines.map(({ _id: { code, _id, name } }) => ({
      label: `${code}/${name}`,
      value: _id,
    }));

    const agents = connected_agent.map(({ _id: { code, _id } }) => ({
      label: code,
      value: _id,
    }));
    return [airlines, agents];
  }, [data]);

  return (
    <Row gutter={8} style={{ padding: "0px 8px" }}>
      <Col
        xs={{ span: 8 }}
        lg={{ span: 8 }}
        sm={{ span: 8 }}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
        }}
      >
        <div
          style={{
            paddingBottom: "4px",
          }}
        >
          <Typography
            style={{
              lineHeight: 1,
              fontWeight: "bold",
              fontSize: fontSize.sm,
            }}
          >
            Airline
          </Typography>
        </div>
        <div style={{ width: "100%", height: "100%" }}>
          <MultiSelectComponent
            hasSelectAll={false}
            options={airlinesOpt}
            selected={airlinesOpt.filter(
              (item) => item.value == state.airlines
            )}
            onChange={(val) => {
              setAirlines(val);
              const selected = val[val?.length - 1];
              onChange("airlines", selected?.value || "");
            }}
          />
        </div>
      </Col>
      <Col
        xs={{ span: 8 }}
        lg={{ span: 8 }}
        sm={{ span: 8 }}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
        }}
      >
        <div
          style={{
            paddingBottom: "4px",
          }}
        >
          <Typography
            style={{
              lineHeight: 1,
              fontWeight: "bold",
              fontSize: fontSize.sm,
            }}
          >
            JPT
          </Typography>
        </div>
        <div style={{ width: "100%", height: "100%" }}>
          <MultiSelectComponent
            hasSelectAll={false}
            options={agentsOpt}
            selected={agentsOpt.filter((item) => item.value == state.agents)}
            onChange={(val) => {
              setAgents(val);
              const selected = val[val?.length - 1];

              onChange("agents", selected?.value || "");
            }}
          />
        </div>
      </Col>
      <Col
        xs={{ span: 8 }}
        lg={{ span: 8 }}
        sm={{ span: 8 }}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
        }}
      >
        <div
          style={{
            paddingBottom: "4px",
          }}
        >
          <Typography
            style={{
              lineHeight: 1,
              fontWeight: "bold",
              fontSize: fontSize.sm,
            }}
          >
            CTO
          </Typography>
        </div>
        <div style={{ width: "100%", height: "100%" }}>
          <MultiSelectComponent
            options={[]}
            selected={["1"]}
            onChange={() => {}}
            //   onChange={(val) => {
            //     setAirports(val);
            //     const selected =
            //       val.length !== 0 ? val.map((item) => item.value) : [];
            //     onChange("airports", selected);
            //   }}
          />
        </div>
      </Col>
    </Row>
  );
}
