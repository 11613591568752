import { Row, Col, Image, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { createUseStyles } from "react-jss";
import BumnLogo from "@/assets/logos/bumn-logo-white.png";
import ApLogo from "@/assets/logos/ap-logo-white.png";
import InjourneyLogo from "@/assets/logos/injourney-logo-white.png";
import DataVizual from "@/assets/images/data-vizualitation.png";
import { HiOutlinePresentationChartBar } from "react-icons/hi";
import {
  GiCommercialAirplane,
  GiBasket,
  GiPlanetConquest,
} from "react-icons/gi";
import { BsSpeedometer } from "react-icons/bs";
import useWindowDimensions, { useFontSize } from "@/hooks/useDimension";

const useStyles = createUseStyles({
  container: {
    background: "#203864",
    // width: '100vw',
    height: "100vh",
  },
  pageWrapper: {
    padding: ({fontSize})=>`${fontSize.xl3}px ${fontSize.xl3*2}px`,
  },
  logoWrapper: {
    justifyContent: "space-between",
    // border: "1px solid red",
  },
  leftLogo: {
    // border: "1px solid red"
  },
  rightLogo: {
    textAlign: "end",
  },
  contentWrapper: {
    // border: '1px solid white',
    justifyItems:"center",
    marginTop: ({fontSize})=> fontSize.xl2*2,
    padding: ({fontSize})=>  `0px ${fontSize.xl2*2}px`,
  },
  rightContent: {
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
    // border: '1px solid red'
  },
  leftContent: {
    // border: '1px solid green',
  },
  landingTitle: {
    fontSize: ({ fontSize }) => fontSize.md*3,
    fontWeight: "bold",
    color: "white",
  },
  landingDesc: {
    fontSize: ({ fontSize }) => fontSize.md,
    marginTop: ({fontSize})=> fontSize.xl,
    color: "white",
  },
  insigthWrapper: {
    marginTop: ({ fontSize }) => fontSize.md*5,
  },
  insightText: {
    color: "white",
    fontSize: ({fontSize})=> fontSize.xl,
    // letterSpacing: '3px',
    fontWeight: "bold",
  },
  insightIcons: {
    marginTop: ({ fontSize }) => fontSize.lg,
    gap: 25,
  },
  iconMenu: {
    "&:hover": {
      transform: " scale(1.15)",
    },
  },
});

function Landing() {
  // const fontSize = useFontSize();
  const { height } = useWindowDimensions();
  // const [fontSize, setFontSize] = useState({ md: 30, lg: 40, xl: 50 });
  const fontSize=useFontSize()
  const [icon, setIcon] = useState(60);
  const classes = useStyles({ fontSize });

  return (
    <div className={classes.container}>
      <div className={classes.pageWrapper}>
        <Row className={classes.logoWrapper}>
          <Col className={classes.leftLogo} span={12}>
            <Row style={{ }}>
              <Col>
                <img src={BumnLogo} width={fontSize.lg*8} />
              </Col>
              <Col>
                <img src={InjourneyLogo} width={fontSize.lg*8} />
              </Col>
            </Row>
          </Col>
          <Col className={classes.rightLogo} span={12}>
            
                <img src={ApLogo} width={fontSize.lg*12} />
              
          </Col>
        </Row>
        <Row className={classes.contentWrapper} gutter={28}>
          <Col className={classes.leftContent} span={10}>
            <Typography className={classes.landingTitle}>
              Cargo Analytics Dashboard
            </Typography>
            <Typography className={classes.landingDesc}>
              Make better decision by getting a 360° view of your shipment
              performance. This dashboard can help you montor & analyze the
              shipment perfomance across regions and understand customers
              better.
            </Typography>
            <div className={classes.insigthWrapper}>
              <Typography className={classes.insightText}>
                Go to insight
              </Typography>
              <Row className={classes.insightIcons}>
                <Col>
                  <Link to="/dashboard-v2/overview-new">
                    <HiOutlinePresentationChartBar
                      color="white"
                      size={fontSize.md*3}
                      className={classes.iconMenu}
                      
                    />
                  </Link>
                </Col>
                <Col>
                  <Link to="/dashboard-v2/stakeholders">
                    <GiCommercialAirplane
                      color="white"
                      size={fontSize.md*3}
                      className={classes.iconMenu}
                      
                    />
                  </Link>
                </Col>
                <Col>
                  <Link to="/dashboard-v2/commodity">
                    <GiBasket
                      color="white"
                      size={fontSize.md*3}
                      className={classes.iconMenu}

                      
                    />
                  </Link>
                </Col>
                <Col>
                  <Link to="/dashboard-v2/airport">
                    <GiPlanetConquest
                      color="white"
                      size={fontSize.md*3}
                      className={classes.iconMenu}

                      
                    />
                  </Link>
                </Col>
                <Col>
                  <Link to="/dashboard-v2/perform">
                    <BsSpeedometer
                      color="white"
                      size={fontSize.md*3}
                      className={classes.iconMenu}

                      
                    />
                  </Link>
                </Col>
              </Row>
            </div>
          </Col>
          <Col className={classes.rightContent} span={14}>
            <img src={DataVizual} width="90%" />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Landing;
