import React, { Fragment } from "react";
import { useFontSize } from "@/hooks/useDimension";
import { Spin } from "antd";
import MainChart from "@/components/Chart";
import Config from "@/config/env";
import { getDataSet } from "@/helper/getDataSet";
import { resetStyleTooltips } from "@/components/Chart/resetStyleTooltip";
import tooltipFormatter from "@/components/Chart/Tooltip";

const { colorPrimary, colorSecondary } = Config;

export default function RouteComparison({ data, loading }) {
  const { trendKgs: dataset } = getDataSet(data, "route", "_id");

  const formatLabel = (params) => {
    const { name, value, percent } = params;
    return `${value[2]}: ${percent}%`;
  };

  const optionDoughnut = {
    dataset,
    tooltip: {
      trigger: "item",
      position: "top",
      ...resetStyleTooltips,
      formatter: (params) => {
        return Array.isArray(params?.value) && tooltipFormatter(
          params?.value[2],
          params?.value[0],
          params?.value[1],
          params?.percent,
          true
        );
      },
    },
    legend: {
      show: false,
      selectedMode: false,
      top: "bottom",
    },
    series: [
      {
        left: 16,
        top: 16,
        right: 16,
        bottom: 16,
        name: "Route",
        type: "pie",
        encode: { x: "kgs", y: "code" },
        datasetIndex: 1,
        color: [colorPrimary, colorSecondary],
        radius: ["40%", "70%"],
        itemStyle: {
          borderRadius: 8,
          borderColor: "#fff",
          borderWidth: 4,
        },
        label: {
          show: true,
          // alignTo:"labelLine",
          position: "inner",
          ellipsis: false,
          formatter: formatLabel,
          minMargin: 5,
          edgeDistance: 10,
          lineHeight: 15,
          rich: {
            time: {
              fontSize: 10,
              color: "#999",
            },
          },
        },
        cursor: "",
        labelLine: {
          show: true,
        },
      },
    ],
  };
  const fontSize = useFontSize();
  return (
    <MainChart
      fullscreen
      option={optionDoughnut}
      height={fontSize.lg * 13}
      title={<Fragment>Route Comparison {loading && <Spin />}</Fragment>}
    />
  );
}
