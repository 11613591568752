import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import MainCard from "../MainCard";
import { Button, Col, Row, Typography } from "antd";
import ReactECharts from "echarts-for-react";
import ModalChart from "../../components/Modal/ModalChart";

import Config from "../../config/env";
import { useFontSize } from "../../hooks/useDimension";
import FiltersSwapper from "./FiltersSwapper";

const { colorPrimary } = Config;

export default function MainChart({
  title,
  subTitle,
  option,
  height,
  onChange,
  filterComp = [],
  fullscreen = false,
  view,
  onFullScreen,
  otherComponent,
}) {
  const fontSize = useFontSize();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    !!onFullScreen && onFullScreen(open);
  }, [open]);

  const opt = useMemo(() => {
    let result = {
      ...option,
      textStyle: {
        ...option.textStyle,
        fontFamily: "Inter",
        fontSize: fontSize.sm,
      },
    };
    if (!!option.yAxis) {
      result = {
        ...result,
        yAxis: {
          ...result.yAxis,
          axisLabel: { ...result.yAxis?.axisLabel, fontSize: fontSize.sm },
        },
      };
    }
    return result;
  }, [option]);

  const renderBody = (isModal) => {
    return (
      <Fragment>
        <Row
          style={{
            // alignItems: "center",
            justifyContent: "space-between",
            paddingTop: !!filterComp ? 8 : 4,
            paddingLeft: 16,
            paddingRight: 16,
          }}
          gutter={[16]}
        >
          <Col>
            <Typography
              className="title-onDoubleClick"
              style={{
                fontWeight: "bold",
                cursor: !!fullscreen && "pointer",
                fontSize: fontSize.md,
                color: colorPrimary,
              }}
            >
              {title}
            </Typography>
            {!!subTitle && typeof subTitle === "string" && (
              <Typography
                style={{
                  fontSize: fontSize.sm,
                  color: "gray",
                }}
              >
                {subTitle}
              </Typography>
            )}
          </Col>
          {!!filterComp && filterComp.length !== 0 && (
            <Col>
              <FiltersSwapper
                onChange={onChange}
                filterComp={filterComp}
                view={view}
              />
            </Col>
          )}
        </Row>
        {!!otherComponent && typeof otherComponent === "function" && (
          <div>{otherComponent()}</div>
        )}
        <div style={{ flexGrow: 1, position: "relative" }}>
          <ReactECharts
            style={{
              height: isModal ? "90%" : height,
              width: "100%",
              padding: 8,
              paddingBottom: 0,
            }}
            option={opt}
          />
        </div>
      </Fragment>
    );
  };
  return (
    <Fragment>
      <MainCard>
        <div
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
          onDoubleClick={(e) => fullscreen && setOpen(true)}
        >
          {renderBody()}
        </div>
        <ModalChart open={open} setOpen={setOpen}>
          {renderBody(true)}
        </ModalChart>
      </MainCard>
    </Fragment>
  );
}
