import React, { Fragment, useEffect, useState } from "react";
import PolylineMap from "../FlightsMap/PolylineMap";
import { Col, Row, Typography } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import MainCard from "../MainCard";
import { useFontSize } from "../../hooks/useDimension";
import FiltersSwapper from "../Chart/FiltersSwapper";
import { createUseStyles } from "react-jss";
import MultiSelectComponent from "../../components/CustomSelect/MultiSelect";
import FlightMap from "../FlightsMap";
import Config from "../../config/env";
import ModalChart from "../Modal/ModalChart";

const { colorPrimary } = Config;

const useStyles = createUseStyles({
  select: {
    // zIndex: 9999,
    "& .dropdown-container": {
      borderRadius: 8,
      position: "relative",
      zIndex: 9999,
      width: "200px",
    },
  },
});

export default function CardMaps({
  title,
  subTitle,
  filterComp,
  height,
  isMobile = false,
  data,
  mulitple = false,
  options,
  selected,
  setSelected,
  origin,
  isRoute = false,
  fullscreen = false,
  otherComponent,
  onFullScreen,
  loading,
}) {
  const classes = useStyles();
  const fontSize = useFontSize();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    !!onFullScreen && onFullScreen(open);
  }, [open]);

  const renderBody = (isModal) => {
    return (
      <Fragment>
        <Row
          style={{
            zIndex: 999,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Col>
            <Typography
              className="title-onDoubleClick"
              style={{
                fontWeight: "bold",
                cursor: !!fullscreen && "pointer",
                fontSize: fontSize.md,
                color: colorPrimary,
              }}
            >
              {title}
            </Typography>
            {!!subTitle && typeof subTitle === "string" && (
              <Typography
                style={{
                  fontSize: fontSize.sm,
                  color: "gray",
                }}
              >
                {subTitle}
              </Typography>
            )}
          </Col>
          {!!filterComp && filterComp.length !== 0 && (
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <FiltersSwapper
                filterComp={filterComp}
                className={classes.select}
              />
            </Col>
          )}
          {mulitple && (
            <Col>
              <div
                style={{
                  width: "100%",
                  // border: "1px solid red",
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                {/* <label style={{ fontWeight: 600 }}>Airport : </label> */}
                <MultiSelectComponent
                
                  className={classes.select}
                  loading={loading}
                  onChange={(val) => {
                    setSelected(val);
                  }}
                  options={options}
                  selected={selected}
                />
              </div>
            </Col>
          )}
        </Row>
        {!!otherComponent && typeof otherComponent === "function" && (
          <div>{otherComponent()}</div>
        )}
        <div
          style={{
            flexGrow: isMobile ? 0 : 1,
            height: isModal ? "80%" : height,
          }}
        >
          {!!isRoute ? (
            <PolylineMap
              fullScreen={open}
              selected={selected}
              loading={loading}
              origin={origin}
              data={data}
            />
          ) : (
            <FlightMap height={fontSize.md * 10} data={data} />
          )}
        </div>
      </Fragment>
    );
  };
  return (
    <MainCard isMobile={false}>
      <div
        style={{
          padding: "8px 16px",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          // border: "1px solid red",
        }}
        onDoubleClick={(e) => fullscreen && setOpen(true)}
      >
        {renderBody()}
      </div>

      {!!open && (
        <ModalChart open={open} setOpen={setOpen}>
          {renderBody(true)}
        </ModalChart>
      )}
    </MainCard>
  );
}
