import { Col, Form, Input, InputNumber, Row, Select } from "antd";
import React, { useMemo, useState } from "react";
import FormPage from "@/components/pages/FormPage";
import AutoComplete from "@/components/autocomplete/Offline";
import AutoCompleteOnline from "@/components/autocomplete/Online";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { formatedOptions } from "@/helper/formatedOptions";
const DefModel = {
  airport_id: null,
  tahun: moment(),
  bidang: "",
  instansi: "",
  total_staff: "",
  total_staff_daily: "",
};

export default () => {
  const [model, setModel] = useState(DefModel);
  const { airportAp1Cache } = useSelector((state) => state.apps);
  const convertOpt = formatedOptions(airportAp1Cache, ["code", "name"], "_id");

  return (
    <FormPage
      url={"api/v1/staff"}
      callbackPath={"master/staff"}
      formValid={false}
      model={model}
      setModel={setModel}
      wrapperCol={{ span: 24 }}
      labelCol={{ span: 12 }}
      title={"Staff Airport"}
      emptyModel={DefModel}
    >
      <Row gutter={32}>
        <Col lg={9}>
          <Form.Item
            style={{ width: "100%" }}
            // name={"airport_id"}
            label="Airport"
            rules={[{ required: true }]}
          >
            <Select
              autoFocus
              showSearch
              style={{ width: "100%" }}
              value={model?.airport_id?._id}
              placeholder="Search to Select"
              optionFilterProp="children"
              onChange={(val) => {
                const obj = airportAp1Cache.find((item) => item._id === val);
                setModel({ ...model, airport_id: obj });
              }}
              filterOption={(input, option) => {
                return input.length > 2
                  ? (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  : false;
              }}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={convertOpt}
            />
          </Form.Item>
        </Col>

        <Col lg={5}>
          <Form.Item
            name={"tahun"}
            label="Year"
            style={{ width: "100%" }}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col lg={5}>
          <Form.Item
            name={"bidang"}
            label="Bidang"
            style={{ width: "100%" }}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col lg={5}>
          <Form.Item
            name={"instansi"}
            label="Instansi"
            style={{ width: "100%" }}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col lg={12}>
          <Form.Item
            name={"total_staff"}
            label="Total Staff"
            style={{ width: "100%" }}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col lg={12}>
          <Form.Item
            name={"total_staff_daily"}
            label="Total Staff Daily"
            style={{ width: "100%" }}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
    </FormPage>
  );
};
