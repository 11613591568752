import React, { useState, useMemo, Fragment } from "react";
import { useFontSize } from "@/hooks/useDimension";
import Config from "@/config/env";
import { getParetoData } from "@/helper/getParetoData";
import { parseFormatedNumber } from "@/helper/parseFormatedNumber";
import MainChart from "@/components/Chart";
import { resetStyleTooltips } from "@/components/Chart/resetStyleTooltip";
import { Spin } from "antd";
import tooltipFormatter from "@/components/Chart/Tooltip";

const { colorPrimary, colorSecondary } = Config;

const options = [
  { title: "Airline", value: "airline" },
  { title: "Origin", value: "origin" },
  { title: "Dest", value: "dest" },
  { title: "JPT", value: "shipper" },
  { title: "Commodity", value: "komoditi" },
  // { label: "Sub Komoditi", value: "sub-komoditi" },
];

function ParetoChart({
  dataSource,
  title,
  loading,
  fullscreen,
  isMobile = false,
}) {
  const fontSize = useFontSize();
  const [view, setView] = useState("airline");
  const [isFullScreen, setIsFullScreen] = useState(false);

  const { trendKgs: dataset } = useMemo(() => {
    return getParetoData([], dataSource?.pareto || [], view);
  }, [dataSource, view]);

  const paretoOption = {
    grid: {
      x: !!isFullScreen ? fontSize.xl * 10 : fontSize.xl * 3,
      x2: fontSize.xl * 2,
      y: fontSize.xs,
      y2: fontSize.lg,
    },
    dataset,
    tooltip: {
      ...resetStyleTooltips,
      trigger: "axis",
      position: function (pos, params, dom, rect, size) {
        // tooltip will be fixed on the right if mouse hovering on the left,
        // and on the left if hovering on the right.
        var obj = { top: 60 };
        obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 5;
        return obj;
      },
      formatter: (params) =>
        tooltipFormatter(
          `${params[0].name} ${
            (params[0].value[3] && `(${params[0].value[3]})`) || ""
          }`,
          params[0].value[0],
          params[0].value[1]
        ),
    },
    xAxis: {
      show: false,
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
    },
    yAxis: {
      type: "category",
      axisLabel: {
        show: true,
        formatter: (params) =>
          `${
            !isFullScreen
              ? params.length > 7
                ? `${params.substring(0, 5)}...`
                : params
              : params
          }`,
      },
    },
    series: [
      {
        name: "category",
        label: {
          show: true,
          position: "right",
          formatter: (params) => `${parseFormatedNumber(params.value[0])}`,
        },
        encode: { x: "kgs", y: "code" },
        // data: sortedData?.map((item) => parseInt(item.curr)),
        type: "bar",
        color: colorPrimary,
        datasetIndex: 1,
        itemStyle: {
          borderRadius: [0, 4, 4, 0],
        },
      },
    ],
  };

  return (
    <MainChart
      title={<Fragment>Pareto Analysis {loading && <Spin />}</Fragment>}
      subTitle={"by Cargo Vol (Kg)"}
      height={fontSize.md * 16}
      fullscreen
      onFullScreen={(val) => setIsFullScreen(val)}
      view={view}
      option={paretoOption}
      filterComp={options}
      onChange={(val) => setView(val)}
    />
    // </div>
  );
}

export default ParetoChart;
