import { Typography } from "antd";
import React, { Fragment } from "react";
import { createUseStyles } from "react-jss";
import { MultiSelect } from "react-multi-select-component";
import { useFontSize } from "../../hooks/useDimension";
import {IoClose} from 'react-icons/io5'
import Config from '@/config/env'
const {colorGray} = Config;
const useStyles = createUseStyles({
  selectStyles: {
    width: "100%",
    height: "100%",
    fontSize: ({ fontSize }) => fontSize.sm,
    // borderRadius: 50,
    // border: "1px solid red",\
    "& .dropdown-content": {
      borderRadius: 8,
      position: "absolute",
      zIndex: 9,
      overflowY: "hidden",
      boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
      "& .panel-content":{
        "& .select-panel":{
          "& .options":{
            maxHeight: ({ fontSize }) => `${fontSize.xs * 18}px !important`,
          }
        }
      }
    },
    "& .dropdown-heading": {
      height: ({ fontSize, isMobile }) =>
        isMobile ? fontSize.md * 3 : fontSize.md * 2,
      background: "tranparent",
    },
    "& .dropdown-container": {
      borderRadius: "8px !important",
    },
    "& :focus-within": {
      borderColor: "transparent !important",
    },
  },
});

function MultiSelectComponent({
  hasSelectAll = true,
  options = [],
  selected = [],
  onChange,
  className,
  loading,
}) {
  const fontSize = useFontSize();
  const classes = useStyles({ fontSize });

  return (
    <div>
      <MultiSelect
        isLoading={loading}
        ClearIcon={<IoClose  />}
        hasSelectAll={hasSelectAll}
        className={`${classes.selectStyles} ${className}`}
        ClearSelectedIcon={<IoClose color={colorGray}  />}
        options={options}
        value={selected}
        onChange={onChange}
        labelledBy={"Select"}
        isCreatable={true}

        // ItemRenderer={({ checked, option, onClick, disabled }) => {
        //   return (
        //     <div
        //       style={{
        //         display: "flex",
        //         justifyContent: "space-between",
        //         height: 20,
        //         fontSize: 9,
        //         padding: 0,
        //       }}
        //       className={`item-renderer ${disabled ? "disabled" : ""}`}
        //     >
        //       <span>{option.label}</span>
        //       <input
        //         type="checkbox"
        //         onChange={onClick}
        //         checked={checked}
        //         tabIndex={-1}
        //         disabled={disabled}
        //       />
        //     </div>
        //   );
        // }}
      />
    </div>
  );
}

export default MultiSelectComponent;
