import React from 'react'
import TablePage from '@/components/pages/TablePage'
import moment from 'moment'
import { levelToRoles } from '@/redux/reducer/levelConvert';
export default () => {
    const columns = [
        {
            selector: (row) => row?.username,
            name: "Username",
            id:'username',
        },
        {
            selector: (row) => row?.name,
            name: "Name",
            id:'name',
        },
        {
            selector: (row) => row?.email,
            name: "Email",
            id:'email',
        },
        {
            selector: (row) => levelToRoles(row?.level),
            name: "Level",
            id:'level',

        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name: "CreatedAt",
            id: 'createdAt'
        },
    ]
    return (
        <TablePage
            title={"Master Users"}
            url="api/v1/users/paging"
            actionPosition={'first'}
            createPath="create"
            editPath={"edit"}
            columns={columns}
        />
    )
}