import { Col, Row, Spin } from "antd";
import React, { useEffect, useState, useMemo, Fragment } from "react";
import Filters from "./components/FiltersStakeholder";
import CardSummary from "./components/CardSummary";
import RouteComparison from "./components/RouteComparison";
import TopPartner from "./components/TopPartners";
import TopPartnerBar from "./components/TopPartnersBar";
import { useDashboardOverview } from "@/hooks/useDashboard";
import { useDispatch } from "react-redux";
import { setActivePage } from "@/redux";
import { createUseStyles } from "react-jss";
import CardMaps from "@/components/Maps/CardMaps";

const defValue = {
  airlines: "",
  agents: "",
  jaster: "",
};
const useStyles = createUseStyles({
  select: {
    // zIndex: 9999,
    "& .dropdown-container": {
      borderRadius: 8,
      position: "relative",
      zIndex: 99999999,
      width: "200px",
    },
  },
});
const options = [
  {
    value: {
      id: "1",
      name: "Marker",
      description: "...",
      position: [40.7128, -74.006],
    },
    label: "New York City, USA",
  },
  {
    value: {
      id: "2",
      name: "Marker 2",
      description: "...",
      position: [51.5074, -0.1278],
    },
    label: "London, UK",
  },
  {
    value: {
      id: "3",
      name: "Marker 3",
      description: "...",
      position: [48.8566, 2.3522],
    },
    label: "Paris, France",
  },
  {
    value: {
      id: "4",
      name: "Marker 4",
      description: "...",
      position: [-33.8688, 151.2093],
    },
    label: "Sydney, Australia",
  },
  {
    value: {
      id: "5",
      name: "Marker 5",
      description: "...",
      position: [35.6895, 139.6917],
    },
    label: "Tokyo, Japan",
  },
];
export default function StakeHolder({ filterValue }) {
  const classes = useStyles();
  const [state, setState] = useState(defValue);
  const [origin, setOrigin] = useState(options);
  const [destination, setDestination] = useState(options);
  const [route, rLoading] = useDashboardOverview({
    ...filterValue,
    type: "route",
    view: "stakeholder",
  });
  const [commodities, cLoading] = useDashboardOverview({
    ...filterValue,
    type: "commodity",
    view: "stakeholder",
  });
  const [panel, pLoading] = useDashboardOverview({
    ...filterValue,
    type: "panel",
    view: "stakeholder",
  });
  const [demograph, dLoading] = useDashboardOverview({
    ...filterValue,
    type: "demograph",
    view: "stakeholder",
  });

  const [map, mLoading] = useDashboardOverview({
    ...filterValue,
    type: "map",
    view: "stakeholder",
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActivePage("Stakeholders"));
  }, []);

  const handleOnChange = (type, value) => {
    switch (type) {
      case "airlines":
        setState((state) => ({ ...state, airlines: value }));
        break;
      case "agents":
        setState((state) => ({ ...state, agents: value }));
        break;

      default:
        break;
    }
  };

  const originMap = useMemo(() => {
    const tempMap = [];
    origin.forEach((item) => {
      tempMap.push(item.value);
    });
    return tempMap;
  }, [origin]);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        gap: 8,
      }}
    >
      <div id="left" style={{ flex: 1, height: "100%" }}>
        <Row gutter={8} style={{ height: "inherit" }}>
          <Col lg={{ span: 8 }} style={{ height: "inherit" }}>
            <div
              style={{
                height: "inherit",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div style={{ height: "20%" }}>
                <Filters onChange={handleOnChange} state={state} data={panel} />
              </div>
              <div style={{ height: "80%" }}>
                <CardSummary data={panel} loading={pLoading} />
              </div>
            </div>
          </Col>
          <Col lg={{ span: 5 }} style={{ height: "inherit" }}>
            <div style={{ height: "inherit" }}>
              <RouteComparison data={route} loading={rLoading} />
            </div>
          </Col>
          <Col lg={{ span: 11 }} style={{ height: "inherit" }}>
            <div style={{ height: "inherit" }}>
              <TopPartner data={demograph} loading={dLoading} />
            </div>
          </Col>
        </Row>
      </div>
      <div id="right" style={{ flex: 1, height: "100%" }}>
        <Row gutter={8} style={{ height: "inherit" }}>
          <Col lg={{ span: 8 }} style={{ height: "inherit" }}>
            <div style={{ height: "inherit" }}>
              <TopPartnerBar data={commodities} loading={cLoading} />
            </div>
          </Col>
          <Col lg={{ span: 8 }} style={{ height: "inherit" }}>
            <div style={{ height: "inherit" }}>
              <CardMaps
                title={<Fragment>Origin {!!mLoading && <Spin />}</Fragment>}
                fullscreen
                mulitple
                options={options}
                selected={origin}
                setSelected={setOrigin}
                height="86%"
                data={map?.incoming || []}
              />
            </div>
          </Col>
          <Col lg={{ span: 8 }} style={{ height: "inherit" }}>
            <div style={{ height: "inherit", width: "100%" }}>
              <CardMaps
                title={<Fragment>Destination {!!mLoading && <Spin />}</Fragment>}
                fullscreen
                mulitple
                options={options}
                selected={destination}
                setSelected={setDestination}
                height="86%"
                data={map?.outgoing || []}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
