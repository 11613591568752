import moment from "moment";

export const groupingData = (currentYear, lastYear, groupBy) => {
  let result = {};
  for (let iii = 0; iii < currentYear.length; iii++) {
    const { totalPjkp2u, totalChw, _id } = currentYear[iii];
    const by = groupBy(_id);
    const found = result[by]?.curr;

    if (!found) {
      result[by] = {
        ...result[by],
        curr: { totalPjkp2u, totalChw },
      };
    } else {
      const sumPjkp2u = (result[by].curr.totalPjkp2u += totalPjkp2u);
      const sumChw = (result[by].curr.totalChw += totalChw);
      result[by] = {
        ...result[by],
        curr: { totalPjkp2u: sumPjkp2u, totalChw: sumChw },
      };
    }
  }

  const kgs = [];
  const idrs = [];
  for (const key in result) {
    if (Object.hasOwnProperty.call(result, key)) {
      const { curr, last } = result[key];
        kgs.push({
            name: key,
            prev: last?.totalChw || 0,
            curr: curr?.totalChw || 0,
          });
          idrs.push({
            name: key,
            prev: last?.totalPjkp2u || 0,
            curr: curr?.totalPjkp2u || 0,
          });
    }
  }

  return { trendKgs: kgs, trendPjkp2u: idrs };
};


export const getDataTrendByView = (lastYear, currentYear, view) => {
  switch (view) {
    case "daily":
      return groupingData(lastYear, currentYear, (row) =>
        row?.substring(0, 6)
      );
    case "weekly":
      return groupingData(lastYear, currentYear, (row) =>
        moment(row, "DD-MMM-YYYY").week()
      );
      case "monthly":
      return groupingData(lastYear, currentYear, (row) =>
        moment(row, "DD-MMM-YYYY").format("MMM")
      );
    case "quarter":
      return groupingData(lastYear, currentYear, (row) =>
        moment(row, "DD-MMM-YYYY").quarter()
      );
    default:
      return;
  }
};
