import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import LayoutComponent from "@/components/Layout/Layout";
import { Layout } from "antd";
import FilterV2 from "@/components/FilterV2";
import moment from "moment/moment";
import { loadAirportsAp1 } from "@/redux";
import { useDispatch } from "react-redux";
import OverviewNew from "./pages/overviewV2/OverviewNew";
import { useFontSize } from "@/hooks/useDimension";
import Config from "@/config/env";
import StakeHolder from "./pages/stakeholder";
import Commodity from "./pages/commodity";
import Airport from "./pages/airport";
import Perform from "./pages/perform";
const { Content } = Layout;
const { colorPrimary } = Config;

const RouterPage = ({ filterValue }) => {
  return (
    <Routes>
      <Route
        path="/overview-new"
        element={<OverviewNew filterValue={filterValue} />}
      />
      <Route
        path="/stakeholders"
        element={<StakeHolder filterValue={filterValue} />}
      />
      <Route
        path="/commodity"
        element={<Commodity filterValue={filterValue} />}
      />
      <Route path="/airport" element={<Airport filterValue={filterValue} />} />
      <Route path="/perform" element={<Perform filterValue={filterValue} />} />
    </Routes>
  );
};

const defValue = {
  airports: [],
  year: moment().year(),
  direction: "",
  route: "",
  months: [],
};

function DashboardV2() {
  const dispatch = useDispatch();
  const [state, setState] = useState(defValue);
  const fontSize = useFontSize();

  const handleOnChange = (type, value) => {
    switch (type) {
      case "airports":
        setState((state) => ({ ...state, airports: value }));
        break;
      case "year":
        setState((state) => ({ ...state, year: value }));
        break;
      case "months":
        setState((state) => ({ ...state, months: value }));
        break;
      case "direction":
        if (state.direction === value) {
          setState((state) => ({ ...state, direction: "" }));
        } else {
          setState((state) => ({ ...state, direction: value }));
        }
        break;
      case "route":
        if (state.route === value) {
          setState((state) => ({ ...state, route: "" }));
        } else {
          setState((state) => ({ ...state, route: value }));
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(loadAirportsAp1());
    // dispatch(loadAirlines());
  }, []);
  // console.log({ state });
  return (
    <LayoutComponent>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div style={{ flex: "10%" }}>
          <FilterV2 state={state} onChange={handleOnChange} />
        </div>

        <div
          style={{
            flex: "90%",
            flexGrow: 1,
            padding: fontSize.xs,
            background: "#EDEDED",
          }}
        >
          <Content
            style={{
              // minheight: "calc(100vh - 90px)",
              height: "100%",

              // marginTop: "5rem",
              // marginTop: 80,
            }}
          >
            <RouterPage filterValue={state} />
          </Content>
        </div>
      </div>
    </LayoutComponent>
  );
}

export default DashboardV2;
