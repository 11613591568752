import { Button, Col, Row, Typography } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import { useFontSize } from "@/hooks/useDimension";
import MultiSelectComponent from "@/components/CustomSelect/MultiSelect";
import MySwiperComponent from "@/components/Swiper";
import { SwiperSlide } from "swiper/react";
import Config from "@/config/env";
import { useState } from "react";
const { colorPrimary } = Config;

const useStyles = createUseStyles({
  selectStyles: {
    zIndex: 99,
    position: "relative",
    width: "100%",
    height: "100%",
    fontSize: ({ fontSize }) => fontSize.sm,
    "& .dropdown-container": {
      borderRadius: 8,
    },
    "& .dropdown-heading": {
      height: ({ fontSize, isMobile }) =>
        isMobile ? fontSize.md * 3 : fontSize.md * 2,
      background: "tranparent",
    },
  },
  swiperStyles: {
    width: "100%",
    // border: "1px solid red",
    height: 30,
    marginTop: 10,
  },
});

const airportsOptions = [
  { value: "1", label: "PT. CEPET EXPRESS" },
  { value: "2", label: "PT. LAMA EXPRESS" },
  { value: "3", label: "PT. JNE" },
  { value: "4", label: "PT. JNT" },
  { value: "5", label: "PT. SHOPEE EXPRESS" },
];

const tabCommodities = [
  { label: "Dangerous Goods", value: "dangerous_goods" },
  { label: "FOC", value: "foc" },
  { label: "General Cargo", value: "general_cargo" },
  { label: "Human Remain", value: "human_remain" },
  { label: "Live Animal", value: "live_animal" },
  { label: "Marine Product", value: "marine_product" },
  { label: "Perishable Cargo", value: "perishabel_cargo" },
  { label: "Parmacheuticals", value: "parmacheuticals" },
  { label: "Rush Handling", value: "rush_handling" },
  { label: "Valuable Cargo", value: "valuable_cargo" },
  { label: "valnurable Cargo", value: "valnurable_cargo" },
];

export default function FiltersStakeholder({ isMobile = false }) {
  const fontSize = useFontSize();
  const classes = useStyles({ fontSize, isMobile });
  const [value, setValue] = useState("dangerous_goods");
  return (
    <Row
      gutter={8}
      style={{
        padding: isMobile ? "10px" : "0px 0px",
      }}
    >
      <Col
        lg={{ span: 3 }}
        sm={{ span: 6 }}
        xs={{ span: 6 }}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
        }}
      >
        <div
          style={{
            paddingBottom: "4px",
          }}
        >
          <Typography
            style={{
              lineHeight: 1,
              // color: "white",
              fontWeight: "bold",
              fontSize: fontSize.sm,
            }}
          >
            Airlines
          </Typography>
        </div>
        <div style={{ height: "100%" }}>
          <MultiSelectComponent
            options={airportsOptions}
            selected={["1"]}
            onChange={() => {}}
            //   onChange={(val) => {
            //     setAirports(val);
            //     const selected =
            //       val.length !== 0 ? val.map((item) => item.value) : [];
            //     onChange("airports", selected);
            //   }}
          />
        </div>
      </Col>
      <Col
        lg={{ span: 3 }}
        sm={{ span: 6 }}
        xs={{ span: 6 }}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
        }}
      >
        <div
          style={{
            paddingBottom: "4px",
          }}
        >
          <Typography
            style={{
              lineHeight: 1,
              // color: "white",
              fontWeight: "bold",
              fontSize: fontSize.sm,
            }}
          >
            JPT
          </Typography>
        </div>
        <div style={{ width: "100%", height: "100%" }}>
          <MultiSelectComponent
            options={airportsOptions}
            selected={["1"]}
            onChange={() => {}}
            //   onChange={(val) => {
            //     setAirports(val);
            //     const selected =
            //       val.length !== 0 ? val.map((item) => item.value) : [];
            //     onChange("airports", selected);
            //   }}
          />
        </div>
      </Col>
      <Col
        lg={{ span: 3 }}
        sm={{ span: 6 }}
        xs={{ span: 6 }}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
        }}
      >
        <div
          style={{
            paddingBottom: "4px",
          }}
        >
          <Typography
            style={{
              lineHeight: 1,
              fontWeight: "bold",
              // color: "white",
              fontSize: fontSize.sm,
            }}
          >
            Origin
          </Typography>
        </div>
        <div style={{ width: "100%", height: "100%" }}>
          <MultiSelectComponent
            options={airportsOptions}
            selected={["1"]}
            onChange={() => {}}
            //   onChange={(val) => {
            //     setAirports(val);
            //     const selected =
            //       val.length !== 0 ? val.map((item) => item.value) : [];
            //     onChange("airports", selected);
            //   }}
          />
        </div>
      </Col>
      <Col
        lg={{ span: 3 }}
        sm={{ span: 6 }}
        xs={{ span: 6 }}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
        }}
      >
        <div
          style={{
            paddingBottom: "4px",
          }}
        >
          <Typography
            style={{
              lineHeight: 1,
              fontWeight: "bold",
              // color: "white",
              fontSize: fontSize.sm,
            }}
          >
            Destination
          </Typography>
        </div>
        <div style={{ width: "100%", height: "100%" }}>
          <MultiSelectComponent
            options={airportsOptions}
            selected={["1"]}
            onChange={() => {}}
            //   onChange={(val) => {
            //     setAirports(val);
            //     const selected =
            //       val.length !== 0 ? val.map((item) => item.value) : [];
            //     onChange("airports", selected);
            //   }}
          />
        </div>
      </Col>
      <Col
        lg={{ span: 12 }}
        xs={{ span: 24 }}
        style={{
          marginTop: isMobile && "10px",
          flexGrow: 1,
          // border: "1px solid red",
          width: "1px",
        }}
      >
        <Typography
          style={{
            lineHeight: 1,
            fontWeight: "bold",
            fontSize: fontSize.sm,
          }}
        >
          Commodities
        </Typography>
        <div
          style={{
            // border: "1px solid red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <MySwiperComponent
            slidesPerView={isMobile ? 4.5 : 5}
            spaceBetween={4}
            className={classes.swiperStyles}
          >
            {tabCommodities.map((item, idx) => (
              <SwiperSlide
                style={{
                  height: fontSize.xl + 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 100,
                }}
                key={idx}
              >
                <Button
                  onClick={() => {
                    setValue(item.value);
                  }}
                  type={item.value === value ? "primary" : "default"}
                  style={{
                    // fontWeight: item.value === value && "bold",
                    fontSize: fontSize.sm,
                    // width: "140px",
                    // padding: "5px",
                    // border: `1px solid ${colorPrimary}`,
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 8,
                    // background: item.value === value ? colorPrimary : "",
                    // height: fontSize.xl + 10,
                  }}
                >
                  {item.label}
                </Button>
              </SwiperSlide>
            ))}
          </MySwiperComponent>
        </div>
      </Col>
    </Row>
  );
}
