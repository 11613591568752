import { Divider, Typography } from 'antd'
import React, { Fragment, useEffect} from 'react'
import { useSelector } from 'react-redux';
import CONFIG from '../../config/env';
import { useLanguage } from '../../hooks/useLanguage';
import {getLanguage} from '../../languages/utils';
import { useFontSize } from '@/hooks/useDimension';
const {Title} = CONFIG;

export default ({ title, children})=>{
    useEffect(()=>{
        document.title=`${Title} (${title})`;
    }, [])
    const {language} = useSelector(state=>state.apps);
    const [Lang] = useLanguage(language);
    const fontSize=useFontSize();
    return (
        <Fragment>
            <Typography style={{fontSize:fontSize.lg, fontWeight:"bold"}}>{getLanguage(`${title}`.toLowerCase(), Lang)}</Typography>
            {/* <Divider style={{marginBlock:8}} /> */}
            <div style={{display:'flex', flexDirection:'column'}}>
                {children}
            </div>
        </Fragment>
    )
}