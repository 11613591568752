import React from 'react'
import TablePage from '@/components/pages/TablePage'
import moment from 'moment'
export default () => {
    const columns=[
        {
            selector:(row)=>row?.airport_id?.code,
            name:"IATA",
            id:'code',
            compact:true
        },
        {
            selector:(row)=>row?.airport_id?.name,
            name:"Name",
            id:'name'
        },
        {
            selector:(row)=>row?.airport_id?.kota,
            name:"City",
            id:'kota'
        },
        {
            selector:(row)=>row?.dom_capacity,
            name:"Dom. Capacity",
            id:'dom_capacity',
            right:true

        },
        {
            selector:(row)=>row?.inter_capacity,
            name:"Inter. Capacity",
            id:'inter_capacity',
            right:true
        },
        {
            selector:(row)=>row?.dom_luas,
            name:"Dom. Luas",
            id:'dom_luas',
            right:true
        },
        {
            selector:(row)=>row?.inter_luas,
            name:"Inter. Luas",
            id:'inter_luas',
            right:true
        },
        {
            selector:(row)=>row?.dom_luas_60,
            name:"Dom. Luas 60",
            id:'dom_luas',
            right:true
        },
        {
            selector:(row)=>row?.inter_luas_60,
            name:"Inter. Luas 60",
            id:'inter_luas',
            right:true
        },
        
        // {
        //     selector:(row)=>(row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
        //     name:"CreatedAt",
        //     id:'created_at'
        // },
    ]
    return (
        <TablePage
            title={"Master Operated Airport"}
            url="api/v1/operated_airport"
            actionPosition={'first'}
            createPath="create"
            editPath={"edit"}
            columns={columns}        
        />
    )
}