import React, { useMemo, useState } from "react";
import { Avatar, Button, Dropdown, Layout, Menu, Typography } from "antd";
// import './layout.css';
import { UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMenu, setOpenKeys } from "../../redux";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import Logo from "@/assets/images/logo-splash.png";
import { UserProfile } from "./userProfile";
import { Scrollbars } from "react-custom-scrollbars";
import { createUseStyles } from "react-jss";
import Config from "@/config/env";
import { FaBars, FaRegUser } from "react-icons/fa";
import { BiLogOutCircle } from "react-icons/bi";
import { doLogout, logout } from "@/redux/reducer/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LOGO from "@/assets/logos/logo-ap1.png";

const { colorPrimary, colorGray, colorSecondary } = Config;
const { Header, Content, Footer, Sider } = Layout;

const useStyles = createUseStyles({
  content: {
    padding: 12,
    // background: "#dadada",
  },
  header: {
    display: "flex",
    // position: "sticky",
    zIndex: 99,
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
    "&.ant-layout-header": {
      padding: "2px 16px",
      background: "white",
      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
    },
  },
  menu: {
    background: "none",
    color: "#dadada",
    padding: 4,
    "&.ant-menu-dark .ant-menu-item": {
      // paddingLeft: 4,
      //   padding: 8,
    },
    "&.ant-menu-dark .ant-menu-item-group-title": {
      // color:({ selectedMenuBg }) => selectedMenuBg
      fontWeight: "bold",
    },
    "&.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline": {
      background: "none",
      //   marginLeft: 8,
    },
    "&.ant-menu-dark  .ant-menu-item-selected": {
      // background:"rgba(0, 0, 0, 0.2)",
      background:({colorSecondary})=>colorSecondary,
      fontWeight: "bold",
      color: "white",
      //   paddingLeft: 2,
      // margin: 0
    },
    "& a": {
      color: "#dadada",
    },
  },
  logo: {
    padding: 14,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
    // color: ({ isDark }) => isDark ? "#FFF":"#000",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: 18,
    // borderBottom: "1px solid #adadad",
  },
});

export const SiderLayout = ({
  children,
  menus,
  title,
  theme,
  headerBgColor,
  headerColor,
}) => {
  const classes = useStyles({colorSecondary});
  const { selectedMenu, openKeys } = useSelector((state) => state.nav);
  const { userdata } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [colapsed, setColapsed] = useState(false);
  const rootSubmenuKeys = useMemo(() => {
    return menus.map(({ key }) => key);
  }, [menus]);
  const renderThumb = ({ style }) => {
    return <div style={{ ...style, backgroundColor: "#eecccc6e" }} />;
  };


  const items = [
    {
      label: (
        <Link
          to={'profile'}
          style={{
            display: "flex",
            gap: 16,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <FaRegUser size={12} />
          <Typography>Profile</Typography>
        </Link>
      ),
      key: "1",
    },
    {
      label: (
        <div
          onClick={() => dispatch(logout())}
          style={{
            display: "flex",
            gap: 16,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BiLogOutCircle size={12} />
          <Typography>Logout</Typography>
        </div>
      ),
      key: "2",
    },
  ];
  return (
    <Layout hasSider>
      <Sider
        style={{
          height: "100vh",
          position: "fixed",
          background: "#203864",
          top: 0,
          bottom: 0,
          left: 0,
        }}
        breakpoint="lg"
        theme={theme || "dark"}
        collapsed={colapsed}
      >
        <div className={classes.logo}>
          <img src={LOGO} alt="Logo" width={60} />
          {/* LOGO */}
        </div>
        {/* <Scrollbars renderThumbVertical={renderThumb}> */}
        <Menu
          className={classes.menu}
          mode="inline"
          selectedKeys={[selectedMenu]}
          theme={theme || "dark"}
          items={menus || []}
          onClick={(e) => {
            dispatch(setSelectedMenu(e.key));
          }}
          openKeys={openKeys}
          inlineIndent={8}
          onOpenChange={(keys) => {
            const latestOpenKey = keys.find(
              (key) => openKeys.indexOf(key) === -1
            );
            if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
              dispatch(setOpenKeys(keys));
            } else {
              dispatch(setOpenKeys(latestOpenKey ? [latestOpenKey] : []));
            }
          }}
        />
        {/* </Scrollbars> */}
      </Sider>
      <Layout style={{ marginLeft: colapsed ? 80 : 200, minHeight: "100vh" }}>
        <Header className={classes.header}>
          <div
            className="header-left"
            style={{ display: "flex", alignItems: "center" }}
          >
            {!!colapsed ? (
              <Button
                onClick={() => {
                  setColapsed(!colapsed);
                }}
                type="link"
                icon={
                  <FaBars color={colorGray} size={18} className="trigger" />
                }
              />
            ) : (
              <Button
                type="link"
                onClick={() => {
                  setColapsed(!colapsed);
                }}
                icon={
                  <FaBars color={colorGray} size={18} className="trigger" />
                }
              />
            )}
          </div>

          <div
            className="header-right"
            style={{ display: "flex", alignItems: "center", gap: 8 }}
          >
            {/* <Button onClick={()=>toggleDarkMode(!isDark)} icon={!!isDark? <HiMoon
                  size={20}
                color={"#f8e71c"}
                /> : <HiOutlineSun
                size={21}
                color={grey[4]} 
              />} /> */}
            <Dropdown arrow trigger={["click"]} menu={{ items }}>
              <Avatar
                size="large"
                icon={<UserOutlined />}
                style={{ backgroundColor: colorPrimary, cursor: "pointer" }}
              />
            </Dropdown>
          </div>
        </Header>
        <Scrollbars renderThumbVertical={renderThumb}>
          <Content
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: `calc(100%)`,
            }}
          >
            <div
              className={classes.content}
              style={{flexGrow: 1 }}
            >
              {children}
            </div>
          </Content>
        </Scrollbars>
        <Footer style={{ textAlign: "center", height: 28 }}>
          COPYRIGHT © 2022{" "}
          <a target={"_blank"} href="https://edifly-si.com/">
            Edifly Solusi Indonesia
          </a>
          , All rights Reserved
        </Footer>
      </Layout>
    </Layout>
  );
};
