import React, { useRef } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/swiper-bundle.min.css";

const MySwiperComponent = ({
  style,
  children,
  slidesPerView,
  spaceBetween,
  className,
}) => {
  const swiperRef = useRef(null);

  const handleNextClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrevClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  return (
    <div className={className}>
      {/* <BiChevronLeft
        style={{ cursor: "pointer" }}
        size={40}
        onClick={handlePrevClick}
      /> */}
      <Swiper
        ref={swiperRef}
        style={style}
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
      >
        {children}
      </Swiper>
      {/* <BiChevronRight
        style={{ cursor: "pointer" }}
        size={40}
        onClick={handleNextClick}
      /> */}
    </div>
  );
};

export default MySwiperComponent;
