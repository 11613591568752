import React, { useMemo, useState } from "react";
import ProductionCard from "./components/ProductionCard";
import { useDashboardOverview } from "@/hooks/useDashboard";
import RevenueCard from "./components/RevenueCard";
import RouteComparation from "./components/RouteComparation";
import ShippingRoute from "./components/ShippingRoute";
import ParetoChart from "./components/Pareto";
import { setActivePage } from "@/redux";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { formatedOptions } from "@/helper/formatedOptions";

export default function Airport({ filterValue }) {
  const dispatch = useDispatch();
  const { airportAp1Cache } = useSelector((state) => state.apps);
  const [loading, setLoading] = useState(false);
  const [routes, setRoutes] = useState([
    {
      label: "UPG/Makassar Sultan Hasanuddin International Airport",
      value: "60a3d866045fd93f38efc1f6",
    },
  ]);
  const formatedOpt = formatedOptions(airportAp1Cache, ["code", "name"], "_id");

  const [route, rLoading] = useDashboardOverview({
    ...filterValue,
    type: "route",
    view: "airport",
  });

  const [panel, loadingPanel] = useDashboardOverview({
    ...filterValue,
    type: "panel",
  });

  const [pareto, loadingPareto] = useDashboardOverview({
    ...filterValue,
    type: "pareto",
  });

  const [production, pLoading] = useDashboardOverview({
    ...filterValue,
    type: "production",
    view: "airport",
  });

  const [routeComparison, rcLoading] = useDashboardOverview({
    ...filterValue,
    type: "route_comparison",
    view: "airport",
  });

  const idOrigin = useMemo(() => {
    return routes.map((item) => {
      return item.value;
    });
  }, [routes]);

  const filterRoutes = useMemo(() => {
    return (
      !!route &&
      route?.filter((item) => {
        return idOrigin.includes(item.origin._id);
      })
    );
  }, [routes, route]);

  const shippingRoutes = useMemo(() => {
    return (
      !!filterRoutes &&
      filterRoutes?.map((item) => {
        return {
          code: [item.origin.code, item.dest.code],
          country: [item.origin.country, item.dest.country],
          kota: [item.origin.kota, item.dest.kota],
          lonlat: [
            [item?.origin?.lat || 0, item?.origin?.lon || 0],
            [item?.dest?.lat || 0, item?.dest?.lon || 0],
          ],
        };
      })
    );
  }, [route, routes, filterRoutes]);

  const originRoutes = useMemo(() => {
    return (
      !!shippingRoutes &&
      shippingRoutes.map((item) => {
        const [lat] = item.lonlat || [0];
        const [name] = item.name || [""];
        return [lat, name];
      })
    );
  }, [shippingRoutes]);

  useEffect(() => {
    dispatch(setActivePage("Airport"));
  }, []);

  useEffect(() => {
    if (formatedOpt[0] !== undefined) {
      setRoutes([formatedOpt[0]]);
    }
  }, [airportAp1Cache]);
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 8,
      }}
    >
      <div id="top" style={{ flex: 1.1 }}>
        <div style={{ height: "100%", display: "flex", gap: 8 }}>
          <div style={{ height: "100%", width: "50%" }}>
            <ProductionCard
              data={production}
              loading={pLoading}
              summary={panel}
            />
          </div>
          <div style={{ height: "100%", width: "50%" }}>
            <RevenueCard data={production} loading={pLoading} />
          </div>
        </div>
      </div>
      <div id="bottom" style={{ flex: 1 }}>
        <div style={{ height: "100%", display: "flex", gap: 8 }}>
          <div style={{ height: "100%", width: "40%" }}>
            <ShippingRoute
              loading={loading}
              origin={originRoutes}
              selected={routes}
              setSelected={setRoutes}
              options={formatedOpt}
              data={shippingRoutes}
            />
          </div>
          <div style={{ height: "100%", width: "20%" }}>
            <RouteComparation data={routeComparison} loading={rcLoading} />
          </div>
          <div style={{ height: "100%", width: "40%" }}>
            <ParetoChart dataSource={pareto} loading={loadingPareto} />
          </div>
        </div>
      </div>
    </div>
  );
}
