import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {levels} from './levelConvert';
import { GetAPI } from './apiHandling'
const Apps = createSlice({
    name: 'apps',
    initialState: { errorMessage: '',levels, activePage:'', loading: false, airportCache: [],airportAp1Cache: [], airlineCache: [], configCache: {} },
    reducers: {
        setActivePage(state, payload) {
            state.activePage=payload.payload
        },
        setLoading(state) {
            state.loading = true
        },
        unsetLoading(state) {
            state.loading = false
        },
        setAirportAp1(state, payload) {
            state.airportAp1Cache = payload.payload
        },
        setAirline(state, payload) {
            state.airlineCache = payload.payload
        },
        setConfig(state, payload) {
            state.configCache = payload.payload
        }
    }
})

export const { setLoading, unsetLoading, setAirline, setAirport, setConfig, setAirportAp1, setActivePage } = Apps.actions
export const loadAirportsAp1 = createAsyncThunk(
    'apps/load-airport-ap1', async (payload, thunkApi) => {
        const { dispatch, getState } = thunkApi
        const { auth: { url: uri } } = getState();
        const url = uri + 'api/airport/angkasa-pura-1';
        const resp = await dispatch(GetAPI({ url }));
        if (resp?.payload?.data) dispatch(setAirportAp1(resp.payload.data))
    }

)

export const loadAirlines = createAsyncThunk(
    'apps/load-airlines', async (payload, thunkApi) => {
        const { dispatch, getState } = thunkApi
        const { auth: { url: uri } } = getState();
        const url = uri + 'api/airlines/all';
        const resp = await dispatch(GetAPI({ url }));
        if (resp?.payload?.data) dispatch(setAirline(resp.payload.data));
    }
)

export const loadConfig = createAsyncThunk(
    'apps/load-config', async (payload, thunkApi) => {
        const { dispatch, getState } = thunkApi
        const { auth: { url: uri } } = getState();
        const url = uri + 'api/v1/apps/one';
        const resp = await dispatch(GetAPI({ url }));
        if (resp?.payload?.data) dispatch(setConfig(resp.payload.data));
    }
)

export default Apps.reducer