import React from 'react'
import TablePage from '../../../../../components/pages/TablePage'
import moment from 'moment'
export default () => {
    const columns=[
        {
            selector:(row)=>row?.name,
            name:"Name",
            id:'name'
        },
        {
            selector:(row)=>row?.code,
            name:"Code",
            id:'code'
        },
        {
            selector:(row)=>row?.company_name,
            name:"Company Name",
            id:'company_name'
        },
        {
            selector:(row)=>(row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name:"CreatedAt",
            id:'createdAt'
        },
    ]
    return (
        <TablePage
            title={"Master CTO"}
            url="api/v1/cto"
            actionPosition={'first'}
            createPath="create"
            editPath={"edit"}
            columns={columns}            
        />
    )
}