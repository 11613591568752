import React, { useMemo } from "react";
import ReactECharts from "echarts-for-react";
import dayjs from "dayjs";
import MainCard from "@/components/MainCard";
import { Spin, Typography } from "antd";
import { useFontSize } from "@/hooks/useDimension";
import Config from "@/config/env";
import { resetStyleTooltips } from "@/components/Chart/resetStyleTooltip";
import numeral from "numeral";
const { colorPrimary } = Config;

function CalenderChart({ isMobile = false, data, loading, filterValue }) {
  const fontSize = useFontSize();

  const callenderData = useMemo(() => {
    // const dataTemp = [];
    if (!data) return [];
    const { callender } = data;
    const dataTemp = callender?.map((item) => {
      return [dayjs(item._id).format("YYYY-MM-DD"), item.totalChw];
    });
    return dataTemp;
  }, [data]);

  const optionHeatMap = {

    tooltip: {
      trigger: "item",
      ...resetStyleTooltips,
      position: isMobile ? "right" : "top",
      formatter: (params) => {
        const format = dayjs(params.data[0]).format("YYYY-MM-DD");
        return `
        <div class="main-tooltip">
          <div style="margin-bottom:4px;">
            <strong>${format}</strong>
          </div>
          <div class="container-tooltip">
            <div class="item-tooltip">
              <div>
               ${params.marker} Production Kg
              </div>
              <div class="text-right text-bold">
                ${numeral(params.data[1]).format("0,000")}
              </div>
            <div>
          </div>
        </div>
        `;
      },
      // formatter: function (p) {
      //   console.log(p.params);
      //   const format = dayjs(p.data[0]).format("YYYY-MM-DD");
      //   return format + ": " + p.data[1];
      // },
    },
    visualMap: {
      show: false,
      min: 0,
      max: Math.max(...callenderData?.map((item, idx) => item[1])),
      calculable: true,
      left: "50",
      top: "center",
      inRange: {
        color: ["#c4d3e0", "#094780"], // Color range from blue to red
      },
    },
    calendar: [
      {
        orient: isMobile ? "horizontal" : "vertical",
        range: `${filterValue?.year}` || "2023",
        cellSize: "auto",
        top: isMobile ? 50 : 30,
        left: isMobile ? 15 : 64,
        right: isMobile ? 5 : 12,
        yearLabel: {
          show: true,
          position: isMobile ? "top" : "left",
          // margin: 50,
          fontSize: "24px",
          color: "#094780",
          margin: isMobile ? 25 : 30,
        },
        itemStyle: {
        },
      },
    ],
    series: [
      {
        dataLabels: {
          overflow: "none",
          crop: true,
          enabled: true,
          style: {
            fontWeight: "normal",
          },
        },
        type: "heatmap",
        coordinateSystem: "calendar",
        data: callenderData,
        calendarIndex: 0,
        itemStyle: {
          borderWidth: 1,
          borderColor: "white",
        },
      },
    ],
  };
  return (
    <MainCard>
      <div style={{ padding: 12, height: "100%" }}>
        <Typography
          style={{
            fontSize: fontSize.md,
            fontWeight: "bold",
            color: colorPrimary,
          }}
        >
          Shipping Cycle {loading && <Spin />}
        </Typography>
        <div style={{ height: "inherit" }}>
          {filterValue?.year && (
            <ReactECharts
              onEvents={{
                click: (params) => console.log(params),
              }}
              style={{ height: isMobile ? fontSize.lg * 8 : fontSize.md * 39 }}
              option={optionHeatMap}
            />
          )}
        </div>
      </div>
    </MainCard>
  );
}

export default CalenderChart;
