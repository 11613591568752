import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route } from "react-router-dom";
import moment from "moment/moment";
import MobileLayout from "@/components/Layout/MobileLayout";
import { loadAirportsAp1 } from "@/redux/reducer/apps";
import Overview from "../overview";
import Stakeholders from "../stakeholders";
import Commodity from "../commodity";
import Airport from "../airport";

const RouterPage = ({ state }) => {
  return (
    <Routes>
      <Route path="/overview-new" element={<Overview state={state} />} />
      <Route
        path="/stakeholders"
        element={<Stakeholders filterValue={state} />}
      />
      <Route path="/commodity" element={<Commodity filterValue={state} />} />
      <Route path="/airport" element={<Airport filterValue={state} />} />
    </Routes>
  );
};

const defValue = {
  airports: [],
  year: moment().year(),
  direction: "",
  route: "",
  months: [],
};

function MobileDashboard() {
  const dispatch = useDispatch();
  const [state, setState] = useState(defValue);
  // const fontSize = useFontSize();

  const handleOnChange = (type, value) => {
    switch (type) {
      case "airports":
        setState((state) => ({ ...state, airports: value }));
        break;
      case "year":
        setState((state) => ({ ...state, year: value }));
        break;
      case "months":
        setState((state) => ({ ...state, months: value }));
        break;
      case "direction":
        if (!!state.direction) {
          setState((state) => ({ ...state, direction: "" }));
        } else {
          setState((state) => ({ ...state, direction: value }));
        }
        break;
      case "route":
        if (!!state.route) {
          setState((state) => ({ ...state, route: "" }));
        } else {
          setState((state) => ({ ...state, route: value }));
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(loadAirportsAp1());
  }, []);

  return (
    <MobileLayout filterValue={state} onChange={handleOnChange}>
      <div style={{ border: "" }}>
        <RouterPage state={state} />
      </div>
    </MobileLayout>
  );
}

export default MobileDashboard;
