import { HiOutlinePresentationChartBar, HiOutlineHome } from "react-icons/hi";
import {
  GiCommercialAirplane,
  GiBasket,
  GiPlanetConquest,
  GiBroom,
} from "react-icons/gi";
import { BsSpeedometer } from "react-icons/bs";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";

export const items = ({ fontSize, isMobile = false }) => [
  {
    key: "1",
    icon: (
      <Tooltip
        zIndex={9999}
        placement={isMobile ? "left" : "right"}
        title="Overview"
      >
        <Link to={"/dashboard-v2/overview-new"}>
          <HiOutlinePresentationChartBar size={fontSize} />
        </Link>
      </Tooltip>
    ),
    href: "/dashboard-v2/overview-new",
  },
  {
    key: "2",
    icon: (
      <Tooltip
        zIndex={9999}
        placement={isMobile ? "left" : "right"}
        title="Stakeholders"
      >
        <Link to={"/dashboard-v2/stakeholders"}>
          <GiCommercialAirplane size={fontSize} />
        </Link>
      </Tooltip>
    ),
    href: "/dashboard-v2/stakeholders",
  },
  {
    key: "3",
    icon: (
      <Tooltip
        zIndex={9999}
        placement={isMobile ? "left" : "right"}
        title="Commodity"
      >
        <Link to={"/dashboard-v2/commodity"}>
          <GiBasket size={fontSize} />
        </Link>
      </Tooltip>
    ),
    href: "/dashboard-v2/commodity",
  },
  {
    key: "4",
    icon: (
      <Tooltip
        zIndex={9999}
        placement={isMobile ? "left" : "right"}
        title="Airport"
      >
        <Link to={"/dashboard-v2/airport"}>
          <GiPlanetConquest size={fontSize} />
        </Link>
      </Tooltip>
    ),
    href: "/dashboard-v2/airport",
  },
  {
    key: "5",
    icon: (
      <Tooltip
        zIndex={9999}
        placement={isMobile ? "left" : "right"}
        title="Perform"
      >
        <Link to={"/dashboard-v2/perform"}>
          <BsSpeedometer size={fontSize} />
        </Link>
      </Tooltip>
    ),
    href: "/dashboard-v2/perform",
  },
];

export const iconBottom = ({ fontSize }) => [
  {
    key: "1",
    icon: (
      <Tooltip placement="right" title="Home">
        <Link to={"/"}>
          <HiOutlineHome size={fontSize} />
        </Link>
      </Tooltip>
    ),
    href: "/",
  },
  // {
  //   key: "2",
  //   icon: (
  //     <Tooltip placement="right" title="Clear">
  //       <Link to={""}>
  //         <GiBroom size={fontSize} />
  //       </Link>
  //     </Tooltip>
  //   ),
  //   href: "",
  // },
];

// export default items;
