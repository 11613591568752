import { Col, Form, Input, Row } from "antd";
import React, { useMemo, useState } from "react";
import FormPage from "@/components/pages/FormPage";
import { useSelector } from "react-redux";

const DefModel = {
  code: "",
  id_name: "",
  categories: "",
  en_name: "",
  bab_penjelasan: "",
};

export default () => {
  const [model, setModel] = useState(DefModel);
  const { airportCache: airport_cache } = useSelector((state) => state.apps);
  const valid = useMemo(() => {
    return true;
  }, [model]);
  return (
    <FormPage
      url={"api/v1/hscode"}
      callbackPath={"master/commodities/hscode"}
      formValid={false}
      model={model}
      setModel={setModel}
      wrapperCol={{ span: 24 }}
      labelCol={{ span: 24 }}
      title={"HS code"}
      onSaveEvent={(rows) => ({
        ...rows,
        categories: Array.isArray(rows?.categories)
          ? rows?.categories
          : rows?.categories?.split(","),
        bab_penjelasan: Array.isArray(rows?.bab_penjelasan)
          ? rows?.bab_penjelasan
          : rows?.bab_penjelasan?.split(","),
      })}
      emptyModel={DefModel}
    >
      <Row gutter={16}>
        <Col lg={24}>
          <Form.Item name={"code"} label="Code" rules={[{ required: true }]}>
            <Input autoFocus />
          </Form.Item>
        </Col>
        <Col lg={12}>
          <Form.Item name={"categories"} label="Categories (xxx,xxx)">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col lg={12}>
          <Form.Item name={"bab_penjelasan"} label="Description (xxx,xxx)">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col lg={12}>
          <Form.Item name={"id_name"} label="Name(ID)">
            <Input.TextArea rows={6} />
          </Form.Item>
        </Col>
        <Col lg={12}>
          <Form.Item name={"en_name"} label="Name(ENG)">
            <Input.TextArea rows={6} />
          </Form.Item>
        </Col>
      </Row>
    </FormPage>
  );
};
