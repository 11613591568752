import Home from "./pages/home";
import MasterAirlines from "./pages/master/airlines"
import FormAirlines from "./pages/master/airlines/form"

import MasterAirports from "./pages/master/airports"
import FormAirports from "./pages/master/airports/form"

import MasterOpAirports from "./pages/master/operated_airport"
import FormOpAirports from "./pages/master/operated_airport/form"

import MasterUsers from "./pages/master/users"
import FormUsers from "./pages/master/users/form"

import MasterCTO from "./pages/master/cto"
import FormCTO from "./pages/master/cto/form"

import MasterGoods from "./pages/master/commodity/goods"
import FormGoods from "./pages/master/commodity/goods/form"

import MasterCommHscode from "./pages/master/commodity/hscode"
import FormCommHscode from "./pages/master/commodity/hscode/form"

import MasterShc from "./pages/master/commodity/shc"
import FormShc from "./pages/master/commodity/shc/form"

import MasterCategory from "./pages/master/commodity/category"
import FormCategory from "./pages/master/commodity/category/form"

import MasterJPT from "./pages/master/jpt"
import FormJPT from "./pages/master/jpt/form"

import MasterStaff from "./pages/master/staff"
import FormStaff from "./pages/master/staff/form"

import MasterCabangParams from "./pages/cabang-params"
import FormCabangParams from "./pages/cabang-params/form"


export const Routing = [
    {
        to:'/home',
        level:0x1f00,
        component: <Home />
    },
    {
        to:'/master/airlines',
        level:0x1f00,
        component: <MasterAirlines />
    },
    {
        to:'/master/airlines/create',
        level:0x1f00,
        component: <FormAirlines />
    },
    {
        to:'/master/airlines/edit',
        level:0x1f00,
        component: <FormAirlines />
    },

    {
        to:'/master/airports',
        level:0x1f00,
        component: <MasterAirports />
    },
    {
        to:'/master/airports/create',
        level:0x1f00,
        component: <FormAirports />
    },
    {
        to:'/master/airports/edit',
        level:0x1f00,
        component: <FormAirports />
    },

    {
        to:'/master/operated_airport',
        level:0x1f00,
        component: <MasterOpAirports />
    },
    {
        to:'/master/operated_airport/create',
        level:0x1f00,
        component: <FormOpAirports />
    },
    {
        to:'/master/operated_airport/edit',
        level:0x1f00,
        component: <FormOpAirports />
    },

    {
        to:'/master/staff',
        level:0x1f00,
        component: <MasterStaff />
    },
    {
        to:'/master/staff/create',
        level:0x1f00,
        component: <FormStaff />
    },
    {
        to:'/master/staff/edit',
        level:0x1f00,
        component: <FormStaff />
    },

    {
        to:'/master/cto',
        level:0x1f00,
        component: <MasterCTO />
    },
    {
        to:'/master/cto/create',
        level:0x1f00,
        component: <FormCTO />
    },
    {
        to:'/master/cto/edit',
        level:0x1f00,
        component: <FormCTO />
    },


    {
        to:'/master/commodity/goods',
        level:0x1f00,
        component: <MasterGoods />
    },
    {
        to:'/master/commodity/goods/create',
        level:0x1f00,
        component: <FormGoods />
    },
    {
        to:'/master/commodity/goods/edit',
        level:0x1f00,
        component: <FormGoods />
    },


    {
        to:'/master/commodity/hscode',
        level:0x1f00,
        component: <MasterCommHscode />
    },
    {
        to:'/master/commodity/hscode/create',
        level:0x1f00,
        component: <FormCommHscode />
    },
    {
        to:'/master/commodity/hscode/edit',
        level:0x1f00,
        component: <FormCommHscode />
    },

    {
        to:'/master/commodity/shc',
        level:0x1f00,
        component: <MasterShc />
    },
    {
        to:'/master/commodity/shc/create',
        level:0x1f00,
        component: <FormShc />
    },
    {
        to:'/master/commodity/shc/edit',
        level:0x1f00,
        component: <FormShc />
    },

    {
        to:'/master/commodity/category',
        level:0x1f00,
        component: <MasterCategory />
    },
    {
        to:'/master/commodity/category/create',
        level:0x1f00,
        component: <FormCategory />
    },
    {
        to:'/master/commodity/category/edit',
        level:0x1f00,
        component: <FormCategory />
    },

    {
        to:'/cabang-params',
        level:0x1f00,
        component: <MasterCabangParams />
    },
    {
        to:'/cabang-params/create',
        level:0x1f00,
        component: <FormCabangParams />
    },
    {
        to:'/cabang-params/edit',
        level:0x1f00,
        component: <FormCabangParams />
    },

    {
        to:'/master/jpt',
        level:0x1f00,
        component: <MasterJPT />
    },
    {
        to:'/master/jpt/create',
        level:0x1f00,
        component: <FormJPT />
    },
    {
        to:'/master/jpt/edit',
        level:0x1f00,
        component: <FormJPT />
    },

    {
        to:'/master/users',
        level:0x1f00,
        component: <MasterUsers />
    },
    {
        to:'/master/users/create',
        level:0x1f00,
        component: <FormUsers />
    },
    {
        to:'/master/users/edit',
        level:0x1f00,
        component: <FormUsers />
    }
]