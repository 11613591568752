import React, { useState } from "react";
import TrendChart from "./TrendChart";
import { getDataTrendByView } from "@/helper/getDataTrendByView";

export default function TrendProduction({ dataSource, isMobile, loading }) {
  const [viewProd, setViewProd] = useState("daily");
  const handleChange = (value) => {
    setViewProd(value);
  };

  const { currentYear, lastYear } = dataSource || {
    currentYear: {currentYear:[]}
  };
  const { trendKgs } = getDataTrendByView(currentYear?.currentYear||[], lastYear, viewProd) || {
    trendKgs: [],
  };

  return (
    <TrendChart
      isMobile={isMobile}
      dataSource={trendKgs}
      onChangeView={handleChange}
      view={viewProd}
      type="production"
      title="Production Trend Analysis (Kg)"
      loading={loading}
    />
  );
}
