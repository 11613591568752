import numeral from "numeral";
import { markerTooltip } from "./markerTooltip";

const tooltipFormatter = (name, value1, value2, value3, gtInfo) => {
  const gtText = gtInfo
    ? `
    <div class="item-tooltip">
        <div>
            ${markerTooltip("production")} %GT Kg
        </div>
        <div class="text-right text-bold">
        ${value3 || 0}%
        </div>
    </div>
    `
    : "";
  return `
    <div class="main-tooltip">
        <div style="margin-bottom:4px;">
            <strong>${name || ""}</strong>
        </div>
        <div class="container-tooltip">
            <div class="item-tooltip">
                <div>
                ${markerTooltip("production")} Production Kg
                </div>
                <div class="text-right text-bold">
                ${numeral(value1||0).format("0,000")}
                </div>
            </div>
            <div class="item-tooltip">
                <div>
                    ${markerTooltip()} Revenue IDR
                </div>
                <div class="text-right text-bold">
                    ${numeral(value2||0).format("0,000")}
                </div>
            </div>
            ${gtText}
        </div>
    </div>
  `;
};

export default tooltipFormatter;
