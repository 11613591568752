import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { Routing } from "../routes";
import { SiderLayout } from "@/components/Layout/sider";
import { useLocHref } from "@/hooks/useLocHref";
import { useMenu } from "@/hooks/useMenu";
import { useNavs } from "@/hooks/useNavs";
import menu from "../menu";


const TheRouter = ()=>{
    const {userdata:{level}} = useSelector(state=>state.auth);
    const route=Routing.filter(r => (r.level & level)>0);
    return (
        <Routes>
            {
                route.map((route, idx)=>(
                    <Route key={idx} path={route.to} element={!!route.component ? route.component : <div>NotFound</div>} />
                ))
            }
            {/* <Route path={'/*'} element={<div>Page Not Found</div>} /> */}

        </Routes>
    )
}

export default ()=> {
    const {userdata:{level}} =useSelector(state=>state.auth);
    const key=useLocHref('head-office');
    const {keys, menus}=useMenu(menu, level);
    useNavs(keys, key);
    return (
        <SiderLayout 
            menus={menus} 
            headerBgColor={"green"}
            headerColor={"white"}
            // title={`${Title}`}
        >
            <TheRouter />
        </SiderLayout>
    )
}
