import React, { useState } from "react";
import { Image, Typography, Col, Row } from "antd";
import { createUseStyles } from "react-jss";
import BumnLogo from "@/assets/logos/bumn-logo-white.png";
import ApLogo from "@/assets/logos/ap-logo-white.png";
import DataVizual from "@/assets/images/data-vizualitation.png";
import { Link } from "react-router-dom";
import { HiOutlinePresentationChartBar } from "react-icons/hi";
import {
  GiCommercialAirplane,
  GiBasket,
  GiPlanetConquest,
} from "react-icons/gi";
import { BsSpeedometer } from "react-icons/bs";
import { useFontSize } from "@/hooks/useDimension";

const useStyles = createUseStyles({
  container: {
    background: "#203864",
    // width: "90%",
    height: "100vh",
    // border: "1px solid red",
  },
  pageWrapper: {
    padding: "40px 68px",
  },
  logoWrapper: {
    justifyContent: "space-between",
    // border: "1px solid red",
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  imageWrapper: {
    marginTop: 20,
  },
  landingDesc: {
    marginTop: 10,
    textAlign: "center",
    // border: "1px solid red",
  },
  insightText: {
    color: "white",
    fontSize: "24px",
    // letterSpacing: '3px',
    fontWeight: "bold",
    marginTop: 50,
    // border: "1px solid red",
    textAlign: "center",
  },
  insightIcons: {
    marginTop: "20px",
    gap: 25,
    justifyContent: "center",
  },
  iconMenu: {
    "&:hover": {
      transform: " scale(1.15)",
    },
  },
});

function MobileLanding() {
  const classes = useStyles();
  const fontSize = useFontSize();
  const [icon, setIcon] = useState(fontSize.sm * 3);
  return (
    <div className={classes.container}>
      <div className={classes.pageWrapper}>
        <div className={classes.logoWrapper}>
          <Image src={BumnLogo} width={200} />
          <Image src={ApLogo} width={200} />
        </div>
        <div className={classes.imageWrapper}>
          <Image src={DataVizual} />
        </div>
        <div className={classes.landingDesc}>
          <Typography style={{ fontSize: 15, color: "white", fontWeight: 600 }}>
            Cargo Analytics Dashboard
          </Typography>
          <Typography style={{ marginTop: 10, color: "white", fontSize: 16 }}>
            Make better decision by getting a 360° view of your shipment
            performance. This dashboard can help you montor & analyze the
            shipment perfomance across regions and understand customers better.
          </Typography>
        </div>
        <div className={classes.insigthWrapper}>
          <Typography className={classes.insightText}>Go to insight</Typography>
          <Row className={classes.insightIcons}>
            <Col>
              <Link to="/dashboard-v2/overview-new">
                <HiOutlinePresentationChartBar
                  color="white"
                  size={icon}
                  className={classes.iconMenu}
                />
              </Link>
            </Col>
            <Col>
              <Link to="/dashboard-v2/stakeholders">
                <GiCommercialAirplane
                  color="white"
                  size={icon}
                  className={classes.iconMenu}
                />
              </Link>
            </Col>
            <Col>
              <Link to="/dashboard-v2/commodity">
                <GiBasket
                  color="white"
                  size={icon}
                  className={classes.iconMenu}
                />
              </Link>
            </Col>
            <Col>
              <Link to="/dashboard-v2/airport">
                <GiPlanetConquest
                  color="white"
                  size={icon}
                  className={classes.iconMenu}
                />
              </Link>
            </Col>
            <Col>
              <Link to="/dashboard-v2/perform">
                <BsSpeedometer
                  color="white"
                  size={icon}
                  className={classes.iconMenu}
                />
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default MobileLanding;
