import { Col, Divider, Row, Select, Spin, Tag, Typography } from "antd";
import React, { Fragment } from "react";
import { createUseStyles } from "react-jss";
import { useMemo } from "react";
import { parseFormatedNumber } from "@/helper/parseFormatedNumber";
import numberSparator from "@/helper/numberSparator";
import Config from "@/config/env";
import MainChart from "@/components/Chart";
import { useFontSize } from "@/hooks/useDimension";
import { useState } from "react";
import MultiSelectComponent from "@/components/CustomSelect/MultiSelect";
import { getTrendComparative } from "@/helper/airport/getTrendComparative";
import { resetStyleTooltips } from "@/components/Chart/resetStyleTooltip";
import { markerTooltipComp } from "@/components/Chart/markerTooltip";

const { colorPrimary, colorSecondary } = Config;


const comparativeOpt = [
  {
    label: "Target",
    value: "target",
  },
  {
    label: "Previous Year",
    value: "prev-year",
  },
];

const busineslineOpt = [
  {
    label: "Jasa Terkait",
    value: "jaster",
  },
  {
    label: "Konsesi",
    value: "konsesi",
  },
  {
    label: "PJKP2U",
    value: "pjkp2u",
  },
];

function RevenueCard({ data, loading }) {
  const fontSize = useFontSize();
  const [comparativeVal, setComparativeValue] = useState("prev-year");
  const [busineslineVal, setBusineslineValue] = useState("pjkp2u");

  const getColorTrend = (view) => {
    switch (view) {
      case "konsesi":
        return "#0079FF";
      case "jaster":
        return "#FF7000";
      case "pjkp2u":
        return "#10A19D";
      default:
        return;
    }
  };

  const trendPjkp2u = useMemo(() => {
    return (
      getTrendComparative("revenue", data, comparativeVal, busineslineVal) || []
    );
  }, [comparativeVal, busineslineVal, data]);

  const comparatorTrend = trendPjkp2u.map((item) =>
    comparativeVal === "target"
      ? item[`${busineslineVal}_${comparativeVal}`]
      : item[busineslineVal]
  );

  const sumComparative = trendPjkp2u.reduce((acc,prev)=>{return acc+prev.curr},0)
  const sumBusinessLine  = trendPjkp2u.reduce((acc,prev)=>{
    const prevObj = comparativeVal==="target" ? prev[`${busineslineVal}_${comparativeVal}`] : prev[`${busineslineVal}`]
    return acc+prevObj
  },0)

  const optionMonthly = {
    grid: {
      x: fontSize.md,
      x2: fontSize.md,
      y: fontSize.xl * 2,
      y2: fontSize.xl,
    },
    xAxis: {
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      type: "category",
      data: trendPjkp2u.map((item) => item.name),
    },
    yAxis: {
      type: "value",
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    tooltip: {
      ...resetStyleTooltips,
      trigger: "axis",
      formatter: (data) => {
        return `
        <div class="main-tooltip">
            <div style="margin-bottom:4px;">
            <strong>${data[0].name}</strong>
            </div>
          <div class="container-tooltip">
            <div class="item-tooltip">
              <div>
              ${data[0].marker} ${data[0].seriesName} IDR
              </div>
              <div class="text-right text-bold">
              ${numberSparator(data[0].value)}
              </div>
            </div>
            <div class="item-tooltip">
              <div class="first-Uppercase">
              ${data[1].marker} ${data[1].seriesName} IDR
              </div>
              <div class="text-right text-bold">
              ${numberSparator(comparatorTrend[data[1].dataIndex])}
              </div>
            </div>
          </div>
        </div>
        `;
      },
    },
    series: [
      {
        data: trendPjkp2u.map((item) => item.curr),
        type: "line",
        name: "Grand Total",
        color: comparativeVal === "target" ? colorSecondary : colorPrimary,
        stack: "total",
        symbol: "circle",
        symbolSize: 6,
        emphasis: {
          focus: "series",
        },
        label: {
          fontSize: 10,
          fontWeight: "bold",
          show: true, // Show labels on the spot
          position: "top", // Set label position as 'top'
          offset: [0, -16],
          color: comparativeVal === "target" ? colorSecondary : colorPrimary,
          formatter: (data) => {
            return `${
              parseFormatedNumber(data.value) !== 0
                ? parseFormatedNumber(data.value)
                : ""
            }`;
          },
        },
      },
      {
        z: -1,
        data: trendPjkp2u.map((item) => item.diff),
        emphasis: {
          focus: "series",
        },
        type: "line",
        name: `${busineslineVal} ${comparativeVal}`.replace("-", " "),
        stack: "total",
        color: getColorTrend(busineslineVal),
        lineStyle: {
          type: "dashed",
          opacity: 0.4,
        },
        stackStrategy: "all",
        areaStyle: {
          opacity: 0.1,
        },
        label: {
          fontSize: 10,
          show: true, // Show labels on the spot
          position: "bottom", // Set label position as 'top'
          offset: [0, 24],
          color: getColorTrend(busineslineVal),
          formatter: (data) => {
            return `${
              parseFormatedNumber(comparatorTrend[data.dataIndex]) !== 0
                ? parseFormatedNumber(comparatorTrend[data.dataIndex])
                : ""
            }`;
          },
        },
      },
    ],
  };

  return (
    <MainChart
      title={<Fragment>Revenue {!!loading && <Spin />}</Fragment>}
      option={optionMonthly}
      height={fontSize.lg * 11}
      fullscreen
      otherComponent={() => (
        <Row
          gutter={[16, 16]}
          style={{
            padding: "8px 16px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Col style={{ flex: 1 }}>
            <label style={{ fontWeight: "bold" }}>Comparative Value</label>
            <div style={{ width: 160, height: "100%", marginTop: 8 }}>
              <MultiSelectComponent
                hasSelectAll={false}
                options={comparativeOpt}
                selected={comparativeOpt.filter(
                  (item) => item.value == comparativeVal
                )}
                onChange={(val) => {
                  if (val.length === 0) {
                    setComparativeValue(comparativeVal);
                  } else {
                    const selected = val[val?.length - 1];
                    setComparativeValue(selected.value || comparativeVal);
                  }
                }}
              />
            </div>
          </Col>
          <Col style={{ flex: 1 }}>
            <label style={{ fontWeight: "bold" }}>Business Line</label>
            <div style={{ width: 160, height: "100%", marginTop: 8 }}>
              <MultiSelectComponent
                hasSelectAll={false}
                options={busineslineOpt}
                selected={busineslineOpt.filter(
                  (item) => item.value == busineslineVal
                )}
                onChange={(val) => {
                  if (val?.length === 0) {
                    setBusineslineValue(busineslineVal);
                  } else {
                    const selected = val[val?.length - 1];
                    setBusineslineValue(selected.value || busineslineVal);
                  }
                }}
              />
            </div>
          </Col>
          <Divider
            type="vertical"
            style={{
              margin: "0px 8px",
              height: "3rem",
              border: "1px solid #dadada",
              background: "red",
            }}
          />
          <Col style={{ flex: 1 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {/* <Tag
                bordered={false}
                icon={<MdOutlineTrendingUp size={fontSize.sm} />}
                color={"red"}
                style={{
                  fontWeight: "bold",
                  fontSize: fontSize.xs,
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                  width: "max-content",
                }}
              >
                100% | 100
              </Tag> */}
              <Typography style={{ fontSize: fontSize.md, fontWeight: "bold" }}>
              {markerTooltipComp(false,comparativeVal==="target"?colorSecondary:colorPrimary)} {parseFormatedNumber(sumComparative)}
              </Typography>
              <Typography style={{ fontSize: fontSize.sm, color: "gray" }}>
                Total{" "}
                {
                  comparativeOpt.find((item) => item.value === comparativeVal)
                    .label
                }
              </Typography>
            </div>
          </Col>
          <Divider
            type="vertical"
            style={{
              margin: "0px 8px",
              height: "3rem",
              border: "1px solid #dadada",
              background: "red",
            }}
          />
          <Col style={{ flex: 1 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {/* <Tag
                bordered={false}
                icon={<MdOutlineTrendingUp size={fontSize.sm} />}
                color={"red"}
                style={{
                  fontWeight: "bold",
                  fontSize: fontSize.xs,
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                  width: "max-content",
                }}
              >
                100% | 100
              </Tag> */}
              <Typography style={{ fontSize: fontSize.md, fontWeight: "bold" }}>
                {markerTooltipComp(false,getColorTrend(busineslineVal))} {parseFormatedNumber(sumBusinessLine)}
              </Typography>
              <Typography style={{ fontSize: fontSize.sm, color: "gray" }}>
                Total{" "}
                {
                  busineslineOpt.find((item) => item.value === busineslineVal)
                    .label
                }
              </Typography>
            </div>
          </Col>
        </Row>
      )}
    />
  );
}

export default RevenueCard;
