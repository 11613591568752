import React from 'react'
import TablePage from '@/components/pages/TablePage'
import moment from 'moment'
export default () => {
    const columns=[
        {
            selector:(row)=>row?.code,
            name:"Code",
            id:'code'
        },
        {
            selector:(row)=>row?.name,
            name:"Name",
            id:'name'
        },
        {
            selector:(row)=>!!row?.is_dangerous ? "YES" : "NO",
            name:"Is Dangerous ",
            id:'is_dangerous'
        },
        {
            selector:(row)=>(row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name:"CreatedAt",
            id:'createdAt'
        },
    ]
    return (
        <TablePage
            title={"Master SHC"}
            url="api/v1/commodity_handling"
            actionPosition={'first'}
            createPath="create"
            editPath={"edit"}
            columns={columns}            
        />
    )
}