import {createSlice} from '@reduxjs/toolkit'
const Navigations = createSlice({
    name:'nav',
    initialState:{selectedMenu:'', selectedMenuKeys:'', openKeys:[''], keys:[]},
    reducers:{
        setSelectedMenu(state, action){
            state.selectedMenu=action.payload;
        },
        setSelectedMenuKeys(state, action){
            state.selectedMenuKeys=action.payload;
        },
        setOpenKeys(state, action){
            state.openKeys=action.payload;
        },
        setNavKeys(state, action){
            state.keys=action.payload
        }
    }
})

export const {setSelectedMenu, setSelectedMenuKeys, setOpenKeys, setNavKeys} = Navigations.actions

export default Navigations.reducer