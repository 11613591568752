import { blue as red } from '@ant-design/colors';

const BaseUrl='/service/';
const ImagesUrl='/service/';
const Title = 'Local DCS System';
const Phone = '(021) 9999999';
const Email = 'ldcs@example.com';
const Theme = {
    token: {
        colorPrimary: '#014F97',
    }
}
export default {
    colorPrimary: '#014F97',
    colorPrimaryTransparat: "rgba(1, 79, 151, 0.2)",
    colorSecondary: "#4A9B00",
    colorWhite:"rgba(246, 246, 246, 1)",
    colorGray:"rgba(132, 132, 132, 1)",
    BaseUrl,
    Title,
    bgColor: red,
    headerBgColor: 'rgba(0, 102, 153, 1)',
    ImagesUrl,
    Phone,
    Email
}
