import { Col, Form, Input, InputNumber, Row, Select } from "antd";
import React, { useMemo, useState } from "react";
import FormPage from "@/components/pages/FormPage";
import AutoComplete from "@/components/autocomplete/Offline";
import AutoCompleteOnline from "@/components/autocomplete/Online";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { formatedOptions } from "@/helper/formatedOptions";
const DefModel = {
  airport_id:null,
  unix_offset: 0,
  dom_capacity: 0,
  inter_capacity: 0,
  dom_luas: 0,
  inter_luas: 0,
  dom_luas_60: 0,
  inter_luas_60: 0,
};

export default () => {
  const [model, setModel] = useState(DefModel);
  const { airportAp1Cache } = useSelector((state) => state.apps);
  const convertOpt = formatedOptions(airportAp1Cache, ["code", "name"], "_id");


  return (
    <FormPage
      url={"api/v1/operated_airport"}
      callbackPath={"master/operated_airport"}
      formValid={false}
      model={model}
      setModel={setModel}
      wrapperCol={{ span: 24 }}
      labelCol={{ span: 12 }}
      title={"Operated Airport"}
      emptyModel={DefModel}
    >
      <Row gutter={32}>
      <Col lg={24}>
          <Form.Item
            style={{ width: "100%" }}
            // name={"airport_id"}
            label="Airport"
            rules={[{ required: true }]}
          >
            <Select
              autoFocus
              showSearch
              style={{ width: "100%" }}
              value={model?.airport_id?._id}
              placeholder="Search to Select"
              optionFilterProp="children"
              onChange={(val) => {
                const obj = airportAp1Cache.find((item) => item._id === val);
                setModel({ ...model, airport_id: obj });
              }}
              filterOption={(input, option) => {
                return input.length > 2
                  ? (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  : false;
              }}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={convertOpt}
            />
          </Form.Item>
        </Col>
       
        <Col lg={4}>
          <Form.Item
            name={"dom_capacity"}
            label="Dom Capacity"
            style={{ width: "100%" }}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col lg={4}>
          <Form.Item
            name={"inter_capacity"}
            label="Inter Capacity"
            style={{ width: "100%" }}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col lg={4}>
          <Form.Item
            name={"dom_luas"}
            label="Dom Luas"
            style={{ width: "100%" }}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col lg={4}>
          <Form.Item
            name={"inter_luas"}
            label="Inter Luas"
            style={{ width: "100%" }}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col lg={4}>
          <Form.Item
            name={"dom_luas_60"}
            label="Dom Luas 60%"
            style={{ width: "100%" }}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col lg={4}>
          <Form.Item
            name={"inter_luas_60"}
            label="Inter Luas 60%"
            style={{ width: "100%" }}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
    </FormPage>
  );
};
