import React, { Fragment, useMemo, useState } from "react";
import { Button, Spin, Typography } from "antd";
import MainChart from "@/components/Chart";
import { useFontSize } from "@/hooks/useDimension";
import { parseFormatedNumber } from "@/helper/parseFormatedNumber";
import Config from "@/config/env";
const { colorPrimary } = Config;

function ProductionTarget({ data, loading }) {
  const fontSize = useFontSize();
  const optionProduction = {
    series: [
      {
        type: "gauge",
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: data?.rka,
        center: ["50%", "70%"],
        splitNumber: 1,
        itemStyle: {
          color: colorPrimary,
          shadowColor: "rgba(0,138,255,0.45)",
          shadowBlur: 10,
          shadowOffsetX: 2,
          shadowOffsetY: 2,
        },
        progress: {
          show: true,
          roundCap: true,
          width: fontSize.xl,
        },
        pointer: {
          show: false,
        },
        axisLine: {
          roundCap: true,
          lineStyle: {
            width: fontSize.xl,
          },
        },
        axisTick: {
          splitNumber: 1,
          lineStyle: {
            width: 2,
            color: "black",
          },
        },
        splitLine: {
          length: 0,
          lineStyle: {
            width: 4,
            color: "black",
          },
        },
        axisLabel: {
          //   marginTop: 10,
          distance: 40,
          color: colorPrimary,
          fontSize: fontSize.xs,
          formatter: function (value) {
            return `${parseFormatedNumber(value)}`;
          },
        },
        title: {
          show: false,
        },
        detail: {
          backgroundColor: "transparent",
          fontSize: fontSize.xl,
          offsetCenter: [0, "-25%"],
          valueAnimation: true,
          formatter: function (value) {
            return `${parseFormatedNumber(value)}`;
          },
          rich: {
            value: {
              fontSize: 50,
              fontWeight: "bolder",
              color: "#777",
            },
            unit: {
              fontSize: 20,
              color: "#999",
              padding: [0, 0, -20, 10],
            },
          },
        },
        data: [
          {
            value: data?.total,
            detail: {
              width: "100%",
              // color: "red",
            },
          },
        ],
      },
    ],
  };

  return (
    <Fragment>
      <MainChart
        height={fontSize.md * 18}
        title={<Fragment>Production Target</Fragment>}
        fullscreen
        option={optionProduction}
      />
    </Fragment>
  );
}

export default ProductionTarget;
