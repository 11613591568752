import React, { Fragment } from "react";
import TablePage from "@/components/pages/TablePage";
import moment from "moment";
import numeral from "numeral";
import { DatePicker, InputNumber, Select } from "antd";
import { useSelector } from "react-redux";
import { formatedOptions } from "@/helper/formatedOptions";
export default () => {
  const columns = [
    {
      selector: (row) => `${row?.airport_id?.code}/${row?.airport_id?.name}`,
      name: "Airport",
      id: "airport_id",
      compact: true,
      
    },
    {
      selector: (row) => row?.tahun,
      name: "Year",
      id: "tahun",
      compact: true,
      center:true
    },
    {
      selector: (row) => moment().month(row?.month).format("MMMM"),
      name: "Month",
      id: "month",
      compact: true,
      center:true
    },
    {
      selector: (row) => numeral(row?.konsesi).format("0,000"),
      name: "Konsesi",
      right: true,
      id: "konsesi",
      compact: true,
    },
    {
      selector: (row) => numeral(row?.konsesi_rka).format("0,000"),
      name: "Konsesi RKA",
      right: true,
      id: "konsesi_rka",
      compact: true,
    },

    {
      selector: (row) => numeral(row?.dom_out_rka).format("0,000"),
      name: "Dom. Out. RKA",
      id: "dom_out_rka",
      right: true,
      compact: true,
    },
    {
      selector: (row) => numeral(row?.dom_inc_rka).format("0,000"),
      name: "Dom. Inc. RKA",
      id: "dom_inc_rka",
      right: true,
      compact: true,
    },
    {
      selector: (row) => numeral(row?.inter_out_rka).format("0,000"),
      name: "Inter. Out. RKA",
      id: "inter_out_rka",
      right: true,
      compact: true,
    },
    {
      selector: (row) => numeral(row?.inter_inc_rka).format("0,000"),
      name: "Inter. Inc. RKA",
      id: "inter_inc_rka",
      right: true,
      compact: true,
    },

    {
      selector: (row) => numeral(row?.dom_out_jaster).format("0,000"),
      name: "Jaster Dom. Out.",
      id: "dom_out_jaster",
      right: true,
      compact: true,
    },
    {
      selector: (row) => numeral(row?.dom_inc_jaster).format("0,000"),
      name: "Jaster Dom. Inc.",
      id: "dom_inc_jaster",
      right: true,
      compact: true,
    },
    {
      selector: (row) => numeral(row?.inter_out_jaster).format("0,000"),
      name: "Jaster Inter. Out.",
      id: "inter_out_jaster",
      right: true,
      compact: true,
    },
    {
      selector: (row) => numeral(row?.inter_inc_jaster).format("0,000"),
      name: "Jaster Inter. Inc.",
      id: "inter_inc_jaster",
      right: true,
      compact: true,
    },

    {
      selector: (row) => numeral(row?.dom_out_jaster_rka).format("0,000"),
      name: "Jaster Dom. Out. RKA",
      id: "dom_out_jaster_rka",
      right: true,
      compact: true,
    },
    {
      selector: (row) => numeral(row?.dom_inc_jaster_rka).format("0,000"),
      name: "Jaster Dom. Inc. RKA",
      id: "dom_inc_jaster_rka",
      right: true,
      compact: true,
    },
    {
      selector: (row) => numeral(row?.inter_out_jaster_rka).format("0,000"),
      name: "Jaster Inter. Out. RKA",
      id: "inter_out_jaster_rka",
      right: true,
      compact: true,
    },
    {
      selector: (row) => numeral(row?.inter_inc_jaster_rka).format("0,000"),
      name: "Jaster Inter. Inc. RKA",
      id: "inter_inc_jaster_rka",
      right: true,
      compact: true,
    },

    {
      selector: (row) => numeral(row?.pjkp2u_out_dom_rka).format("0,000"),
      name: "PJKP2U Dom. Out. RKA",
      id: "pjkp2u_out_dom_rka",
      right: true,
      compact: true,
    },
    {
      selector: (row) => numeral(row?.pjkp2u_inc_dom_rka).format("0,000"),
      name: "PJKP2U Dom. Inc. RKA",
      id: "pjkp2u_inc_dom_rka",
      right: true,
      compact: true,
    },
    {
      selector: (row) => numeral(row?.pjkp2u_out_int_rka).format("0,000"),
      name: "PJKP2U Inter. Out. RKA",
      id: "pjkp2u_out_int_rka",
      right: true,
      compact: true,
    },
    {
      selector: (row) => numeral(row?.pjkp2u_inc_int_rka).format("0,000"),
      name: "PJKP2U Inter. Inc. RKA",
      id: "pjkp2u_inc_int_rka",
      right: true,
      compact: true,
    },

    {
      selector: (row) =>
        (row?.created_at &&
          moment(row.created_at).format("DD-MMM-YYYY HH:mm")) ||
        "-",
      name: "CreatedAt",
      id: "created_at",
    },
  ];

  const { airportAp1Cache } = useSelector((state) => state.apps);
  const convertOpt = formatedOptions(airportAp1Cache, ["code", "name"], "_id");
  const sizeMonth = Array.from(Array(12).keys());
  const convertOptMonth = sizeMonth.map((item) => ({
    label: moment().month(item).format("MMMM"),
    value: moment().month(item).format("MMMM"),
  }));
  return (
    <TablePage
      title={"Cabang"}
      url="api/v1/cabang_param"
      actionPosition={"first"}
      createPath="create"
      editPath={"edit"}
      columns={columns}
      noSearch
      otherFilter={({ state, onChangeFilter }) => {
        return (
          <div style={{ display: "flex", gap: 8 }}>
            <Select
              showSearch
              allowClear
              style={{ width: "200px" }}
              value={state?.airport}
              placeholder="Airport"
              optionFilterProp="children"
              onChange={(val) => {
                onChangeFilter({ ...state, airport: val });
              }}
              filterOption={(input, option) => {
                return input.length > 2
                  ? (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  : false;
              }}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={convertOpt}
            />

            <Select
              showSearch
              allowClear
              style={{ width: "160px" }}
              value={state?.month}
              placeholder="Month"
              optionFilterProp="children"
              onChange={(val) => {
                onChangeFilter({ ...state, month: val });
              }}
              filterOption={(input, option) => {
                return input.length > 2
                  ? (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  : false;
              }}
              options={convertOptMonth}
            />

            <DatePicker
              picker={"year"}
              value={state?.year && moment(state?.year)}
              onChange={(_, dateStr) =>
                onChangeFilter({ ...state, year: dateStr })
              }
            />
          </div>
        );
      }}
    />
  );
};
