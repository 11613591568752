import React, { useEffect, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Divider,
  Typography,
  Select,
  Radio,
  Button,
} from "antd";
import { useFontSize } from "../../hooks/useDimension";
import { createUseStyles } from "react-jss";
import Config from "../../config/env";
import MultiSelectComponent from "../CustomSelect/MultiSelect";
import useWindowDimensions, {
  useLayoutCustomize,
} from "../../hooks/useDimension";
import { formatedOptions } from "../../helper/formatedOptions";
import { useSelector } from "react-redux";
const { colorPrimary, colorSecondary } = Config;
const { Header } = Layout;

const useStyles = createUseStyles({
  container: {
    background: "white",
    padding: 0,
    // height: "100%",
    padding: "0px 0px 0px 10px",
  },
  leftContent: {
    // border: "1px solid red",
    // padding: "5px 10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // alignItems: "center",
  },
  rightContent: {
    padding: "8px 12px",
    background: colorPrimary,
    // height: "60px",
    borderRadius: "16px 0px 0px 0px",
    // boxShadow:
    //   "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
    // padding: '10px 25px',
    // gap: 30
    // border: "1px solid red",
  },
  selectWrapper: {
    // border: "1px solid red",
    display: "flex",
    alignItems: "center",
  },
  radioWrapper: {
    // border: "1px solid green",
  },
  multipleStyle: {
    borderRadius: 8,
  },
  radioStyles: {
    "& .ant-radio-button-wrapper": {
      borderRadius: "0px !important",
      // background: "white !important",
      width: ({ radioLayout }) => radioLayout.width,
      marginRight: 8,
      color: colorPrimary,
      fontWeight: 600,
      textAlign: "center",
      // letterSpacing: 2,
      fontSize: ({ fontSize }) => fontSize.xs,
      height: ({ radioLayout }) => radioLayout.height,
    },
    "& .ant-radio-button-checked": {
      background: `#C0C0C0 !important`,
    },
  },
});

const routeOptions = [
  { label: "Dom", value: "DOm" },
  { label: "Inter", value: "Inter" },
];
const directionOptions = [
  { label: "Inc", value: "Inc" },
  { label: "Out", value: "Out" },
];

const airportsOptions = [
  { value: "1", label: "PT. CEPET EXPRESS" },
  { value: "2", label: "PT. LAMA EXPRESS" },
  { value: "3", label: "PT. JNE" },
  { value: "4", label: "PT. JNT" },
  { value: "5", label: "PT. SHOPEE EXPRESS" },
];

const yearOptions = [
  { value: 2020, label: "2020" },
  { value: 2021, label: "2021" },
  { value: 2022, label: "2022" },
  { value: 2023, label: "2023" },
];

const monthOptions = [
  { value: 0, label: "Jan" },
  { value: 1, label: "Feb" },
  { value: 2, label: "Mar" },
  { value: 3, label: "Apr" },
  { value: 4, label: "May" },
  { value: 5, label: "Jun" },
  { value: 6, label: "Jul" },
  { value: 7, label: "Aug" },
  { value: 8, label: "Sep" },
  { value: 9, label: "Oct" },
  { value: 10, label: "Nov" },
  { value: 11, label: "Dec" },
];

function FilterV2({ state, onChange }) {
  const { airportAp1Cache } = useSelector((state) => state.apps);

  const [airports, setAirports] = useState([]);
  const [year, setYear] = useState([]);

  const [month, setMonth] = useState([]);
  const [route, setRoute] = useState("");
  const [direct, seDirect] = useState("");
  const { activePage } = useSelector((state) => state.apps);
  const radioLayout = useLayoutCustomize(
    "24px",
    "32px",
    "36px",
    "40px",
    "90px",
    "95px",
    "100px",
    "105px"
  );
  const selectLayout = useLayoutCustomize(
    "24px",
    "32px",
    "36px",
    "50px",
    "140px",
    "170px",
    "200px",
    "225px"
  );
  const { width, height } = useWindowDimensions();
  const fontSize = useFontSize();
  const classes = useStyles({ selectLayout, radioLayout, fontSize });

  const formatedOpt = formatedOptions(
    airportAp1Cache,
    ["code", "name"],
    "_id",
    "asc"
  );

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <Row
        style={{
          borderBottom: `${fontSize.xs} solid ${colorPrimary}`,
          height: "100%",
          background: "white",
        }}
      >
        <Col span={8}>
          <div
            style={{
              display: "flex",
              gap: 8,
              alignItems: "center",
              padding: fontSize.sm,
              // border: "1px solid red",
            }}
          >
            <Typography.Title
              style={{ margin: 0, fontWeight: "bold", fontSize: fontSize.lg }}
            >
              Cargo Analytics Dashboard
            </Typography.Title>
            <Divider
              type="vertical"
              style={{ borderInlineStart: "3px solid #dadada", height: "3rem" }}
            />
            <Typography.Title
              style={{ margin: 0, fontSize: fontSize.md, color: "red" }}
            >
              {activePage}
            </Typography.Title>
          </div>
        </Col>
        <Col className={classes.rightContent} span={16}>
          <Row gutter={12}>
            <Col
              span={6}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 5,
              }}
            >
              <div
                style={{
                  paddingBottom: "4px",
                }}
              >
                <Typography
                  style={{
                    lineHeight: 1,
                    color: "white",
                    fontSize: fontSize.sm,
                  }}
                >
                  Airports
                </Typography>
              </div>
              <div style={{ width: "100%", height: "100%" }}>
                <MultiSelectComponent
                  className={classes.multipleStyle}
                  options={formatedOpt}
                  selected={airports}
                  onChange={(val) => {
                    setAirports(val);
                    const selected =
                      val.length !== 0 ? val.map((item) => item.value) : [];
                    onChange("airports", selected);
                  }}
                />
              </div>
            </Col>
            <Col
              span={5}
              style={{
                // height: "50px",
                display: "flex",
                flexDirection: "column",
                gap: 5,
                // height: "100%",
                // position: "relative",
              }}
            >
              <div
                style={{
                  // borderBottom: "1px solid white",
                  paddingBottom: "4px",
                }}
              >
                <Typography
                  style={{
                    lineHeight: 1,
                    color: "white",
                    fontSize: fontSize.sm,
                  }}
                >
                  Year
                </Typography>
              </div>
              <div style={{ width: "100%", height: "100%" }}>
                <MultiSelectComponent
                  hasSelectAll={false}
                  options={yearOptions}
                  selected={yearOptions.filter(
                    (item) => item.value == state.year
                  )}
                  onChange={(val) => {
                    setYear([...year, val]);
                    const selected = val[val?.length - 1];
                    onChange("year", selected?.value || state.year);
                  }}
                />
              </div>
            </Col>
            <Col
              span={5}
              style={{
                // height: "50px",
                display: "flex",
                flexDirection: "column",
                gap: 5,
                // height: "100%",
                // position: "relative",
              }}
            >
              <div
                style={{
                  // borderBottom: "1px solid white",
                  paddingBottom: "4px",
                }}
              >
                <Typography
                  style={{
                    lineHeight: 1,
                    color: "white",
                    fontSize: fontSize.sm,
                  }}
                >
                  Month
                </Typography>
              </div>
              <div style={{ width: "100%", height: "100%" }}>
                <MultiSelectComponent
                  options={monthOptions}
                  selected={month}
                  onChange={(val) => {
                    setMonth(val);
                    const selected = val.map((item) => item.value);
                    onChange("months", selected);
                  }}
                />
              </div>
            </Col>
            <Col
              span={4}
              style={{
                // height: "50px",
                display: "flex",
                flexDirection: "column",
                gap: 5,

                // position: "relative",
                // height: "100%"
              }}
            >
              <div
                style={{
                  // borderBottom: "1px solid white",
                  paddingBottom: "4px",
                  // height: "100%"
                }}
              >
                <Typography
                  style={{
                    lineHeight: 1,
                    color: "white",
                    fontSize: fontSize.sm,
                  }}
                >
                  Direct
                </Typography>
              </div>
              <div style={{ display: "flex", gap: 8, height: "100%" }}>
                <Button
                  onClick={() => onChange("direction", "Inc")}
                  size="small"
                  style={{
                    flex: 1,
                    flexGrow: 1,
                    borderRadius: 8,
                    background:
                      state?.direction === "Inc" ? colorSecondary : "white",
                    color: state?.direction === "Inc" ? "white" : "black",
                    border: "none",
                    fontSize: fontSize.sm,
                    height: "100%",
                  }}
                >
                  Inc
                </Button>
                <Button
                  onClick={() => onChange("direction", "Out")}
                  size="small"
                  style={{
                    flex: 1,
                    flexGrow: 1,
                    borderRadius: 8,
                    background:
                      state?.direction === "Out" ? colorSecondary : "white",
                    color: state?.direction === "Out" ? "white" : "black",
                    border: "none",
                    fontSize: fontSize.sm,
                    height: "100%",
                  }}
                >
                  Out
                </Button>
              </div>
            </Col>
            <Col
              span={4}
              style={{
                // height: "50px",
                display: "flex",
                flexDirection: "column",
                gap: 5,
                position: "relative",
              }}
            >
              <div
                style={{
                  // borderBottom: "1px solid white",
                  paddingBottom: "4px",
                }}
              >
                <Typography
                  style={{
                    lineHeight: 1,
                    color: "white",
                    fontSize: fontSize.sm,
                  }}
                >
                  Route
                </Typography>
              </div>
              <div style={{ display: "flex", gap: 8, height: "100%" }}>
                <Button
                  size="small"
                  onClick={() => onChange("route", "Dom")}
                  style={{
                    background:
                      state?.route === "Dom" ? colorSecondary : "white",
                    color: state?.route === "Dom" ? "white" : "black",
                    flex: 1,
                    flexGrow: 1,
                    borderRadius: 8,
                    border: "none",
                    fontSize: fontSize.sm,
                    height: "100%",
                  }}
                >
                  Dom
                </Button>
                <Button
                  size="small"
                  onClick={() => onChange("route", "Inter")}
                  style={{
                    flex: 1,
                    flexGrow: 1,
                    background: "white",
                    borderRadius: 8,
                    background:
                      state?.route === "Inter" ? colorSecondary : "white",
                    color: state?.route === "Inter" ? "white" : "black",
                    border: "none",
                    fontSize: fontSize.sm,
                    height: "100%",
                  }}
                >
                  Int
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default FilterV2;
