import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {GetAPI, PostAPI} from './apiHandling'
import jwtDecode from 'jwt-decode'
import {toast} from 'react-toastify'
import {levelToRoles,  levels} from './levelConvert'
const authSlice = createSlice({
    name:'auth',
    initialState:{userdata:false, token:'', apps:'', initComplete:false, url:''},
    reducers:{
        successLogin(state, action) {
            const {userdata} = action.payload;            
            // const level=(state.apps==='head-office')&&levels;
            const levelStr = levelToRoles(userdata.level || 0);            
            state.userdata = {...userdata, levelStr};
            state.token = action.payload.token
        },
        setApps(state, action) {
            state.apps = action.payload
            switch (action.payload) {
                case 'airline':
                    state.url='airline/';
                    break;
            
                default:
                    state.url='';
                    break;
            }
        },
        doLogout(state) {
            state.userdata = false
            state.token = ''
        },
        initComplete(state) {
            state.initComplete = true
        }
    }
})

export const {successLogin, setApps, doLogout, initComplete} = authSlice.actions

export const login = createAsyncThunk('auth/login', async({user, password, app, ...others}, thunkAPI) => {
    const {dispatch, getState} = thunkAPI
    const {auth:{apps, url:uri}} = getState()
    const tokenName = `${apps}_token`
    const url=uri+'auth/login';
    const token = await dispatch(PostAPI({url, data:{username:user, password, app, ...others}}))
    if (!!token.payload) {
        const userdata = jwtDecode(token.payload)
        window.localStorage.setItem(tokenName, token.payload)
        dispatch(successLogin({token:token.payload, userdata}))
        toast.success(`Welcome to Apps ${userdata?.name}`)
    }
})
export const logout = createAsyncThunk('auth/logout', async (body, thunkAPI) => {
    const {dispatch, getState} = thunkAPI
    const {auth:{apps, url:uri}} = getState()
    const url=uri+'auth/logout';
    await dispatch(GetAPI({url}));
    const tokenName = `${apps}_token`
    window.localStorage.removeItem(tokenName)
    dispatch(doLogout())
})
export const initMe = createAsyncThunk('auth/me', async (body, thunkAPI) => {
   //dipslay masih splash
    const {dispatch, getState} = thunkAPI
    const {auth:{apps, url:uri}} = getState()
    const tokenName = `${apps}_token`
    const token = window.localStorage.getItem(tokenName)
    if (!!token) {
        const userdata = jwtDecode(token)
        dispatch(successLogin({userdata, token}))
        const url=uri+'auth/me';
        dispatch(GetAPI({url}))
        if (userdata) dispatch(successLogin({userdata, token}))
    }
    dispatch(initComplete())
})
// export const login
export default authSlice.reducer