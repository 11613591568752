import React from "react";
import CardMaps from "@/components/Maps/CardMaps";

function ShippingRoute({
  data,
  tabOptions,
  loading,
  isMobile,
  height,
  options,
  selected,
  setSelected,
  origin,
}) {
  return (
    <CardMaps
      loading={loading}
      fullscreen
      isRoute
      origin={origin}
      selected={selected}
      setSelected={setSelected}
      options={options}
      mulitple
      data={data}
      isMobile={isMobile}
      title="Shipping Route"
      subTitle="by Airports"
      height={height}
    />
  );
}

export default ShippingRoute;
