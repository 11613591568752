import { useDashboardOverview } from "@/hooks/useDashboard";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createUseStyles } from "react-jss";
import Config from "../../../../config/env";
import CTUltilizationRate from "./components/CTUltilizationRate";
import LaborProductivity from "./components/LaborProductivity";
import ProductionTarget from "./components/ProductionTarget";
import RevenueTarget from "./components/RevenueTarget";
import { setActivePage } from "@/redux";
const { colorPrimary } = Config;

const useStyles = createUseStyles({
  container: {
    padding: "20px 12px",
    height: "90vh",
  },
  cardContainer: {
    padding: "16px 20px !important",
    // border: '1px solid',
    borderRadius: 8,
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
  },
});

function Perform({ filterValue }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [values, setValues] = useState([]);
  const loading = false;
  const data = [];

  const [target, tLoading] = useDashboardOverview({
    ...filterValue,
    type: "target",
    view: "performance",
  });

  const [utility, uLoading] = useDashboardOverview({
    ...filterValue,
    type: "utility",
    view: "performance",
  });

  useEffect(() => {
    dispatch(setActivePage("Performance"));
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 10,
      }}
    >
      <div id="top" style={{ flex: 1.1 }}>
        <div style={{ height: "100%", display: "flex", gap: 8 }}>
          <div style={{ height: "100%", width: "50%" }}>
            <ProductionTarget loading={tLoading} data={target?.production} />
          </div>
          <div style={{ height: "100%", width: "50%" }}>
            <RevenueTarget loading={tLoading} data={target?.revenue} />
          </div>
        </div>
      </div>
      <div id="bottom" style={{ flex: 1 }}>
        <div style={{ height: "90%", display: "flex", gap: 8 }}>
          <div style={{ height: "100%", width: "50%" }}>
            <LaborProductivity />
          </div>
          <div style={{ height: "100%", width: "50%" }}>
            <CTUltilizationRate data={utility} loading={uLoading} />
          </div>
        </div>
      </div>
      {/* <Row gutter={10}>
        <Col span={12}>
          <div style={{ height: "100%" }}>
            <ProductionTarget loading={tLoading} data={target?.production} />
          </div>
        </Col>
        <Col span={12}>
          <div style={{ height: "100%" }}>
            <RevenueTarget loading={tLoading} data={target?.revenue} />
          </div>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={12}>
          <div style={{ height: "100%" }}>
            <LaborProductivity />
          </div>
        </Col>
        <Col span={12}>
          <div style={{ height: "100%" }}>
            <CTUltilizationRate data={utility} loading={uLoading} />
          </div>
        </Col>
      </Row> */}
    </div>
  );
}

export default Perform;
