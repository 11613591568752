import { Form, Input } from "antd";
import React, { useMemo, useState } from "react";
import FormPage from "@/components/pages/FormPage";
import { useSelector } from "react-redux";
import AutoComplate from "@/components/autocomplete/Online";
import hscode from "../hscode";

const DefModel = {
  commodity: "",
  alternate_names: "",
  handling_id: null,
  hscode_id: null,
  category_id: null,
};

export default () => {
  const [model, setModel] = useState(DefModel);
  const { airportCache: airport_cache } = useSelector((state) => state.apps);
  console.log(model);
  const valid = useMemo(() => {
    return true;
  }, [model]);
  return (
    <FormPage
      url={"api/v1/commodity"}
      callbackPath={"master/goods"}
      formValid={false}
      model={model}
      setModel={setModel}
      wrapperCol={{ span: 24 }}
      labelCol={{ span: 24 }}
      onSaveEvent={(row)=>({...row, alternate_names: Array.isArray(row?.alternate_names) ? row?.alternate_names : row?.alternate_names?.split(",")})}
      title={"Goods"}
      emptyModel={DefModel}
    >
      <Form.Item
        name={"commodity"}
        label="Commodity Name"
        rules={[{ required: true }]}
      >
        <Input autoFocus />
      </Form.Item>

      <Form.Item
        name={"alternate_names"}
        label="Alternate Names (xxx,xxx)"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="HS Code" rules={[{ required: true }]}>
        <AutoComplate
          uri={"api/v1/hscode"}
          getKey={(opt) => opt._id}
          getSelectedOptions={(opt, val) => opt._id === val._id}
          value={model?.hscode_id}
          width={"100%"}
          getValues={(row) => `${row?.code||""}--${row?.id_name||""}`}
          onChange={(val) => setModel({ ...model, hscode_id: val })}
        />
      </Form.Item>

      <Form.Item label="SHC" rules={[{ required: true }]}>
        <AutoComplate
          uri={"api/v1/commodity_handling"}
          getKey={(opt) => opt._id}
          getSelectedOptions={(opt, val) => opt._id === val._id}
          value={model?.handling_id}
          width={"100%"}
          getValues={(row) => `${row?.code}--${row?.name}`}
          onChange={(val) => setModel({ ...model, handling_id: val })}
        />
      </Form.Item>

      <Form.Item label="Category" rules={[{ required: true }]}>
        <AutoComplate
          uri={"api/v1/commodity_category"}
          getKey={(opt) => opt._id}
          getSelectedOptions={(opt, val) => opt._id === val._id}
          value={model?.category_id}
          width={"100%"}
          getValues={(row) => `${row?.name}`}
          onChange={(val) => setModel({ ...model, category_id: val })}
        />
      </Form.Item>
    </FormPage>
  );
};
