import React, { Fragment} from "react";
import { createUseStyles } from "react-jss";
import {  Spin} from "antd";
import { useFontSize } from "@/hooks/useDimension";
import numeral from "numeral";
import Config from "@/config/env";
import { parseFormatedNumber } from "@/helper/parseFormatedNumber";
import { resetStyleTooltips } from "@/components/Chart/resetStyleTooltip";
import MainChart from "@/components/Chart";
const { colorPrimary, colorSecondary } = Config;

function TrendChart({
  dataSource,
  title,
  onChangeView,
  view,
  type,
  loading,
  isMobile = false,
}) {

  const fontSize = useFontSize();

  const formatterTitle = (params, view) => {
    switch (view) {
      case "daily":
        return `
          <div style="margin-bottom:4px;">
            <strong>${params[0].name}</strong>
          </div>
        `;
      case "weekly":
        return `
          <div style="margin-bottom:4px;">
            <strong>Week ${params[0].name}</strong>
          </div>
        `;
      case "monthly":
        return `
          <div style="margin-bottom:4px;">
            <strong>${params[0].name}</strong>
          </div>
        `;
      case "quarter":
        return `
          <div style="margin-bottom:4px;">
            <strong>Qtr ${params[0].name}</strong> 
          </div>
        `;

      default:
        return;
    }
  };

  const min = Math.min(...dataSource.map((item) => parseInt(item.curr)));
  const max = Math.max(...dataSource.map((item) => parseInt(item.curr)));

  const avg =
    dataSource?.reduce((acc, prev) => acc + prev?.curr, 0) / dataSource?.length;
  const opts = {
    tooltip: {
      show: true,
      trigger: "axis",
      ...resetStyleTooltips,
      formatter: (params) => {
        return `
        <div class="main-tooltip">
        ${formatterTitle(params, view)}
          <div class="container-tooltip">
            <div class="item-tooltip">
            <div>
            ${params[0].marker} ${
          type === "production" ? "Production" : "Revenue"
        } ${type === "production" ? "Kg" : "IDR"}
            </div>
            <div class="text-right text-bold">
            ${numeral(params[0].value).format("0,000")}
            </div>
            </div>
          </div>
        </div>
        `;
      },
    },
    grid: {
      x: isMobile ? 10 : 32,
      x2: 100,
      y:
        view !== "daily" && view !== "monthly"
          ? fontSize.xl * 3
          : isMobile
          ? fontSize.md * 4
          : fontSize.md*2,
      y2: fontSize.lg,
      // top: "10%",
    },
    xAxis: {
      type: "category",
      data: dataSource.map((item) => item.name),
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        fontSize: isMobile ? "11px" : "10px",
        axisPointer: {
          show: true,
        },
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    visualMap: [
      {
        show: false,
        type: "continuous",
        seriesIndex: 0,
        min: min,
        max: max,
        inRange: {
          color: ["red", type === "production" ? colorPrimary : colorSecondary],
        },
      },
      {
        show: false,
        type: "continuous",
        seriesIndex: 1,
        min: min,
        max: max,
        inRange: {
          color: ["red", type === "production" ? colorPrimary : colorSecondary],
        },
      },
    ],
    series: [
      {
        label: {
          show: true, //view === "quarter" ? true : false,
          position: "top",
          offset: [0, -4],
          formatter: (params) => {
            let modulus = 1;
            switch (view) {
              case "daily":
                modulus = Math.round(dataSource.length/6);
                break;
              case "weekly":
                modulus = 4;
                break;
              case "monthly":
                modulus = 2;
                break;

              default:
                break;
            }
            return (
              (params.dataIndex % modulus === 0 &&
                `${parseFormatedNumber(params.value)}`) ||
              ""
            );
          },
        },
        data: dataSource.map((item) => parseInt(item.curr)),
        type: view === "monthly" ? "bar" : "line",
        symbol: "circle",
        color: "#4d8e3a",
        symbolSize: view !== "daily" ? fontSize.xs : 0,
        itemStyle: {
          borderWidth: 10,
          color: colorSecondary,
          borderRadius: view === "monthly" && 4,
        },
        markLine: {
          data: [
            {
              type: "average",
              itemStyle: {
                color: colorPrimary,
              },
              label: {
                formatter: (params) =>
                  `Avg. ${parseFormatedNumber(params.value)}`,
              },
            },
          ],
          silent: true,
        },
      },
    ],
  };

  const options = [
    { title: "Daily", value: "daily" },
    { title: "Weekly", value: "weekly" },
    { title: "Montly", value: "monthly" },
    { title: "Quarter", value: "quarter" },
  ];

  return (
    <MainChart
      title={
        <Fragment>
          {title} {loading && <Spin />}
        </Fragment>
      }
      option={opts}
      view={view}
      height={isMobile ? fontSize.md * 16 : fontSize.md * 11}
      fullscreen
      onChange={(val) => onChangeView(val)}
      filterComp={options}
    />
  );
}

export default TrendChart;
