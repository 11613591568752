import { Col, Row, Spin, Typography } from "antd";
import React, { useMemo } from "react";
import MainCard from "@/components/MainCard";
import { useFontSize } from "@/hooks/useDimension";
import Config from "@/config/env";
import numeral from "numeral";

const { colorPrimary, colorSecondary,colorGray } = Config;

const cardContents = [
  {
    title: 0,
    subTitle: "Airline Operated",
  },
  {
    title: 0,
    subTitle: "Agent Operated",
  },
  {
    title: 0,
    subTitle: "Connected City",
  },
  {
    title: 0,
    subTitle: "Connected Continent",
  },
  {
    title: 0,
    subTitle: "Domestic Route",
  },
  {
    title: 0,
    subTitle: "International Route",
  },
];

export default function CardSummary({ data, loading, isMobile = false }) {
  const fontSize = useFontSize();
  const contents = useMemo(() => {
    const dt = cardContents;
    if (!data) return cardContents;
    if (!data.airport) return cardContents;
    const { connected_airlines, connected_agent, airport } = data;
    const {
      connected_city,
      connected_continent,
      dom_route,
      international_route,
    } = airport;
    dt[0].title = connected_airlines?.length || 0;
    dt[1].title = connected_agent?.length || 0;
    dt[2].title = connected_city || 0;
    dt[3].title = connected_continent || 0;
    dt[4].title = dom_route || 0;
    dt[5].title = international_route || 0;
    return dt;
  }, [data]);
  return (
    <Row gutter={[8, 8]} style={{ height: "100%" }}>
      {contents.map((content, idx) => (
        <Col xs={{ span: 12 }} sm={{ span: 12 }} lg={{ span: 12 }} key={idx}>
          <MainCard>
            <div
              style={{
                height: isMobile ? "60px" : "100%",
                borderLeft: `6px solid ${
                  idx % 2 === 0 ? colorSecondary : colorPrimary
                }`,
                padding: isMobile ? "0px 10px" : "8px 12px",
                borderRadius: 8,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography style={{ fontSize: fontSize.lg, fontWeight: "bold" }}>
                {numeral(content.title).format("0,000")} {loading && <Spin />}
              </Typography>
              <Typography style={{ fontSize: fontSize.sm, color:colorGray }}>
                {content.subTitle}
              </Typography>
            </div>
          </MainCard>
        </Col>
      ))}
    </Row>
  );
}
