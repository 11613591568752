import React from "react";
import { Row, Col, Typography } from "antd";
import MultiSelectComponent from "@/components/CustomSelect/MultiSelect";
import FlightMap from "@/components/FlightsMap";
import MainCard from "@/components/MainCard";
import { createUseStyles } from "react-jss";
import { useFontSize } from "@/hooks/useDimension";
import Config from "@/config/env";
const { colorPrimary } = Config;

const useStyles = createUseStyles({
  select: {
    // zIndex: 9999,
    "& .dropdown-container": {
      borderRadius: 8,
      position: "relative",
      zIndex: 999,
      width: "300px",
    },
  },
});

function CardmapsV2({ title, value, setValue, mapData, options, disabled }) {
  const classes = useStyles();
  const fontSize = useFontSize();
  return (
    <MainCard isMobile>
      <div style={{ height: "100%", padding: "8px 16px" }}>
        <Row
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 8,
          }}
        >
          <Col>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: fontSize.md,
                color: colorPrimary,
              }}
            >
              {title}
            </Typography>
          </Col>
          {!disabled && (
            <MultiSelectComponent
              disabled={disabled}
              className={classes.select}
              options={options}
              selected={value}
              onChange={(val) => {
                setValue(val);
              }}
            />
          )}
        </Row>
        <div style={{ height: "86%" }}>
          <FlightMap data={mapData} />
        </div>
      </div>
    </MainCard>
  );
}

export default CardmapsV2;
