import { Typography } from "antd";
import React, { Fragment, useEffect } from "react";
import { createUseStyles } from "react-jss";
import {
  MapContainer,
  TileLayer,
  Polyline,
  FeatureGroup,
  Circle,
  useMap,
  Popup,
} from "react-leaflet";
import ReactCountryFlag from "react-country-flag";
import Config from "../../config/env";
const { colorPrimary } = Config;

const useStyles = createUseStyles({
  circleStyle: {
    color: "red",
  },
  popupStyle: {
    "& .leaflet-popup-content-wrapper": {
      width: "400px !important",
    },
  },
});

const ComponentResize = () => {
  const map = useMap();
  useEffect(() => {
    setTimeout(() => {
      map?.invalidateSize();
      return () => map.remove();
    }, 0);
  }, []);
  return null;
};
const PolylineMap = ({ data, origin, loading, fullScreen }) => {
  const classes = useStyles();
  const mapCenter = [0.538659, 116.419389];
  const limeOptions = { color: colorPrimary };

  const maxRadius = 1200000;
  const logaritRadius = (value) => {
    const radius = (Math.log(value + 1) / Math.log(1000000 + 1)) * maxRadius;
    return radius;
  };

  return (
    <div
      style={{ width: "100%", padding: "8px 0px", height: "100%", flexGrow: 1 }}
    >
      <MapContainer
        attributionControl={false}
        scrollWheelZoom={false}
        center={mapCenter}
        zoom={fullScreen ? 4 : 2}
        style={{ width: "100%", zIndex: 99, height: "100%", borderRadius: 8 }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <ComponentResize />
        <FeatureGroup>
          {!!data &&
            data?.map((item, idx) => {
              return (
                <Fragment key={idx}>
                  <Polyline
                    weight={2}
                    pathOptions={limeOptions}
                    positions={[item.lonlat[0], item.lonlat[1]]}
                  >
                    <Popup className={classes.popupStyle}>
                      <div style={{ width: "350px" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography
                            style={{
                              color: "white",
                              fontSize: 14,
                              fontWeight: 600,
                            }}
                          >
                            To
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 5,
                            }}
                          >
                            <Typography
                              style={{ color: "white", fontSize: 14 }}
                            >
                              <b>
                                {item.code[1]}, {item.kota[1]},{" "}
                                {item.country[1]}
                              </b>
                            </Typography>
                            <ReactCountryFlag
                              countryCode={item.country[1]}
                              svg
                              style={{
                                fontSize: "2em",
                                lineHeight: "2em",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography
                            style={{
                              color: "white",
                              fontSize: 14,
                              fontWeight: 600,
                            }}
                          >
                            From
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 5,
                            }}
                          >
                            <Typography
                              style={{ color: "white", fontSize: 14 }}
                            >
                              <b>
                                {item.code[0]}, {item.kota[0]},{" "}
                                {item.country[0]}
                              </b>
                            </Typography>
                            <ReactCountryFlag
                              countryCode={item.country[0]}
                              svg
                              style={{
                                fontSize: "2em",
                                lineHeight: "2em",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Popup>
                  </Polyline>
                </Fragment>
              );
            })}
          {!!origin &&
            origin.map((item, idx) => (
              <Fragment key={idx}>
                <Circle
                  className={classes.circleStyle}
                  center={item[0]}
                  radius={logaritRadius(5)}
                  color="#99D9FB"
                  fillColor={colorPrimary}
                  weight={1.5}
                >
                  <Popup>
                    <div style={{ textAlign: "center" }}>
                      <Typography style={{ color: "white" }}>
                        {item[1]}
                      </Typography>
                    </div>
                  </Popup>
                </Circle>
              </Fragment>
            ))}
        </FeatureGroup>
      </MapContainer>
    </div>
  );
};

export default PolylineMap;
