import { Form, Input, Select, Switch } from "antd";
import React, { useMemo, useState } from "react";
import FormPage from "@/components/pages/FormPage";
import { useSelector } from "react-redux";
import { levelToRoles } from "@/redux/reducer/levelConvert";

const DefModel = {
  username: "",
  password: "",
  name: "",
  email: "",
  level: "",
  phone: "",
};

export default () => {
  const [model, setModel] = useState(DefModel);
  const { levels } = useSelector((state) => state.apps);

  const {
    userdata: { level },
  } = useSelector((state) => state.auth);
  const lvls = useMemo(() => {
    return levels.filter((l) => l.level <= level);
  }, [levels, level]);
  return (
    <FormPage
      url={"api/v1/users"}
      callbackPath={"master/users"}
      formValid={false}
      model={model}
      setModel={setModel}
      wrapperCol={{ span: 24 }}
      labelCol={{ span: 6 }}
      title={"Users"}
      emptyModel={DefModel}
      onEditFormEvent={(val) => {
        const { airport_id } = val;
        const is_airport = !!airport_id;
        return { ...val, is_airport };
      }}
    >
      <Form.Item
        name={"username"}
        label="Username"
        rules={[{ required: true }]}
      >
        <Input autoFocus />
      </Form.Item>
      <Form.Item name={"name"} label="Name">
        <Input />
      </Form.Item>
      {!!model?.isCreate && (
        <Form.Item
          name={"password"}
          label="Password"
          rules={[{ required: !!model?.isCreate }]}
        >
          <Input.Password />
        </Form.Item>
      )}
      <Form.Item name={"email"} label="Email">
        <Input />
      </Form.Item>

      <Form.Item label="Role">
        <Select
          value={levelToRoles(model?.level)}
          onChange={(val) => setModel({ ...model, level: val })}
        >
          {lvls.map((level, idx) => (
            <Select.Option key={idx} value={level.level}>
              {level.role}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </FormPage>
  );
};
