import { useDispatch, useSelector } from "react-redux";
import SplashPage from "@/splash";
import { initMe, loadAirportsAp1, setApps } from "@/redux";
import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import HeadOfficePages from "./pages";
import Login from "../auth/Login";

const UserPage = ({ userdata }) => {
  return (
    <Routes >
      <Route
        path="/"
        element={
          !!userdata ? <Navigate to={"/head-office/home"} /> : <Navigate to={"/head-office/login"} />
        }
      />
      <Route
        path="/login"
        element={
          !!userdata && (userdata.level&0x1ff0 ) > 0  ? (
            <Navigate to={"/head-office/home"} />
          ) : (
            <Login />
          )
        }
      />
      <Route
        path="/*"
        element={
          !!userdata && (userdata.level&0x1ff0 ) > 0  ? <HeadOfficePages /> :<Navigate to={"/head-office/login"} />
        }
      />
    </Routes>
  );
};

export default () => {
  const { userdata, initComplete } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setApps("head-office")); // set applikasi
    dispatch(initMe()); // cek token validasi
    dispatch(loadAirportsAp1());
  }, [initMe, setApps]);
  if (!initComplete) return <SplashPage />;
  return <UserPage userdata={userdata} />;
};
