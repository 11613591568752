import React, { Fragment, useMemo, useState } from "react";
import MainChart from "@/components/Chart";
import { Spin } from "antd";
import { useFontSize } from "@/hooks/useDimension";
import Config from "@/config/env";
import { getShippingData } from "@/helper/stakeholder/getShippingData";
import tooltipFormatter from "@/components/Chart/Tooltip";
import { resetStyleTooltips } from "@/components/Chart/resetStyleTooltip";

const { colorPrimary } = Config;

export default function TopPartnersBar({ data, loading }) {
  const fontSize = useFontSize();
  const [view, setView] = useState("commodity");
  const [isFullScreen, setIsFullScreen] = useState(false);

  const { trendKgs: dataset } = getShippingData(data, view);

  const total = data?.commodities?.reduce((total, num) => {
    return total + num.total_charge_weight;
  }, 0);

  const getPercented = (data) => {
    return ((data / total) * 100 || 0).toFixed(2) || 0;
  };

  const getLenghtData = useMemo(() => {
    return !!dataset[0]?.source && dataset[0]?.source?.length > 10;
  }, [view, dataset]);

  const optionBar = {
    grid: {
      x: !!isFullScreen ? fontSize.xl * 8 : fontSize.xl * 3,
      x2: fontSize.xl * 4,
      y: fontSize.lg,
      y2: fontSize.lg,
    },
    dataset,
    tooltip: {
      // trigger: "axis",
      position: function (pos, params, dom, rect, size) {
        // tooltip will be fixed on the right if mouse hovering on the left,
        // and on the left if hovering on the right.
        var obj = { top: 60 };
        obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 5;
        return obj;
      },
      ...resetStyleTooltips,
      formatter: (params) => {
        return tooltipFormatter(
          params.value[2],
          params.value[0],
          params.value[1],
          getPercented(params.value[0]),
          true
        );
      },
    },
    xAxis: {
      show: false,
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
    },
    dataZoom: [
      {
        type: "slider",
        start: !!getLenghtData ? 100 : 0,
        orient: "vertical",
        right: 36,
        end: !!getLenghtData ? 70 : 100,
        handleSize: 8,
      },
      {
        type: "inside",
        start: !!getLenghtData ? 100 : 0,
        end: !!getLenghtData ? 70 : 100,
        orient: "vertical",
        zoomOnMouseWheel: false,
        moveOnMouseMove: true,
        moveOnMouseWheel: true,
      },
    ],
    yAxis: {
      type: "category",
      axisLabel: {
        show: true,
        formatter: (params) =>
          `${!isFullScreen ? `${params.substring(0, 4)}...` : params}`,
      },
    },
    series: [
      {
        name: "category",
        label: {
          show: true,
          position: "right",
          formatter: (params) => `${getPercented(params.value[0]) || 0}%`,
        },
        encode: { x: "kgs", y: "code" },
        type: "bar",
        color: colorPrimary,
        datasetIndex: 1,
        itemStyle: {
          borderRadius: [0, 4, 4, 0],
        },
      },
    ],
  };

  return (
    <MainChart
      fullscreen
      onFullScreen={(val) => setIsFullScreen(val)}
      title={<Fragment>What are we shipping? {loading && <Spin />}</Fragment>}
      height="100%"
      onChange={(val) => setView(val)}
      view={view}
      option={optionBar}
      filterComp={[
        {
          title: "Commodity",
          value: "commodity",
        },
        {
          title: "Sub Commodity",
          value: "sub-commodity",
        },
      ]}
    />
  );
}
