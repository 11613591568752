import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setActivePage } from "@/redux";
import CalenderChart from "./components/CalenderChart";
import Filters from "./components/Filters";
import TreeChart from "./components/TreeChart";
import { useDashboardOverview } from "@/hooks/useDashboard";
function Commodity({ filterValue }) {
  const dispatch = useDispatch();
  const [callender, cLoading] = useDashboardOverview({
    ...filterValue,
    type: "callender",
    view: "commodity",
  });
  const [commodity, kLoading] = useDashboardOverview({
    ...filterValue,
    type: "commodity",
    view: "commodity",
  });
  useEffect(() => {
    dispatch(setActivePage("Commodity"));
  }, []);

  return (
    <div id="left" style={{ height: "100%" }}>
      <div style={{ display: "flex", height: "inherit", gap: 8 }}>
        <div style={{ width: "20%", height: "100%" }}>
          <CalenderChart
            data={callender}
            loading={cLoading}
            filterValue={filterValue}
          />
        </div>
        <div
          style={{
            flexGrow: 1,
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              height: "inherit",
              flexDirection: "column",
              gap: 8,
            }}
          >
            <div style={{ flex: 1, height: "10%" }}>
              <Filters />
            </div>

            <div style={{ flexGrow: 1, height: "89%" }}>
              <TreeChart data={commodity} loading={kLoading} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Commodity;
