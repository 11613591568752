import { Col, Row } from "antd";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDashboardOverview } from "@/hooks/useDashboard";
import { setActivePage } from "@/redux";
import ProductionCard from "../../airport/components/ProductionCard";
import RevenueCard from "../../airport/components/RevenueCard";
import ShippingRoute from "../../airport/components/ShippingRoute";
import RouteComparation from "../../airport/components/RouteComparation";
import ParetoChart from "../../airport/components/Pareto";
import { formatedOptions } from "@/helper/formatedOptions";

function Airport({ filterValue }) {
  const dispatch = useDispatch();
  const { airportAp1Cache } = useSelector((state) => state.apps);
  const [routes, setRoutes] = useState([
    {
      label: "UPG/Makassar Sultan Hasanuddin International Airport",
      value: "60a3d866045fd93f38efc1f6",
    },
  ]);
  const formatedOpt = formatedOptions(airportAp1Cache, ["code", "name"], "_id");

  const [route, rLoading] = useDashboardOverview({
    ...filterValue,
    type: "route",
    view: "airport",
  });

  const [panel, loadingPanel] = useDashboardOverview({
    ...filterValue,
    type: "panel",
  });

  const [production, pLoading] = useDashboardOverview({
    ...filterValue,
    type: "production",
    view: "airport",
  });

  const [pareto, loadingPareto] = useDashboardOverview({
    ...filterValue,
    type: "pareto",
  });

  const [routeComparison, rcLoading] = useDashboardOverview({
    ...filterValue,
    type: "route_comparison",
    view: "airport",
  });

  const idOrigin = useMemo(() => {
    return routes.map((item) => {
      return item.value;
    });
  }, [routes]);

  const filterRoutes = useMemo(() => {
    return (
      !!route &&
      route?.filter((item) => {
        return idOrigin.includes(item.origin._id);
      })
    );
  }, [routes, route]);

  const shippingRoutes = useMemo(() => {
    return (
      !!filterRoutes &&
      filterRoutes?.map((item) => {
        return {
          code: [item.origin.code, item.dest.code],
          country: [item.origin.country, item.dest.country],
          kota: [item.origin.kota, item.dest.kota],
          lonlat: [
            [item?.origin?.lat || 0, item?.origin?.lon || 0],
            [item?.dest?.lat || 0, item?.dest?.lon || 0],
          ],
        };
      })
    );
  }, [route, routes, filterRoutes]);

  const originRoutes = useMemo(() => {
    return (
      !!shippingRoutes &&
      shippingRoutes.map((item) => {
        const [lat] = item.lonlat || [0];
        const [name] = item.name || [""];
        return [lat, name];
      })
    );
  }, [shippingRoutes]);

  useEffect(() => {
    dispatch(setActivePage("Airport"));
  }, []);

  useEffect(() => {
    if (formatedOpt[0] !== undefined) {
      setRoutes([formatedOpt[0]]);
    }
  }, [airportAp1Cache]);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 8,
        padding: 8,
        margin: "50px 0px 30px 0px",
      }}
    >
      <Row style={{ flexDirection: "column", gap: 8 }} id="top">
        <Col sm={{ span: 24 }} xs={{ span: 24 }}>
          <ProductionCard
            data={production}
            loading={pLoading}
            summary={panel}
          />
        </Col>
        <Col sm={{ span: 24 }} xs={{ span: 24 }}>
          <RevenueCard data={production} loading={pLoading} />
        </Col>
      </Row>
      <Row style={{ flexDirection: "column", gap: 8 }} id="bottom">
        <Col sm={{ span: 24 }} xs={{ span: 24 }}>
          <ShippingRoute
            height={350}
            isMobile
            // loading={loading}
            origin={originRoutes}
            selected={routes}
            setSelected={setRoutes}
            options={formatedOpt}
            data={shippingRoutes}
          />
        </Col>
        <Col sm={{ span: 24 }} xs={{ span: 24 }}>
          <RouteComparation data={routeComparison} loading={rcLoading} />
        </Col>
        <Col sm={{ span: 24 }} xs={{ span: 24 }}>
          <div style={{ height: 350 }}>
            <ParetoChart dataSource={pareto} loading={loadingPareto} />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Airport;
