import React, { useState } from "react";
import TrendChart from "./TrendChart";
import { getDataTrendByView } from "@/helper/getDataTrendByView";

export default function TrendRevenue({ dataSource, isMobile, loading }) {
  const [viewRev, setViewRev] = useState("daily");
  const handleChange = (value) => {
    setViewRev(value);
  };
  const { currentYear, lastYear } = dataSource || {
    currentYear: {currentYear:[]}
  };
  const { trendPjkp2u } = getDataTrendByView(
    currentYear?.currentYear || [],
    lastYear,
    viewRev
  ) || { trendPjkp2u: [] };
  return (
    <TrendChart
      isMobile={isMobile}
      dataSource={trendPjkp2u}
      onChangeView={handleChange}
      view={viewRev}
      type="revenue"
      title="Revenue Trend Analysis(IDR)"
      loading={loading}
    />
  );
}
