import React, { useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Row,
  Col,
  Typography,
  Select,
  DatePicker,
  Radio,
  Avatar,
} from "antd";
import { createUseStyles } from "react-jss";
// import Menus from "./items";
import { iconBottom, items } from "./items";
import Config from "../../config/env";
import { RiCloseCircleLine } from "react-icons/ri";
import dayjs from "dayjs";
import getPreviousMonth from "../../helper/getPrevMonth";
import { useNavigate, useLocation } from "react-router-dom";
import { useFontSize } from "@/hooks/useDimension";
import LOGO from "@/assets/logos/logo-ap1.png";
const { Header, Content, Footer, Sider } = Layout;
const { Theme, colorPrimary } = Config;
const { RangePicker } = DatePicker;

const useStyles = createUseStyles({
  container: {
    minHeight: "100vh",
    overflow: "hidden !important",
    display: "flex",
    width: "100vw",
    // position: "relative"
  },
  header: {
    padding: "15px 0px",
    background: "#f5f5f5",
    height: "150px",
    borderBottom: `3px solid ${colorPrimary}`,
    marginRight: 20,
    marginLeft: 20,
  },
  content: {
    height: "100vh",
    background: "#EDEDED",
  },
  menuStyle: {
    paddingTop: ({ fontSize }) => fontSize.xl3,

    background: "none",

    "&.ant-menu-dark": {
      background: "none",
    },
    "& .ant-menu-item": {
      padding: "24px 12px !important",
      marginLeft: "8px !important",
      marginBottom: "20px !important",
    },
    "&.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline": {
      // marginLeft: 4,
      background: "none",
      padding: ({ fontSize }) => fontSize.md,
    },
    "&.ant-menu-dark .ant-menu-item-selected": {
      marginLeft: "18px !important",
      backgroundColor: "#EDEDED",
      fontWeight: "bold",
      color: colorPrimary,
      padding: ({ fontSize }) => fontSize.sm,
      "&:active": {
        backgroundColor: "none",
      },
      // margin: 0
    },
  },
  menuBottomStyle: {
    // paddingTop: 50,
    position: "absolute",
    bottom: "0",
    background: "none",
    "&.ant-menu-dark": {
      background: "none",
    },
    "&.ant-menu-dark .ant-menu-item": {
      // paddingLeft: 4,
      padding: "40px 12px",
    },
    "&.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline": {
      background: "none",
      padding: 24,
    },
    "&.ant-menu-dark .ant-menu-item-selected": {
      marginLeft: 18,
      backgroundColor: "#EDEDED",
      fontWeight: "bold",
      color: colorPrimary,
      padding: 40,
      "&:active": {
        backgroundColor: "none",
      },
      // margin: 0
    },
  },
  siderStyle: {
    // paddingTop: 170,
    height: "100vh",
    // position: "fixed !important",
    // top: 0,
    // bottom: 0,
    // left: 0,
    // zIndex: 99,
    background: `${colorPrimary} !important`,
  },
  itemStyle: {
    width: "110% !important",
    height: "100px !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    marginTop: "0px !important",
    "&:focus": {
      background: "#f5f5f5",
      color: colorPrimary,
    },
    "&:active": {
      background: "#f5f5f5 !important",
      color: colorPrimary,
    },
    "&:hover": {
      background: "#f5f5f5 !important",
      color: `${colorPrimary} !important`,
    },
  },
  titleHeader: {
    fontSize: "40px",
    fontWeight: 600,
    color: colorPrimary,
  },
  leftHeader: {
    gap: 20,
    alignItems: "center",
  },
  rightHeader: {
    background: colorPrimary,
    height: "135px",
    borderRadius: "20px 0px 0px 0px",
    // padding: '10px 25px',
    // gap: 30
  },
  labelSelect: {
    // borderBottom: '1px solid white',
    // paddingBottom: 10
  },
  dateStyles: {
    "& input::placeholder": {
      color: "white !important",
    },
    background: "transparent !important",
    "& .ant-picker-suffix": {
      color: "white",
    },
    "& .ant-picker-separator": {
      color: "white",
    },
    "& input": {
      color: "white !important",
    },
  },
  selectStyles: {
    width: 300,
    "& .ant-select-selector": {
      color: "white !important",
      background: `${colorPrimary} !important`,
    },
    "& .ant-select-arrow": {
      color: "white",
    },
    "& .anticon": {
      display: "none !important",
    },
    "& .ant-select-selection-placeholder": {
      color: "white",
    },
  },
  selectMultipleStyles: {
    width: 300,
    "& .ant-select-selector": {
      color: "white !important",
      background: `${colorPrimary} !important`,
    },
    "& .ant-select-arrow": {
      color: "white",
    },
    "& .anticon": {
      display: "none !important",
    },
    "& .ant-select-selection-placeholder": {
      color: "white",
    },
    "& .ant-select-selection-item": {
      display: "none !important",
    },
  },
  selectWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    position: "relative",
  },
  radioWrapper: {
    height: 60,
    marginTop: 10,
    borderRadius: "10px 0px 0px 0px",
    background: colorPrimary,
    padding: "4px",
    width: "100%",
    justifyContent: "space-around",
    alignItems: "center",
  },
  radioStyles: {
    "& .ant-radio-button-wrapper": {
      borderRadius: "0px !important",
      background: "white !important",
      width: 80,
      marginRight: 8,
      color: colorPrimary,
      fontWeight: 600,
      textAlign: "center",
      letterSpacing: 2,
    },
    "& .ant-radio-button-checked": {
      background: `${colorPrimary} !important`,
    },
  },
  labelDate: {
    display: "flex",
    alignItems: "center",
    gap: 12,
  },
  filterWrapper: {
    gap: 10,
    padding: "10px 25px",
  },
  multipleSelected: {
    borderBottom: "1px solid white",
    // borderRadius: '8px',
    width: "90%",
    height: 45,
    padding: 8,
    gap: 10,
    overflowY: "auto",
    // marginTop: 4
    alignItems: "center",
  },
  chipStyle: {
    padding: 4,
    border: "1px solid white",
    borderRadius: "8px",
    height: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 5,
    background: "white",
    cursor: "pointer",
  },
});

// const routeOptions = [{ label: 'Dom', value: 'domestic' }, { label: 'Inter', value: 'internatioal' }];
// const directionOptions = [{ label: 'Inc', value: 'incoming' }, { label: 'Out', value: 'outgoing' }];

function CustomeLayout({ children, ...props }) {
  const fontSize = useFontSize();
  const classes = useStyles({ ...props, fontSize });
  const monthFormat = "YYYY/MM";
  const navigate = useNavigate();
  const [key, setKey] = useState("");
  const [route, setRoute] = useState("");
  const [direct, setDirect] = useState("");
  const [category, setCategory] = useState("");
  const [value, setValue] = useState([]);
  const rangeSelected = [
    dayjs(new Date()).format(monthFormat),
    dayjs(getPreviousMonth(2)).format(monthFormat),
  ];
  const defaultCompare = [
    dayjs(getPreviousMonth(5)).format(monthFormat),
    dayjs(getPreviousMonth(3)).format(monthFormat),
  ];
  const [range, setRange] = useState([]);
  const [compare, setCompare] = useState([]);

  const { pathname } = useLocation();

  const MenusItem = items({ fontSize: fontSize.xl2 });
  const MenusItemBottom = iconBottom({ fontSize: fontSize.xl2 });

  return (
    <Layout className={classes.container}>
      <Sider
        className={`${classes.siderStyle} sider-dashboard`}
        width={fontSize.xl3 * 2}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: fontSize.sm,
              }}
            >
              <img
                src={LOGO}
                width={fontSize.lg * 3}
                style={{
                  padding: fontSize.xs,
                  background: "rgba(0,0,0, .1)",
                  borderRadius: 8,
                }}
              />

              {/* <Avatar  shape="square" src={<img src={LOGO} width={20}/>} size={fontSize.lg*2} /> */}
            </div>
            <Menu
              style={{ marginBottom: fontSize }}
              className={classes.menuStyle}
              theme="dark"
              defaultSelectedKeys={MenusItem[0].href}
              mode="inline"
              selectedKeys={MenusItem.map((el) => {
                if (el.href === pathname) return el.key;
              })}
              items={MenusItem}
            />
          </div>
          <Menu
            className={classes.menuStyle}
            theme="dark"
            // defaultSelectedKeys={items[0].href}
            mode="inline"
            selectedKeys={MenusItemBottom.map((el) => {
              if (el.href === pathname) return el.key;
            })}
            items={MenusItemBottom}
          />
        </div>
      </Sider>
      <div style={{ height: "100vh", width: "100%" }}>{children}</div>
    </Layout>
  );
}

export default CustomeLayout;
