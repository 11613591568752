export const formatedOptions = (
  opts = [],
  label = [],
  value = "",
  orderBy="asc",
  disabledValue = [],
  cb
) => {
  const [label1, label2] = label;
  const reMapOpts = opts.map((item) => {
    const [newLabel1, newLabel2] = typeof cb === "function" ? cb(item[label1], item[label2]) : [label1,label2]
    return (item = {
      label: !label ? item : !!label2 ? `${item[newLabel1]}/${item[newLabel2]}` : item[label],
      value: item[value],
    });
  });

  if(!!orderBy){
    if(orderBy === 'asc'){
      return reMapOpts.sort((a,b)=>{
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      })
    } else {
      return reMapOpts.sort((a,b)=>{
        if (a.label < b.label) {
          return 1;
        }
        if (a.label > b.label) {
          return -1;
        }
        return 0;
      })
    }
  }

  return reMapOpts;
};
