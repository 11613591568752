import { Row, Col, } from "antd";
import React, { useEffect, } from "react";
import { createUseStyles } from "react-jss";
import { getSummaryData } from "@/helper/getSummaryData";
import { useDashboardOverview } from "@/hooks/useDashboard";
import SummaryCard from "../../overviewV2/components/SummaryCard";
import TrendProduction from "../../overviewV2/components/TrendProduction";
import TrendRevenue from "../../overviewV2/components/TrendRevenue";
import Pareto from "../../overviewV2/components/Pareto";
import Config from "@/config/env";
import ShippingMapV2 from "../../overviewV2/components/ShippingMapV2";
import { useDispatch } from "react-redux";
import { setActivePage } from "@/redux";
const { colorPrimary, colorSecondary } = Config;

const useStyles = createUseStyles({
  container: { padding: "60px 0px 100px 0px" },
});

function Overview({ state }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  //   useDashboardOverview({ ...filterValue });
  const [panel, loadingPanel] = useDashboardOverview({
    ...state,
    type: "panel",
  });
  const [currentYear, loadingTrend] = useDashboardOverview({
    ...state,
    type: "trend",
  });
  const [pareto, loadingPareto] = useDashboardOverview({
    ...state,
    type: "pareto",
  });

  const [connected, loadingConnected] = useDashboardOverview({
    ...state,
    type: "connected",
  });
  const [mapData, loadingMap] = useDashboardOverview({
    ...state,
    type: "map",
  });

  const cardContents = getSummaryData(panel);

  useEffect(() => {
    dispatch(setActivePage("Overview"));
  }, []);

  return (
    <div className={classes.container}>
      <div id="card">
        <Row gutter={16} style={{ height: "100%", rowGap: 16, padding: 8 }}>
          {cardContents.map((item, idx) => (
            <Col span={12} style={{ height: "100%" }} key={idx}>
              <div style={{ height: "100%" }}>
                <SummaryCard
                  bgColor={
                    item.type === "production" ? colorPrimary : colorSecondary
                  }
                  dataSource={item}
                />
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <div id="trend" style={{ marginBottom: 15 }}>
        <Row>
          <Col span={24}>
            <div style={{ height: "100%", padding: 8 }}>
              <TrendProduction
                isMobile={true}
                dataSource={{ currentYear }}
                loading={loadingTrend}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div id="trend" style={{ marginBottom: 15 }}>
        <Row>
          <Col span={24}>
            <div style={{ height: "100%", padding: 8 }}>
              <TrendRevenue
                isMobile={true}
                dataSource={{ currentYear }}
                loading={loadingTrend}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div id="last">
        <Row style={{ height: "100%", rowGap: 15, padding: 8 }}>
          <Col span={24} style={{ height: "100%" }}>
            <div style={{ height: "100%" }}>
              <Pareto
                isMobile
                title="Pareto Analysis (Kg)"
                dataSource={pareto}
              />
            </div>
          </Col>
          <Col span={24} style={{ height: "100%" }}>
            <div style={{ height: "400px" }}>
              <ShippingMapV2
                isMobile
                connectedData={connected}
                connectedLoading={loadingConnected}
                mapData={mapData}
                loadingMap={loadingMap}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Overview;
