import React, { Fragment, useMemo, useState } from "react";
import {
  Col,
  Row,
  Typography,
  DatePicker,
  Select,
  Divider,
  AutoComplete,
  Layout,
  Button,
} from "antd";
import dayjs from "dayjs";
import Config from "../../config/env";
import { TbArrowsExchange } from "react-icons/tb";
import { useSelector } from "react-redux";
import { formatedOptions } from "../../helper/formatedOptions";

const { Theme, colorPrimary } = Config;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Header } = Layout;

export default function MainHeader({ onChange, filterValue }) {
  const { airportAp1Cache } = useSelector((state) => state.apps);
  const [isRangePickerOpen, setIsRangePickerOpen] = useState(false);
  const [isRangePickerOpen2, setIsRangePickerOpen2] = useState(false);

  const optAirport = useMemo(() => {
    const active = ["LOP", "KOE", "SOC"];

    const formatedOpt = formatedOptions(
      airportAp1Cache,
      ["code", "name"],
      "code"
    );
    return formatedOpt.map((item) => {
      // if (item.value !== "LOP" || item.value !== "KOE") item = { ...item, disabled: true };
      item = { ...item, disabled: true };
      switch (item.value) {
        case "LOP":
          item = { ...item, disabled: false };
          break;
        case "KOE":
          item = { ...item, disabled: false };
          break;
        case "SOC":
          item = { ...item, disabled: false };
          break;

        default:
          break;
      }
      return item;
    });
  }, [airportAp1Cache]);

  return (
    <Fragment>
      <Row
        style={{
          borderBottom: `6px solid ${colorPrimary}`,
          background: "white",
          position: "fixed",
          right: 0,
          left: "5rem",
          top: 0,
          zIndex: 99,
        }}
      >
        <Col span={8}>
          <div
            style={{
              display: "flex",
              gap: 8,
              alignItems: "center",
              padding: ".8rem",
              // border: "1px solid red",
            }}
          >
            <Typography.Title
              style={{ margin: 0, fontWeight: "bold", fontSize: "1.2rem" }}
            >
              Cargo Analytics Dashboard
            </Typography.Title>
            <Divider
              type="vertical"
              style={{ borderInlineStart: "3px solid #dadada", height: "3rem" }}
            />
            <Typography.Title
              style={{ margin: 0, fontSize: "1.2rem", color: "red" }}
            >
              Stakeholder
            </Typography.Title>
          </div>
        </Col>
        <Col span={16}>
          <div
            style={{
              backgroundColor: `${colorPrimary}`,
              height: "100%",
              borderTopLeftRadius: 16,
              padding: "8px 32px",
            }}
          >
            <Row gutter={16}>
              <Col span={9}>
                <Typography.Text style={{ fontSize: ".9rem", color: "white" }}>
                  Airport
                </Typography.Text>
                <Select
                  showSearch
                  value={filterValue.airport}
                  // size="large"
                  style={{ width: "100%" }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  onChange={(val) => onChange("airport", val)}
                  filterOption={(input, option) =>
                    (option?.label?.toLowerCase() ?? "").includes(
                      input?.toLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "all",
                      label: "All Airport",
                    },
                    ...optAirport,
                  ]}
                />
              </Col>
              <Col span={7}>
                <Typography.Text style={{ fontSize: ".9rem", color: "white" }}>
                  Selected Month
                </Typography.Text>
                <RangePicker
                  renderExtraFooter={() => (
                    <div
                      style={{
                        // border: "1px solid red",
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: "10px",
                      }}
                    >
                      <Button
                        type="primary"
                        onClick={() => setIsRangePickerOpen(false)}
                      >
                        Hide Panel
                      </Button>
                    </div>
                  )}
                  allowClear={false}
                  // open={isRangePickerOpen}
                  onOpenChange={() => {
                    setIsRangePickerOpen(true);
                  }}
                  style={{ width: "100%" }}
                  // size="large"
                  picker="month"
                  value={filterValue.selectedDate}
                  format={"MMM-YYYY"}
                  // onChange={(date, dateStr) => {
                  //   onChange("selectedDate", date);
                  // }}
                  onCalendarChange={(dates, dateString, info) => {
                    let result = null;
                    if (info.range === "start") {
                      // onChange("selectedDate", [
                      //   dates[0],
                      //   dayjs(dates[0]).add(1, "month"),
                      // ]);
                      // console.log(range);
                      result = [dates[0], dayjs(dates[0]).add(1, "month")];
                    } else {
                      result = dates;
                      const [date1, date2] = result;
                      const diffMonthSelected = date2.diff(date1, "month");
                      const endComparator = dayjs(date1).add(-1, "month");
                      const startComparator = dayjs(endComparator).add(
                        -Math.abs(diffMonthSelected),
                        "month"
                      );
                      const changeComparator = [startComparator, endComparator];
                      onChange("comparatorDate", changeComparator);
                      onChange("selectedDate", result);
                    }
                  }}
                />
              </Col>
              <Col span={1}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "end",
                    height: "100%",
                  }}
                >
                  <TbArrowsExchange color="white" size={32} />
                </div>
              </Col>
              <Col span={7}>
                <Typography.Text style={{ fontSize: ".9rem", color: "white" }}>
                  Comparator Month
                </Typography.Text>
                <RangePicker
                  renderExtraFooter={() => (
                    <div
                      style={{
                        // border: "1px solid red",
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: "10px",
                      }}
                    >
                      <Button
                        type="primary"
                        onClick={() => setIsRangePickerOpen2(false)}
                      >
                        Hide Panel
                      </Button>
                    </div>
                  )}
                  onOpenChange={() => {
                    setIsRangePickerOpen2(!isRangePickerOpen2);
                  }}
                  disabledDate={(current) => {
                    const dates = filterValue.selectedDate;
                    if (!dates) {
                      return false;
                    }
                    const diffMonthSelected = dates[1].diff(dates[0], "month");

                    return current?.isAfter(
                      dayjs(dates[0]).add(
                        -Math.abs(diffMonthSelected + 1),
                        "month"
                      )
                    );
                  }}
                  allowClear={false}
                  open={isRangePickerOpen2}
                  style={{ width: "100%" }}
                  value={filterValue.comparatorDate}
                  onCalendarChange={(dates, dateString, info) => {
                    const [date1, date2] = filterValue.selectedDate;
                    const [compDate1, compDate2] = dates;
                    const diffMonthSelected = date2.diff(date1, "month");
                    if (info.range === "start") {
                      const endComparator = dayjs(compDate1).add(
                        diffMonthSelected,
                        "month"
                      );
                      const changeComparator = [compDate1, endComparator];

                      onChange("comparatorDate", changeComparator);
                    } else {
                      const startComparator = dayjs(compDate2).add(
                        -Math.abs(diffMonthSelected),
                        "month"
                      );
                      const changeComparator = [startComparator, compDate2];
                      onChange("comparatorDate", changeComparator);
                    }
                    setIsRangePickerOpen2(false);
                  }}
                  picker="month"
                  format={"MMM-YYYY"}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}
