import { Col, Row, } from "antd";
import React, { useEffect } from "react";
import Config from "@/config/env";
import SummaryCard from "./components/SummaryCard";
import TrendProduction from "./components/TrendProduction";
import { useDashboardOverview } from "@/hooks/useDashboard";
import { useFontSize } from "@/hooks/useDimension";
import TrendRevenue from "./components/TrendRevenue";
import Pareto from "./components/Pareto";
import ShippingMapsV2 from "./components/ShippingMapV2";
import { getSummaryData } from "@/helper/getSummaryData";
import { useDispatch } from "react-redux";
import { setActivePage } from "@/redux";

const { colorPrimary, colorSecondary } = Config;

export default function OverviewNew({ filterValue }) {

  const [panel, loadingPanel] = useDashboardOverview({
    ...filterValue,
    type: "panel",
  });
  const [currentYear, loadingTrend] = useDashboardOverview({
    ...filterValue,
    type: "trend",
  });
  const [pareto, loadingPareto] = useDashboardOverview({
    ...filterValue,
    type: "pareto",
  });
  const [connected, loadingConnected] = useDashboardOverview({
    ...filterValue,
    type: "connected",
  });
  const [mapData, loadingMap] = useDashboardOverview({
    ...filterValue,
    type: "map",
  });

  const cardContents = getSummaryData(panel);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActivePage("Overview"));
  }, []);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 8,
      }}
    >
      <div id="card" style={{ flex: 0.8 }}>
        <Row gutter={8} style={{ height: "100%" }}>
          {cardContents.map((item, idx) => (
            <Col lg={{ span: 4 }} style={{ height: "100%" }} key={idx}>
              <div style={{ height: "100%" }}>
                <SummaryCard
                  loading={loadingPanel}
                  bgColor={
                    item.type === "production" ? colorPrimary : colorSecondary
                  }
                  dataSource={item}
                />
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <div id="trend" style={{ flex: 1.7 }}>
        <Row gutter={8} style={{ height: "100%" }}>
          <Col lg={{ span: 12 }} style={{ height: "100%" }}>
            <div style={{ height: "100%" }}>
              <TrendProduction
                dataSource={{ currentYear }}
                loading={loadingTrend}
              />
            </div>
          </Col>
          <Col lg={{ span: 12 }} style={{ height: "100%" }}>
            <div style={{ height: "100%" }}>
              <TrendRevenue
                dataSource={{ currentYear }}
                loading={loadingTrend}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div id="last" style={{ flex: 2.4 }}>
        <Row gutter={8} style={{ height: "100%" }}>
          <Col lg={{ span: 12 }} style={{ height: "100%" }}>
            <div style={{ height: "100%" }}>
              <Pareto
                fullscreen={true}
                title="Pareto Analysis (Kg)"
                dataSource={pareto}
                loading={loadingPareto}
              />
            </div>
          </Col>
          <Col lg={{ span: 12 }} style={{ height: "100%" }}>
            <div style={{ height: "100%" }}>
              <ShippingMapsV2
                connectedData={connected}
                connectedLoading={loadingConnected}
                mapData={mapData}
                loadingMap={loadingMap}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
