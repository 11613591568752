import { Button, Col, Input, Row,Form, Typography } from "antd";
import DataVizual from "@/assets/images/data-vizualitation.png";
import BumnLogo from "@/assets/logos/bumn-logo-white.png";
import InjourneyLogo from "@/assets/logos/injourney-logo-white.png";
import ApLogo from "@/assets/logos/ap-logo.png";
import { useFontSize } from "@/hooks/useDimension";
import config from "@/config/env";
import { login } from "@/redux";
import { useDispatch } from "react-redux";


const { colorWhite, colorGray, colorPrimary } = config;


export default function Login() {
  const fontSize = useFontSize();
  const dispatch = useDispatch();

  const onFinish = (values) => {
    dispatch(login({ ...values, app: "dashboard" }));
  };
  return (
    <Row style={{ height: "100vh" }}>
      <Col
        lg={14}
        style={{
          background: "#203864",
          padding: fontSize.md * 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
          <img src={BumnLogo} width={fontSize.md * 9} alt="bumn-logo" />
          <img
            src={InjourneyLogo}
            width={fontSize.md * 9}
            alt="injourey-logo"
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <img src={DataVizual} width="70%" alt="data-visual" />
        </div>
        <div
          style={{
            width: "60%",
            margin: "4px auto",
            textAlign: "center",
            marginBottom: fontSize.md * 4,
          }}
        >
          <Typography
            style={{
              fontSize: fontSize.xl2,
              color: colorWhite,
              fontWeight: "bold",
              marginBottom: 8,
            }}
          >
            Cargo Analytics Dashboard
          </Typography>
          <Typography style={{ fontSize: fontSize.sm, color: colorWhite }}>
            This dashboard can help you montor & analyze the shipment perfomance
            across regions and understand customers better.
          </Typography>
        </div>
      </Col>
      <Col
        lg={10}
        style={{
          background: colorWhite,
          padding: fontSize.md * 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div style={{ textAlign: "right" }}>
          <img src={ApLogo} width={fontSize.md * 14} alt="ap1-logo" />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div id="form-login" style={{ width: "60%" }}>
            <div style={{marginBottom:fontSize.xl}}>
            <Typography
              style={{
                color: colorPrimary,
                fontWeight: "bold",
                fontSize: fontSize.xl2,
              }}
            >
              Welcome back!
            </Typography>
            <Typography style={{ color: colorGray }}>
              Lets get to dashboard
            </Typography>
            </div>
            <Form
              layout="vertical"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 24,
              }}
              onFinish={onFinish}
              autoComplete="off"
              style={{color:colorGray}}
            >
              <Form.Item
                label={<label style={{color:colorGray}}>Username</label>}
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input size="large"  />
              </Form.Item>

              <Form.Item
                label={<label style={{color:colorGray}}>Password</label>}
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password size="large"  />
              </Form.Item>

              <Form.Item >
                <Button size="large"  style={{width:"100%",fontSize:fontSize.md}}  type="primary" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div>
          <Typography
            style={{
              textAlign: "center",
              fontSize: fontSize.xs,
              color: colorGray,
            }}
          >
            Version App @Beta
          </Typography>
        </div>
      </Col>
    </Row>
  );
}
