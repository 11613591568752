import React, { Fragment } from "react";
import { Spin } from "antd";
import { useFontSize } from "@/hooks/useDimension";
import Config from "@/config/env";
import MainChart from "@/components/Chart";
import { parseFormatedNumber } from "@/helper/parseFormatedNumber";
import numeral from "numeral";
import { resetStyleTooltips } from "@/components/Chart/resetStyleTooltip";
import { markerTooltip } from "@/components/Chart/markerTooltip";
const { colorPrimary, colorSecondary } = Config;

function TreeChart({ isMobile = false, loading, data }) {
  const fontSize = useFontSize();

  const getPercented = (total, value) => ((value / total) * 100 || 0).toFixed(2);

  const option = {
    tooltip: {
      ...resetStyleTooltips,
      formatter: (params) => {
        return `
        <div class="main-tooltip">
          <div style="margin-bottom:4px;">
            ${params.marker}
            <strong>${params.name}</strong>
          </div>
          <div class="container-tooltip">
          <div class="item-tooltip">
              <div>
               ${markerTooltip("production")}Production Kg
              </div>
              <div class="text-right text-bold">
              ${numeral(params.value).format("0,000")}
              </div>
            </div>
            <div class="item-tooltip">
              <div>
              ${markerTooltip("production")} %GT Total Kg
              </div>
              <div class="text-right text-bold">
                ${getPercented(params.data.total, params.data.value)}%
              </div>
            </div>
            
          </div>
        </div>
        `;
      },
    },
    series: [
      {
        left: 16,
        right: 16,
        top: 56,
        bottom: 60,
        name: "Commodity",
        type: "treemap",
        itemStyle: {
          borderColor: "white",
          borderWidth: 1,
        },
        visibleMin: 300,
        label: {
          show: true,
          fontSize: fontSize.sm,
          lineHeight: fontSize.md,
          formatter: (params) => {
            const percent = getPercented(params.data.total, params.value);
            let format = [
              `${params.name}`,
              `${parseFormatedNumber(params.value)} (${percent || 0}%)`,
            ];
            return format.join("\n");
          },
        },
        itemStyle: {
          borderColor: "#fff",
        },
        data: data,
        leafDepth: 1,
        breadcrumb: {
          top: "1%",
          left: "1%",
          itemStyle: {
            padding: 8,
            textStyle: {
              padding: 100,
              fontSize: fontSize.xs,
            },
          },
        },
        levels: [
          {
            itemStyle: {
              borderColor: "white",
              borderWidth: 1,
              gapWidth: 1,
            },
          },
          {
            colorSaturation: [0.3, 0.6],
            itemStyle: {
              // borderColorSaturation: 0.7,
              gapWidth: 2,
              borderWidth: 2,
            },
          },
          {
            colorSaturation: [0.3, 0.5],
            itemStyle: {
              gapWidth: 2,
            },
          },
          {
            colorSaturation: [0.3, 0.5],
          },
        ],
      },
    ],
  };
  return (
    <MainChart
      title={
        <Fragment>
          Goods Decomposition Tree Analysis {loading && <Spin />}
        </Fragment>
      }
      fullscreen
      height={isMobile ? "450px" : "100%"}
      option={option}
    />
  );
}

export default TreeChart;
