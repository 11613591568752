import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  createBrowserRouter,
  Route,
  BrowserRouter as Router,
  RouterProvider,
  Routes,
} from "react-router-dom";
import Landing from "./landing";
import DashboardPage from "./dashboard";
import DashboardV2 from "./dashboard-v2";
import MobileDashboard from "./dashboard-v2/pages/mobile/dashboard";
import MobileLanding from "./dashboard-v2/pages/mobile/landing";
import useWindowDimensions from "../hooks/useDimension";
import HeadOfficePage from "./head-office";

const MAX_RES_MOBILE = 1200;
export default () => {
  const { width } = useWindowDimensions();

  const isMobileView = useMemo(() => {
    return width < MAX_RES_MOBILE;
  }, [width]);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={isMobileView ? <MobileLanding /> : <Landing />}
        />
        <Route
          path="/dashboard-v2/*"
          element={isMobileView ? <MobileDashboard /> : <DashboardV2 />}
        />
        <Route path="/head-office/*" element={<HeadOfficePage />} />
      </Routes>
    </Router>
  );
};
