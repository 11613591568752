import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useFontSize } from "../../hooks/useDimension";
import Config from "../../config/env";
import { Button } from "antd";

const { colorPrimary } = Config;
export default function FiltersSwapper({ filterComp, onChange, view, className }) {
  const fontSize = useFontSize();
  const isActive = (value) => {
    return !!view && view === value ? true : false;
  };
  

  return (
    <Swiper
      // style={{ paddingTop: 8, paddingBottom: 16 }}
      className={className}
      spaceBetween={8}
      slidesPerView="auto"
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {filterComp.map((filterItem, idx) => {
        const Component = filterItem.Component || Button;
        return (
          <SwiperSlide
            key={idx}
            style={{ marginBottom: 8 }}
            onClick={() =>
              typeof onChange === "function"
                ? onChange(filterItem.value)
                : undefined
            }
          >
            <Component
              style={{
                flex: 1,
                flexGrow: 1,
                padding: `2px 8px`,
                height: "fit-content",
                borderRadius: 4,
                border: "none",
                fontSize: fontSize.sm,
                // height: "100%",
                background: "none",
                color: !!isActive(filterItem.value) && "white",
                border: "1px solid #dadada",
                background: !!isActive(filterItem.value) && colorPrimary,
                borderRadius: 8,
              }}
              {...filterItem.ComponentProps}
            >
              {filterItem.title}
            </Component>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
