import React, { Fragment, useState } from "react";
import { Col, Radio, Row, Typography } from "antd";
import ReactECharts from "echarts-for-react";
import { createUseStyles } from "react-jss";

import MainCard from "../MainCard";
import Config from "../../config/env";

const { colorPrimary } = Config;

const useStyles = createUseStyles({
  radioStyle: {
    "& .ant-radio-button-wrapper": {
      borderRadius: "4px !important",
      background: "white !important",
      width: "6.25rem",
      // borderRadius: "0.5rem",
      marginRight: "0.5rem",
      color: colorPrimary,
      fontWeight: 600,
      textAlign: "center",
      fontSize: "0.8rem",
      letterSpacing: 0,
      // justifyItems: "flex-end",
    },
    "& .ant-radio-button-checked": {
      background: `${colorPrimary} !important`,
    },
  },
});
const radioOptions = [
  { label: "Full Day", value: "daily" },
  { label: "Week Day", value: "weekly" },
];

export default function TrendCard({ title, options, height = "12.5rem" }) {
  const classes = useStyles();
  const [filterValue, setFilterValue] = useState("daily");

  return (
    <Fragment>
      <MainCard>
        <div
          style={{
            padding: "0.25",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // border: "1px solid red",
          }}
        >
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "bold",
              color: colorPrimary,
              padding: "0.30rem 1rem",
            }}
          >
            {title}
          </Typography>
          <Row
            style={{
              justifyContent: "center",
              marginTop: 10,
              // border: "1px solid red",
            }}
          >
            <Col>
              <Radio.Group
                size="small"
                defaultValue="Daily"
                className={classes.radioStyle}
                options={radioOptions}
                onChange={({ target: { value } }) => {
                  setFilterValue(value);
                }}
                value={filterValue}
                optionType="button"
                buttonStyle="solid"
              />
            </Col>
          </Row>
        </div>
        <ReactECharts
          style={{ width: "100%", height }}
          option={
            filterValue === "daily"
              ? options?.optionDaily
              : options?.optionWeekly
          }
        />
      </MainCard>
    </Fragment>
  );
}
