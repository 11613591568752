import AsyncSelect from "react-select/async";
import React, { useCallback, useEffect, useRef, useState, } from "react";
import { GetAPI } from "../../redux";
import { useDispatch } from "react-redux";
import { createUseStyles } from "react-jss";
import {theme} from 'antd'
const { useToken } = theme;
const useStyles = createUseStyles({
    root:{
        "& .react-select__control":{
            borderColor:`none`,
            borderRadius:({token})=>token.borderRadius,
            "&:hover":{
                border: ({token})=>`1px solid ${token.colorPrimary}`
            },
            "&:focus":{
                border: ({token})=>`1px solid ${token.colorPrimary}`,
            }
        }
    }
})

export default ({
  getValues,
  onChange,
  value,
  getSelectedOptions,
  getKey,
  uri,
  width,
}) => {
    const { token } = useToken();
    const classes=useStyles({token});
  const mapper = (row, idx) => {
    const value = (!!getValues && getValues(row)) || row;
    const key = (!!getKey && getKey(row)) || idx;
    return { label: value, value: value, key, row };
  };
  const dispatch = useDispatch();
  const loadData = async (val, uri) => {
    const url = `${uri}?page=1&perPage=10&search=${val}`;
    const resp = await dispatch(GetAPI({ url }));
    return resp.payload.data?.data;
  };
  const [defaultOptions, setDefaultOpt] = useState([]);

  useEffect(() => {
    loadData("", uri).then((data) => {
      if (!!data) {
        setDefaultOpt(data);
      }
    });
  }, []);
  const timer = useRef(false);
  const loadOptions = useCallback(
    (val) => {
      if (timer.current) clearTimeout(timer.current);
      return new Promise((res) => {
        const p = new Promise((resolve) => {
          timer.current = setTimeout(() => {
            loadData(val, uri).then((result) => {
              resolve(result);
            });
          }, 300);
        });
        p.then((result) => res(result));
      });
    },
    [mapper, uri]
  );

  return (
    <AsyncSelect
    styles={{
        control: (provided, state) => ({
            ...provided,
            borderRadius: token.borderRadius,
            boxShadow: state.isFocused && `0 0 0 2px rgba(0,0,0, .2)`,
            border: state.isFocused && `1px soid ${token.colorPrimary}`
          }),
          
    }}
    theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: token.colorPrimary,
        },
      })}
      cacheOptions={true}
      loadOptions={loadOptions}
      defaultOptions={defaultOptions || []}
      style={{ width}}
      value={value}
      isClearable
      getOptionLabel={(opt) => getValues(opt)}
      getOptionValue={(opt) => getValues(opt)}
      onChange={onChange}
    />
  );
};
