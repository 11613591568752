import { Spin, Tag, Typography } from "antd";
import MainCard from "@/components/MainCard";
import { MdOutlineTrendingDown, MdOutlineTrendingUp } from "react-icons/md";
import { useFontSize } from "@/hooks/useDimension";
import Config from "@/config/env";
import numeral from "numeral";
import { RiBattery2ChargeLine } from "react-icons/ri";
import { Fragment } from "react";


const { colorPrimary, colorSecondary } = Config;

export default function SummaryCard({ bgColor, dataSource, loading }) {
  const fontSize = useFontSize();
  const { prev, curr, type, title, is_recovery} = dataSource;
  const diff = curr - prev;
  const percent = is_recovery?(curr / prev) : (diff / prev);

  const renderHead=()=>{
    return is_recovery?`${numeral(curr).format('0.00a')} / ${numeral(prev).format('0.00a')}`:`${numeral(percent).format('0.0%')} | ${numeral(diff).format('0.0a')}`
  }

  const getColorHead=()=>{
    const positive = is_recovery?curr - prev:percent;
    return positive>0?'green':positive<0?'red':'blue';
  }
  
  const renderBody=()=>{
    // {type === 'revenue'?: numeral(curr).format("0.00a")}
    return is_recovery?numeral(percent).format('0.00%'):numeral(curr).format("0.00a");
  }

  const renderContent=()=>{
    return (
      <Fragment>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            background: "white",
            borderTopLeftRadius: fontSize.xs,
            padding: fontSize.xs - 2,
            borderTopRightRadius: fontSize.xs,
            borderBottomLeftRadius: fontSize.sm,
            borderBottomRightRadius: fontSize.sm,
            flexGrow: 1,
            textAlign: "center",
            justifyContent: "center",
            // alignItems: "center"
          }}
        >
          <div>
              <Tag
                bordered={false}
                icon={
                  is_recovery?(<RiBattery2ChargeLine size={fontSize.sm} />) :
                  percent > 0 ? (
                    <MdOutlineTrendingUp size={fontSize.sm} />
                  ) : percent < 0 ? (
                    <MdOutlineTrendingDown size={fontSize.sm} />
                  ) : (
                    false
                  )
                }
                color={
                  getColorHead()
                }
                style={{
                  fontWeight: "bold",
                  fontSize: fontSize.xs,
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                  width: "max-content",
                }}
              >
                {renderHead()}
              </Tag>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                flexGrow: 1,
              }}
            >
              <Typography
                style={{
                  fontSize: fontSize.xl,
                  fontWeight: "bold",
                  alignSelf: "center",
                  // color: summaryItem?.type === "production" ? colorPrimary : colorSecondary,
                }}
              >
                {renderBody()} {loading && <Spin />}
              </Typography>
            </div>
        </div>
        <div
          style={{
            textAlign: "center",
            padding: "0.3rem 1rem",

            fontWeight: "bold",
          }}
        >
          <Typography style={{ fontSize: fontSize.sm, color: "white" }}>
            {title}
          </Typography>
        </div>
      </Fragment>
    )
  }

  return (
    <MainCard>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          background: !!bgColor ? bgColor : "#dadada",
          justifyContent: "space-between",
          height: "100%",
          borderTopLeftRadius: fontSize.xs + 2,
          borderTopRightRadius: fontSize.xs + 2,
          borderBottomLeftRadius: fontSize.xs,
          borderBottomRightRadius: fontSize.xs,
        }}
      >
        {
          renderContent()
        }
      </div>
    </MainCard>
  )
}
