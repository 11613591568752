import moment from "moment";

const getComparatedValue = (current, comparator, view) => {
  const result =[];
  for (const key in current) {
    let objResult = {}
    if (Object.hasOwnProperty.call(current, key)) {
      const { value, _id } = current[key];
      const { value:comparatorVal } =comparator[key];
      objResult[view] = comparatorVal
      objResult ={...objResult, 
        name: moment().month(_id).format("MMM"),
        curr: value || 0,
        diff: comparatorVal === 0? 0: comparatorVal-value  
      }
      result.push(objResult);
      
    }
  }
  return result;
};


const getProductionTrend = (data,dataComparator,view)=>{
  switch (view) {
    case "target":
      const targetData = dataComparator["charge_weight_target"]
      return getComparatedValue(data,targetData, view);
    case "prev-year":
      const prevData = dataComparator["prev"]?.map((item)=> ({_id:item?._id , value:item.total_charge_weight}))
      return getComparatedValue(data,prevData, view);
    default:
      return;
  }
}

const getRevenueTrend = (data,dataComparator,view, subView)=>{
  switch (view) {
    case "target":
      const lineBussines = subView === "pjkp2u" ? data : dataComparator[`${subView}`]
      return getComparatedValue(lineBussines,dataComparator[`${subView}_${view}`], `${subView}_${view}`);
    case "prev-year":
      const comparatorData = subView==="pjkp2u"? dataComparator["prev"]?.map((item)=> ({_id:item?._id , value:item.total_pjkp2u})) :  dataComparator[subView]
      return getComparatedValue(data,comparatorData, subView);
    default:
      return;
  }
}



export const getTrendComparative = (type, data, view, subView) => {
  const currentVal = type === "production" ? data?.current?.map((item)=> ({_id:item?._id , value:item.total_charge_weight}))  : data?.current?.map((item)=> ({_id:item?._id , value:item.total_pjkp2u}))
  return  type === "production" ? getProductionTrend(currentVal, data, view) : getRevenueTrend(currentVal, data, view, subView)
};
