// import { useEffect, useMemo, useState } from "react";
import { useFetch } from "./useFetch";
// import moment from "moment";

export const useDashboardOverview = ({
  year,
  months,
  airports,
  direction,
  route,
  type,
  view,
}) => {
  const obj = { year, months, airports, direction, route };
  const result = useFetch(
    `/api/dashboard/${view || 'overview'}?type=${type}&search=${JSON.stringify(obj)}`, true
  );  
  return result;
};
