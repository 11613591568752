import React, { Fragment } from "react";
import TablePage from "@/components/pages/TablePage";
import moment from "moment";
import { useFontSize } from "@/hooks/useDimension";
import { Button, Popover } from "antd";
import Config from '@/config/env'

const {colorPrimary} = Config
export default () => {
  const fontSize = useFontSize();
  const columns = [
    {
      selector: (row) => <strong>{row?.commodity}</strong>,
      name: "Commodity Name",
      id: "commodity",
    },
    {
      selector: (row) => row?.alternate_names?.toString(),
      name: "Alternante Names",
      id: "alternate_names",
      width: "300px",
      wrap: true,
    },
    {
      selector: (row) => row?.handling_id?.name,
      name: "SHC",
      id: "handling_id",
    },
    {
      selector: (row) => row?.category_id?.name,
      name: "Category",
      id: "category_id",
    },

    {
      selector: (row) => row?.hscode_id?.code && (
        <Popover content={
            <div style={{width:300}}>
                {row?.hscode_id?.id_name}
            </div>
        } title={row?.hscode_id?.code} trigger="hover">
          <Button style={{color:colorPrimary}} type="text">{row?.hscode_id?.code}</Button>
        </Popover>
      ),
      name: "HS Code",
      id: "hscode_id",
    },
  ];
  return (
    <TablePage
      title={"Master Goods"}
      url="api/v1/commodity"
      actionPosition={"first"}
      createPath="create"
      editPath={"edit"}
      columns={columns}
    />
  );
};
