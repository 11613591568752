import React from "react";
import { Modal } from "antd";
import "leaflet/dist/leaflet.css";


function ModalChart({ children, title, open, setOpen }) {
  return (
    <Modal
      width="100%"
      style={{ top: 0, height: "100vh", zIndex: 999999999, position: "relative" }}
      bodyStyle={{ height: "calc(100vh - 108px)", overflow: "auto", display:'flex', flexDirection:'column' }}
      centered
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      open={open}
      title={title}
    >
      {children}
    </Modal>
  );
}

export default ModalChart;
