import { groupingData } from "./getDataTrendByView";


export const getId=(data_source, view, id, fields=[], parseId=false)=>{
  if(!data_source || !data_source[view])return{trendKgs:[], trendPjkp2u:[]};
  const idx=[];
  const trendKgs=[];
  for (let iii = 0; iii < data_source[view].length; iii++) {
    const item = data_source[view][iii];
    const {_id, totalChw, totalPjkp2u} = item;
    const other=[];
    for (let iii = 0; iii < fields.length; iii++) {
      const fn = fields[iii];
      other.push(_id[fn]);
    }
    let code=!!parseId?parseId(_id[id]):_id[id];
    const i=idx.indexOf(code);
    if(i>=0){
      code=idx[i]+'_';
    }
    idx.push(code);
    trendKgs.push([totalChw, totalPjkp2u, code, ...other]);
  }
  
  const dataset=[
    {
      dimensions:['kgs', 'pjkp2u', 'code', ...fields],
      source:trendKgs
    },
    {
      transform:{
        type:"sort",
        config:{dimension:'kgs', order:'asc'}
      }
    }
    
  ]
  return {trendKgs:dataset, trendPjkp2u:[]};
}

export const getParetoData = (lastYear, dataSource, view) => {
    switch (view) {
      case "airline":{        
        return getId(dataSource, 'airlines', 'code', ['name']);
      }
        
      case "shipper":{
        return getId(dataSource, 'jpts', 'name', ['name'], (name)=>{
          const arr=name.toUpperCase().split('.').join('').split(',').join('').split('PT').join('').split(' ');
          let names=arr.map(i=>i.substring(0,1));
          return names.join('');
        });
      }
    
      case "origin":{
        return getId(dataSource, 'origins', 'code', ['kota']);
      }
      
      case 'dest':{
        return getId(dataSource, 'dests', 'code', ['kota']);
      }
      case "komoditi":{
        return getId(dataSource, 'commodities', 'commodity', []);
      }
      default:
        return {trendKgs:[], trendPjkp2u:[]};
    }
  };
