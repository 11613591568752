export const getSummaryData = (panelData) => {
  const { current, last, recovery } = panelData || {current:0, last:0, recovery:0};

  return [
    {
      // percent: numeral(percentProdKg).format('0%'),
      prev: last?.total_charge_weight || 0,
      curr: current?.total_charge_weight || 0,
      type: "production",
      title: "Production (Kg)",
    },
    {
      // percent: revPercent == "NaN" ? 0 : revPercent,
      prev: last?.total_pjkp2u || 0,
      curr: current?.total_pjkp2u,
      type: "revenue",
      title: "Revenue(IDR)",
    },
    {
      // percent: last,
      prev: recovery?.total_charge_weight || 0,
      curr: current?.total_charge_weight || 0,
      type: "production",
      title: "Prod. Recovery Rate(Kg)",
      is_recovery: true,
    },
    {
      // percent: revRecoveryRatePercent == "NaN" ? 0 : revRecoveryRatePercent,
      prev: recovery?.total_pjkp2u || 0,
      curr: current?.total_pjkp2u || 0,
      type: "revenue",
      title: "Rev. Recovery Rate(IDR)",
      is_recovery: true,
    },
    {
      // percent: prodAvgPercent == "NaN" ? 0 : prodAvgPercent,
      prev: last?.total_charge_weight / last?.total_trx || 0,
      curr: current?.total_charge_weight / current?.total_trx || 0,
      type: "production",
      title: "Avg. Prod/day(Kg)",
    },
    {
      // percent: revAvgPercent == "NaN" ? 0 : revAvgPercent,
      prev: last?.total_pjkp2u / last?.total_trx || 0,
      curr: current?.total_pjkp2u / current?.total_trx || 0,
      type: "revenue",
      title: "Avg. Rev/day(IDR)",
    },
  ];
};
