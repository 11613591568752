import {
  Drawer,
  Col,
  Image,
  Row,
  Tooltip,
  Typography,
  Button,
  Divider,
  Menu,
} from "antd";
import { useSelector } from "react-redux";
import React, { useState, useEffect, useMemo, Fragment } from "react";
import { createUseStyles } from "react-jss";
import MultiSelectComponent from "../CustomSelect/MultiSelect";
import { formatedOptions } from "../../helper/formatedOptions";
import Config from "../../config/env";
import {
  HiOutlinePresentationChartBar,
  HiOutlineDocumentAdd,
  HiOutlineHome,
} from "react-icons/hi";
import { useLocation } from "react-router-dom";
import {
  GiCommercialAirplane,
  GiBasket,
  GiPlanetConquest,
  GiBroom,
} from "react-icons/gi";
import { BsSpeedometer, BsFilter } from "react-icons/bs";
import { items } from "./items";
import { useFontSize } from "../../hooks/useDimension";
const { colorPrimary, colorSecondary } = Config;

const useStyles = createUseStyles({
  container: {
    // border: "1px solid red",
    width: "100%",
    overflowX: "hidden",
  },
  header: {
    position: "fixed",
    top: 0,
    // right: 5,
    // width: "100vw",
    // border: "1px solid red",
    height: 50,
    background: "#014f97",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 1000,
    borderRadius: "0px 0px 8px 8px",
    width: "100%",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
  },
  tabMenu: {
    borderRadius: "8px 8px 0px 0px",
    position: "fixed",
    bottom: 0,
    width: "100%",
    zIndex: 9999,
    // border: "1px solid red",
    // padding: "10px 0px",
    background: "#014f97",
  },
  iconWrapper: {
    padding: "20px",
    // border: "1px solid red",
    "&:hover": {
      background: "white",
      borderRadius: 8,
      position: "relative",
      bottom: 10,
    },
  },
  iconMenu: {
    fontSize: ({ fontSize }) => fontSize.xl,
    color: "white",
    "&:hover": {
      color: "#014f97",
      transform: " scale(1.15)",
    },
  },
  monthFilter: {
    marginTop: 20,
  },
  yearFilter: {
    marginBottom: 20,
  },
  btnScroll: {
    position: "fixed",
    bottom: 150,
    right: 20,
    zIndex: 999,
    background: "rgba(0, 100, 181, 0.38)",
    // color: "white",
    border: "none",
    width: 60,
    height: 60,
  },
  buttonFilter: {
    // border: "1px solid red",
    display: "flex",
    justifyContent: "space-between",
    height: "40px",
    gap: "10px",
    marginBottom: 20,
  },
  menuStyle: {
    // paddingTop: ({ fontSize }) => fontSize.md,
    // paddingBottom: ({ fontSize }) => fontSize.md,
    // gap: ({ fontSize }) => fontSize.md,
    // border: "1px solid red",
    background: "none",
    "&.ant-menu-dark": {
      display: "flex",
      justifyContent: "center",
      background: "none",
    },
    "& .ant-menu-item": {
      padding: "20px 20px !important",
      marginLeft: ({ fontSize }) => `${fontSize.md}px !important`,
      marginRight: ({ fontSize }) => `${fontSize.md}px !important`,
      // marginBottom: "20px !important",
      // border: "1px solid red",
      display: "flex",
      justifyContent: "center",
    },
    "&.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline": {
      // marginLeft: 4,
      background: "none",
      padding: ({ fontSize }) => fontSize.md,
    },
    "&.ant-menu-dark .ant-menu-item-selected": {
      backgroundColor: "#EDEDED",
      fontWeight: "bold",
      color: colorPrimary,
      borderRadius: "0px 0px 8px 8px",
      marginBottom: "20px",
      // padding: ({ fontSize }) => fontSize.sm,
      "&:active": {
        backgroundColor: "none",
      },
      // margin: 0
    },
    "& .ant-menu-title-content": {
      marginInlineStart: "0px !important",
    },
  },
});

const yearOptions = [
  { value: 2019, label: "2019" },
  { value: 2020, label: "2020" },
  { value: 2021, label: "2021" },
  { value: 2022, label: "2022" },
  { value: 2023, label: "2023" },
];

const monthOptions = [
  { value: 0, label: "Jan" },
  { value: 1, label: "Feb" },
  { value: 2, label: "Mar" },
  { value: 3, label: "Apr" },
  { value: 4, label: "May" },
  { value: 5, label: "Jun" },
  { value: 6, label: "Jul" },
  { value: 7, label: "Aug" },
  { value: 8, label: "Sep" },
  { value: 9, label: "Oct" },
  { value: 10, label: "Nov" },
  { value: 11, label: "Dec" },
];

function MobileLayout({ children, filterValue, onChange }) {
  const { activePage } = useSelector((state) => state.apps);
  const fontSize = useFontSize();
  const { pathname } = useLocation();
  const classes = useStyles({ fontSize });
  const [open, setOpen] = useState(false);
  const { airportAp1Cache } = useSelector((state) => state.apps);
  const [airports, setAirports] = useState([]);
  const [year, setYear] = useState([]);
  const [month, setMonth] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showComponent, setShowComponent] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  const formatedOpt = formatedOptions(airportAp1Cache, ["code"], "_id");
  const MenusItem = items({ fontSize: fontSize.xl2, isMobile: true });

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    const determineScrollDirection = () => {
      if (scrollPosition > prevScrollPosition) {
        setShowComponent(false); // Scrolling down
      } else {
        setShowComponent(true); // Scrolling up
      }
      setPrevScrollPosition(scrollPosition);
    };

    window.addEventListener("scroll", () => {
      handleScroll();
      determineScrollDirection();
    });

    return () => {
      window.removeEventListener("scroll", () => {
        handleScroll();
        determineScrollDirection();
      });
    };
  }, [scrollPosition, prevScrollPosition]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      {showComponent && (
        <div
          style={{
            width: "60px",
            height: !showMenu ? "80px" : "200px",
            position: "fixed",
            bottom: 100,
            zIndex: 9999,
            right: 20,
          }}
        >
          <Row
            style={{
              flexDirection: "column",
              gap: 10,
              alignItems: "center",
            }}
          >
            {showMenu && (
              <>
                <Col>
                  <Button
                    href="/"
                    shape="circle"
                    style={{
                      width: "50px",
                      height: "50px",
                      background: colorSecondary,
                      border: "none",
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <HiOutlineHome size={30} color="white" />
                  </Button>
                </Col>
                <Col>
                  <Button
                    shape="circle"
                    style={{
                      width: "50px",
                      height: "50px",
                      background: colorSecondary,
                      border: "none",
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <GiBroom size={30} color="white" />
                  </Button>
                </Col>
              </>
            )}
            <Col>
              <Button
                onClick={() => {
                  setShowMenu(!showMenu);
                }}
                shape="circle"
                style={{
                  width: "60px",
                  height: "60px",
                  background: colorPrimary,
                  border: "none",
                }}
              >
                <HiOutlineDocumentAdd size={40} color="white" />
              </Button>
            </Col>
          </Row>
        </div>
      )}
      <div onClick={() => setShowMenu(false)} className={classes.container}>
        {showComponent && (
          <div className={classes.header}>
            <div
              style={{
                background: "white",
                width: "50%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "0px 8px 0px 8px",
              }}
            >
              <Row style={{ alignItems: "center" }}>
                <Col>
                  <Typography
                    style={{ fontWeight: 800, fontSize: fontSize.xs }}
                  >
                    Cargo Integrated System
                  </Typography>
                </Col>
                <Divider
                  type="vertical"
                  style={{
                    borderInlineStart: "2px solid #dadada",
                    height: "2rem",
                  }}
                />
                <Col>
                  <Typography
                    style={{
                      color: "red",
                      fontSize: fontSize.xs,
                      fontWeight: 600,
                    }}
                  >
                    {activePage}
                  </Typography>
                </Col>
              </Row>
            </div>
            <div
              onClick={showDrawer}
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                right: 30,
                cursor: "pointer",
                gap: 10,
              }}
            >
              <BsFilter size={30} color="white" />
              <Typography style={{ color: "white" }}>Filter</Typography>
            </div>
          </div>
        )}
        <Drawer
          title="Filter Drawer"
          placement="right"
          onClose={onClose}
          open={open}
        >
          <div className={classes.filterContainer}>
            <div className={classes.yearFilter}>
              <Typography
                style={{ fontSize: "18px", fontWeight: 600, marginBottom: 10 }}
              >
                Airports :
              </Typography>
              <MultiSelectComponent
                className={classes.selectStyles}
                options={formatedOpt}
                selected={airports}
                onChange={(val) => {
                  setAirports(val);
                  const selected =
                    val.length !== 0 ? val.map((item) => item.value) : [];
                  onChange("airports", selected);
                }}
              />
            </div>
            <div className={classes.yearFilter}>
              <Typography
                style={{ fontSize: "18px", fontWeight: 600, marginBottom: 10 }}
              >
                Year :
              </Typography>
              <MultiSelectComponent
                hasSelectAll={false}
                className={classes.selectStyles}
                options={yearOptions}
                selected={yearOptions.filter(
                  (item) => item.value == filterValue.year
                )}
                onChange={(val) => {
                  setYear([...year, val]);
                  const selected = val[val?.length - 1];
                  onChange("year", selected.value);
                }}
              />
            </div>
            <div className={classes.monthFilter}>
              <Typography
                style={{ fontSize: "18px", fontWeight: 600, marginBottom: 10 }}
              >
                Month :
              </Typography>
              <MultiSelectComponent
                className={classes.selectStyles}
                options={monthOptions}
                selected={month}
                onChange={(val) => {
                  setMonth(val);
                  const selected = val.map((item) => item.value);
                  onChange("months", selected);
                }}
              />
            </div>
            <div className={classes.monthFilter}>
              <Typography
                style={{ fontSize: "18px", fontWeight: 600, marginBottom: 10 }}
              >
                Direction
              </Typography>
              <div className={classes.buttonFilter}>
                <Button
                  onClick={() => onChange("direction", "Inc")}
                  style={{
                    flex: 1,
                    flexGrow: 1,
                    borderRadius: 8,
                    background:
                      filterValue?.direction === "Inc"
                        ? colorSecondary
                        : "white",
                    color: filterValue?.direction === "Inc" ? "white" : "black",
                    border: `1px solid ${colorSecondary}`,
                    fontSize: fontSize.md,
                    height: "100%",
                  }}
                >
                  Inc
                </Button>
                <Button
                  onClick={() => onChange("direction", "Out")}
                  style={{
                    flex: 1,
                    flexGrow: 1,
                    borderRadius: 8,
                    background:
                      filterValue?.direction === "Out"
                        ? colorSecondary
                        : "white",
                    color: filterValue?.direction === "Out" ? "white" : "black",
                    border: `1px solid ${colorSecondary}`,
                    fontSize: fontSize.md,
                    height: "100%",
                  }}
                >
                  Out
                </Button>
              </div>
              <div className={classes.buttonFilter}>
                <Button
                  onClick={() => onChange("route", "Dom")}
                  style={{
                    flex: 1,
                    flexGrow: 1,
                    borderRadius: 8,
                    background:
                      filterValue?.route === "Dom" ? colorSecondary : "white",
                    color: filterValue?.route === "Dom" ? "white" : "black",
                    border: `1px solid ${colorSecondary}`,
                    fontSize: fontSize.md,
                    height: "100%",
                  }}
                >
                  Dom
                </Button>
                <Button
                  onClick={() => onChange("route", "Inter")}
                  style={{
                    flex: 1,
                    flexGrow: 1,
                    background: "white",
                    borderRadius: 8,
                    background:
                      filterValue?.route === "Inter" ? colorSecondary : "white",
                    color: filterValue?.route === "Inter" ? "white" : "black",
                    border: `1px solid ${colorSecondary}`,
                    fontSize: fontSize.md,
                    height: "100%",
                  }}
                >
                  Int
                </Button>
              </div>
            </div>
          </div>
        </Drawer>
        {children}
        {showComponent && (
          <div className={classes.tabMenu}>
            <Menu
              theme="dark"
              mode="horizontal"
              className={classes.menuStyle}
              defaultSelectedKeys={MenusItem[0].href}
              selectedKeys={MenusItem.map((el) => {
                if (el.href === pathname) return el.key;
              })}
              items={MenusItem}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default MobileLayout;
