import React, { Fragment, useMemo, useState } from "react";
import { Button, Spin } from "antd";
import MainChart from "@/components/Chart";
import { useFontSize } from "@/hooks/useDimension";
import Config from "@/config/env";
import { parseFormatedNumber } from "@/helper/parseFormatedNumber";
import { resetStyleTooltips } from "@/components/Chart/resetStyleTooltip";
import tooltipFormatter from "@/components/Chart/Tooltip";

const { colorPrimary, colorSecondary } = Config;

export default function TopPartner({ data, loading, isMobile = false }) {
  const [view, setView] = useState("airline");

  const dataSource = useMemo(() => {
    const dataByView = data[view] || [];
    return !!data
      ? dataByView?.map((item) => {
          return {
            name: item._id.name,
            value: item.total_charge_weight,
            totalPjkp2u: item.total_pjkp2u,
          };
        })
      : [];
  }, [data, view]);

  const total = dataSource.reduce((acc, num) => {
    return acc + num.value;
  }, 0);
  const optionTreemap = {
    tooltip: {
      trigger: "item",
      position:
        isMobile &&
        function (pos, params, dom, rect, size) {
          // tooltip will be fixed on the right if mouse hovering on the left,
          // and on the left if hovering on the right.
          var obj = { top: 60 };
          obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 5;
          return obj;
        },
      ...resetStyleTooltips,
      formatter: (params) => {
        const percentage = (params.value / total) * 100 ||0;
        return tooltipFormatter(
          params.data.name,
          params.value,
          params.data.totalPjkp2u,
          percentage.toFixed(2),
          true
        );
      },
    },
    series: [
      {
        left: 16,
        top: 16,
        right: 16,
        bottom: 16,
        type: "treemap",
        itemStyle: {
          borderColor: "white",
          borderWidth: 1,
        },
        label: {
          position: "insideTopLeft",
          align: "left",
          lineHeight: 24,
          formatter: (params) => {
            let format = [
              `{container|${params.name}}`,
              `{bottom|${parseFormatedNumber(params.value)} ( ${(
                (params.value / total) *
                100
              ).toFixed(2)}% )}`,
            ];
            return format.join("\n");
          },
          rich: {
            container: {
              fontWeight: "bold",
              // display:"flex",
              // flexDirection:"column",
              // justifyContent:"space-between"
            },
            hr: {
              // width:"100%"
            },
            bottom: {
              // textAlign: "left"
              // justifyContent:"space-between"
            },
          },
        },
        levels: [
          {
            color: [colorSecondary, colorPrimary],
            colorMappingBy: "value",
          },
        ],
        breadcrumb: {
          show: false,
        },
        data: dataSource,
      },
    ],
  };
  const fontSize = useFontSize();
  return (
    <Fragment>
      <MainChart
        fullscreen
        title={
          <Fragment>Who is our top partners? {loading && <Spin />}</Fragment>
        }
        height={fontSize.md * 19}
        onChange={(val) => setView(val)}
        view={view}
        option={optionTreemap}
        filterComp={[
          {
            Component: Button,
            title: "Airline",
            value: "airline",
          },
          {
            Component: Button,
            title: "Shipper",
            value: "jpt",
          },
          {
            Component: Button,
            title: "Airport",
            value: "airport",
          },
        ]}
      />
    </Fragment>
  );
}
