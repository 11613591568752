import React, { useEffect } from "react";
import { Row, Col } from "antd";
import CalenderChart from "../../commodity/components/CalenderChart";
import Filters from "../../commodity/components/Filters";
import TreeChart from "../../commodity/components/TreeChart";
import { useDashboardOverview } from "@/hooks/useDashboard";
import { setActivePage } from "@/redux";
import { useDispatch } from "react-redux";

function Commodity({ filterValue }) {
  const dispatch = useDispatch();
  const [dataCallender, loadingCallender] = useDashboardOverview({
    ...filterValue,
    type: "callender",
    view: "commodity",
  });

  const [commodity, kLoading] = useDashboardOverview({
    ...filterValue,
    type: "commodity",
    view: "commodity",
  });

  useEffect(() => {
    dispatch(setActivePage("Commodity"));
  }, []);

  return (
    <div id="left" style={{ height: "100%", marginTop: 50 }}>
      <Row style={{ marginBottom: 10 }}>
        <Col lg={{ span: 24 }} xs={{ span: 24 }}>
          <Filters isMobile />
        </Col>
      </Row>
      <Row
        style={{ flexDirection: "column", gap: 20, padding: 20 }}
        gutter={16}
      >
        <Col lg={{ span: 8 }}>
          <div style={{ height: "inherit" }}>
            <CalenderChart
              isMobile
              data={dataCallender}
              loading={loadingCallender}
              filterValue={filterValue}
            />
          </div>
        </Col>
        <Col lg={{ span: 16 }}>
          <TreeChart isMobile={true} data={commodity} loading={kLoading} />
        </Col>
      </Row>
    </div>
  );
}

export default Commodity;
