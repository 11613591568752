import { getDataSet } from "../getDataSet";



export const getShippingData = (dataSource, view)=>{
switch (view) {
    case "commodity":{
        return getDataSet(dataSource, 'commodities', 'commodity', []);
      }
    case "sub-commodity":{
      return getDataSet(dataSource, 'sub', '_id', []);
    }

    default:
        return {trendKgs:[], trendPjkp2u:[]};
}
} 