import { Form, Input, Radio } from "antd";
import React, { useMemo, useState } from "react";
import FormPage from "@/components/pages/FormPage";
import { useSelector } from "react-redux";

const DefModel = {
  code: "",
  name: "",
  is_dangerous: null,
};

export default () => {
  const [model, setModel] = useState(DefModel);
  const { airportCache: airport_cache } = useSelector((state) => state.apps);
  const valid = useMemo(() => {
    return true;
  }, [model]);
  return (
    <FormPage
      url={"api/v1/commodity_handling"}
      callbackPath={"master/commodity/commodities"}
      formValid={false}
      model={model}
      setModel={setModel}
      wrapperCol={{ span: 24 }}
      labelCol={{ span: 3 }}
      title={"Commodities"}
      emptyModel={DefModel}
    >
      <Form.Item name={"code"} label="Code" rules={[{ required: true }]}>
        <Input autoFocus />
      </Form.Item>

      <Form.Item name={"name"} label="Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item
        name={"is_dangerous"}
        label="Is Dangerous"
        rules={[{ required: true }]}
      >
        <Radio.Group>
          <Radio value={true}> Yes </Radio>
          <Radio value={false}> No </Radio>
        </Radio.Group>
      </Form.Item>
    </FormPage>
  );
};
