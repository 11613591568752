import MainChart from "@/components/Chart";
import { useFontSize } from "@/hooks/useDimension";
import React, { Fragment } from "react";
import Config from "@/config/env";
const { colorPrimary, colorSecondary } = Config;

function LaborProductivity({ data, loading }) {
  const fontSize = useFontSize();
  const optionLabor = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "5%",
      left: "center",
      // doesn't perfectly work with our tricks, disable it
      selectedMode: true,
      textStyle: {
        fontSize: 14,
      },
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["40%", "70%"],
        center: ["50%", "70%"],
        // adjust the start angle
        startAngle: 180,
        color: [colorPrimary, colorSecondary],
        itemStyle: {
          borderRadius: 8,
          borderColor: "transparent",
          borderWidth: 4,
        },
        label: {
          show: true,
          formatter(param) {
            // correct the percentage
            return param.name + " (" + param.percent * 2 + "%)";
          },
        },
        data: [
          { value: 1048, name: "Volume/Staff" },
          { value: 735, name: "Revenue/Staff" },
          {
            // make an record to fill the bottom 50%
            value: 1048 + 735,
            itemStyle: {
              // stop the chart from rendering this piece
              color: "none",
              decal: {
                symbol: "none",
              },
            },
            label: {
              show: false,
            },
          },
        ],
      },
    ],
  };
  return (
    <Fragment>
      <MainChart
        height={fontSize.md * 20}
        title={<Fragment>Labor Productivity</Fragment>}
        fullscreen
        option={optionLabor}
      />
    </Fragment>
  );
}

export default LaborProductivity;
