import { Col, Form, Input, Row } from "antd";
import React, { useMemo, useState } from "react";
import FormPage from "../../../../../components/pages/FormPage";
import { useSelector } from "react-redux";

const DefModel = {
    code:"",
    customer_id:"",
    name:"",    
    company_name:"",
    address:"", 
    city:"",
    npwp:"",
    channel:"",
};

export default () => {
  const [model, setModel] = useState(DefModel);
  const { airportCache: airport_cache } = useSelector((state) => state.apps);
  const valid = useMemo(() => {
    return true;
  }, [model]);
  return (
    <FormPage
      url={"api/v1/jpt"}
      callbackPath={"master/jpt"}
      formValid={false}
      model={model}
      setModel={setModel}
      wrapperCol={{ span: 24 }}
      labelCol={{ span: 24 }}
      title={"JPT"}
      emptyModel={DefModel}
    >
      <Row gutter={16}>
        <Col lg={6}>
          <Form.Item name={"code"} label="Code" rules={[{ required: true }]}>
            <Input autoFocus />
          </Form.Item>
        </Col>
        <Col lg={6}>
          <Form.Item name={"name"} label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={6}>
          <Form.Item
            name={"company_name"}
            label="Company Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={6}>
        <Form.Item
            name={"npwp"}
            label="NPWP"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={12}>
        <Form.Item
            name={"address"}
            label="Address"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={6}>
        <Form.Item
            name={"city"}
            label="City"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={6}>
        <Form.Item
            name={"channel"}
            label="Channel"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </FormPage>
  );
};
