import React from 'react'
import TablePage from '../../../../../components/pages/TablePage'
import moment from 'moment'
export default () => {
    const columns=[
        {
            selector:(row)=>row?.customer_id,
            name:"Customer ID",
            id:'customer_id'
        },
        {
            selector:(row)=>row?.code,
            name:"Code",
            id:'code',
            width:"100px"
        },
        {
            selector:(row)=>row?.name,
            name:"Name",
            id:'name',
            width:"200px"

        },
        
        // {
        //     selector:(row)=>row?.company_name,
        //     name:"Company Name",
        //     id:'company_name'
        // },
        {
            selector:(row)=>row?.address,
            name:"Address",
            id:'address',
            wrap:true,
            width:"200px"
        },
        {
            selector:(row)=>row?.city,
            name:"City",
            id:'city'
        },
        {
            selector:(row)=>row?.npwp,
            name:"NPWP",
            id:'npwp',
            right:true
        },
        {
            selector:(row)=>row?.channel,
            name:"Channel",
            id:'channel'
        },
        // {
        //     selector:(row)=>(row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
        //     name:"CreatedAt",
        //     id:'createdAt'
        // },
    ]
    return (
        <TablePage
            title={"Master JPT"}
            url="api/v1/jpt"
            actionPosition={'first'}
            createPath="create"
            editPath={"edit"}
            columns={columns}            
        />
    )
}