import { Col, Divider, Row,Spin, Tag, Typography } from "antd";
import React, { Fragment } from "react";
import { parseFormatedNumber } from "@/helper/parseFormatedNumber";
import numberSparator from "@/helper/numberSparator";
import Config from "@/config/env";
import MainChart from "@/components/Chart";
import { useFontSize } from "@/hooks/useDimension";
import { useState } from "react";
import { MdOutlineTrendingDown, MdOutlineTrendingUp } from "react-icons/md";
import MultiSelectComponent from "@/components/CustomSelect/MultiSelect";
import { getTrendComparative } from "@/helper/airport/getTrendComparative";
import { resetStyleTooltips } from "@/components/Chart/resetStyleTooltip";

const { colorPrimary, colorSecondary } = Config;

const comparativeOpt = [
  {
    label: "Target",
    value: "target",
  },
  {
    label: "Previous Year",
    value: "prev-year",
  },
];

function ProductionCard({ data, loading, summary }) {
  const fontSize = useFontSize();
  const [comparativeVal, setComparativeValue] = useState("prev-year");

  const trendKgs =
    getTrendComparative("production", data, comparativeVal) || [];
  const comparatorTrend = trendKgs.map((item) => item[comparativeVal]);

  const getDiff = (summary) =>
    summary?.current?.total_charge_weight - summary?.last?.total_charge_weight || 0;
  const getPercent = (curr, prev) => ((curr / prev) * 100 || 0).toFixed(2);
  const getPercentAvg = (curr, prev) =>
    (((curr - prev) / prev) * 100 || 0).toFixed(2) ;
  const getAvg = (val, total) => val / total || 0;

  const optionMonthly = {
    grid: {
      x: fontSize.md,
      x2: fontSize.md,
      y: fontSize.xl * 2,
      y2: fontSize.xl,
    },
    xAxis: {
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      type: "category",
      data: trendKgs.map((item) => item.name),
    },
    yAxis: {
      type: "value",
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    tooltip: {
      ...resetStyleTooltips,
      trigger: "axis",
      formatter: (data) => {
        return `
        <div class="main-tooltip">
          <div style="margin-bottom:4px;">
           <strong>${data[0].name}</strong>
          </div>
          <div class="container-tooltip">
            <div class="item-tooltip">
              <div>
              ${data[0].marker} ${data[0].seriesName} Kg 
              </div>
              <div class="text-right text-bold">
              ${numberSparator(data[0].value)}
              </div>
            </div>
            <div class="item-tooltip">
              <div class="first-Uppercase">
              ${data[1].marker} ${data[1].seriesName} Kg 
              </div>
              <div class="text-right text-bold">
              ${numberSparator(comparatorTrend[data[1].dataIndex])}
              </div>
            </div>
          </div>
        </div>
        `;
      },
    },
    series: [
      {
        data: trendKgs.map((item) => item.curr),
        type: "line",
        name: "Grand Total",
        color: colorPrimary,
        stack: "total",
        symbol: "circle",
        symbolSize: 6,
        emphasis: {
          focus: "series",
        },
        label: {
          fontSize: 10,
          fontWeight: "bold",
          show: true, // Show labels on the spot
          position: "top", // Set label position as 'top'
          offset: [0, -16],
          color: colorPrimary,
          formatter: (data) => {
            return `${parseFormatedNumber(data.value) !== 0 ? parseFormatedNumber(data.value):""}`;
          },
        },
      },
      {
        z: -1,
        data: trendKgs.map((item) => item.diff),
        emphasis: {
          focus: "series",
        },
        type: "line",
        name: comparativeVal.replace("-", " "),
        stack: "total",
        color: comparativeVal ==="target" ? "#FF7000" : colorSecondary,
        lineStyle: {
          type: "dashed",
          opacity: 0.4,
        },
        stackStrategy: "all",
        areaStyle: {
          opacity: 0.1,
        },
        label: {
          fontSize: 10,
          show: true, // Show labels on the spot
          position: "bottom", // Set label position as 'top'
          offset: [0, 24],
          color: comparativeVal ==="target" ? "#FF7000" : colorSecondary,
          formatter: (data) => {
            return `${parseFormatedNumber(comparatorTrend[data.dataIndex]) !==0 ? parseFormatedNumber(comparatorTrend[data.dataIndex]) : ""}`;
          },
        },
      },
    ],
  };

  return (
    <MainChart
      title={<Fragment>Production {!!loading && <Spin />}</Fragment>}
      option={optionMonthly}
      height={fontSize.md * 14}
      fullscreen
      otherComponent={() => (
        <Row
          gutter={[16, 16]}
          style={{ padding: "4px 16px", alignItems: "center" }}
        >
          <Col style={{ flexGrow: 1 }}>
            <label style={{ fontWeight: "bold" }}>Comparative Value</label>
            <div style={{ width: 200, height: "100%", marginTop: 8 }}>
              <MultiSelectComponent
                hasSelectAll={false}
                options={comparativeOpt}
                selected={comparativeOpt.filter(
                  (item) => item.value == comparativeVal
                )}
                onChange={(val) => {
                  if (val.length === 0) {
                    setComparativeValue(comparativeVal);
                  } else {
                    const selected = val[val?.length - 1];
                    setComparativeValue(selected.value || comparativeVal);
                  }
                }}
              />
            </div>
          </Col>
          <Divider
            type="vertical"
            style={{
              margin: 8,
              height: "3rem",
              border: "1px solid #dadada",
              background: "red",
            }}
          />
          <Col style={{ flexGrow: 1 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Tag
                bordered={false}
                icon={
                  getPercent(
                    getDiff(summary),
                    summary?.last?.total_charge_weight
                  ) > 0 ? (
                    <MdOutlineTrendingUp size={fontSize.sm} />
                  ) : getPercent(
                      getDiff(summary),
                      summary?.last?.total_charge_weight
                    ) < 0 ? (
                    <MdOutlineTrendingDown size={fontSize.sm} />
                  ) : (
                    false
                  )
                }
                color={
                  getPercent(
                    getDiff(summary),
                    summary?.last?.total_charge_weight
                  ) > 0
                    ? "green"
                    : getPercent(
                        getDiff(summary),
                        summary?.last?.total_charge_weight
                      ) < 0
                    ? "red"
                    : "gray"
                }
                style={{
                  fontWeight: "bold",
                  fontSize: fontSize.xs,
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                  width: "max-content",
                }}
              >
                {getPercent(
                  getDiff(summary),
                  summary?.last?.total_charge_weight
                )}{" "}
                | {parseFormatedNumber(getDiff(summary))}
              </Tag>
              <Typography style={{ fontSize: fontSize.md, fontWeight: "bold" }}>
                {parseFormatedNumber(
                  summary?.current?.total_charge_weight || 0
                )}
              </Typography>
            </div>
            <Typography style={{ fontSize: fontSize.sm, color: "gray" }}>
              Total Production (ton)
            </Typography>
          </Col>
          <Divider
            type="vertical"
            style={{
              margin: 8,
              height: "3rem",
              border: "1px solid #dadada",
              background: "red",
            }}
          />
          <Col style={{ flexGrow: 1 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Tag
                bordered={false}
                icon={
                  getPercentAvg(
                    getAvg(
                      summary?.current?.total_charge_weight,
                      summary?.current?.total_trx
                    ),
                    getAvg(
                      summary?.last?.total_charge_weight,
                      summary?.last?.total_trx
                    )
                  ) > 0 ? (
                    <MdOutlineTrendingUp size={fontSize.sm} />
                  ) : getPercentAvg(
                      getAvg(
                        summary?.current?.total_charge_weight,
                        summary?.current?.total_trx
                      ),
                      getAvg(
                        summary?.last?.total_charge_weight,
                        summary?.last?.total_trx
                      )
                    ) < 0 ? (
                    <MdOutlineTrendingDown size={fontSize.sm} />
                  ) : (
                    false
                  )
                }
                color={
                  getPercentAvg(
                    getAvg(
                      summary?.current?.total_charge_weight,
                      summary?.current?.total_trx
                    ),
                    getAvg(
                      summary?.last?.total_charge_weight,
                      summary?.last?.total_trx
                    )
                  ) > 0
                    ? "green"
                    : getPercentAvg(
                        getAvg(
                          summary?.current?.total_charge_weight,
                          summary?.current?.total_trx
                        ),
                        getAvg(
                          summary?.last?.total_charge_weight,
                          summary?.last?.total_trx
                        )
                      ) < 0
                    ? "red"
                    : "gray"
                }
                style={{
                  fontWeight: "bold",
                  fontSize: fontSize.xs,
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                  width: "max-content",
                }}
              >
                {getPercentAvg(
                  getAvg(
                    summary?.current?.total_charge_weight,
                    summary?.current?.total_trx
                  ),
                  getAvg(
                    summary?.last?.total_charge_weight,
                    summary?.last?.total_trx
                  )
                )}{" "}
                |{" "}
                {parseFormatedNumber(
                  getAvg(
                    summary?.current?.total_charge_weight,
                    summary?.current?.total_trx
                  ) -
                    getAvg(
                      summary?.last?.total_charge_weight,
                      summary?.last?.total_trx
                    )
                )}
              </Tag>
              <Typography style={{ fontSize: fontSize.md, fontWeight: "bold" }}>
                {parseFormatedNumber(
                  getAvg(
                    summary?.current?.total_charge_weight,
                    summary?.current?.total_trx
                  )
                )}
              </Typography>
            </div>
            <Typography style={{ fontSize: fontSize.sm, color: "gray" }}>
              Avg. Production/day
            </Typography>
          </Col>
        </Row>
      )}
    />
  );
}

export default ProductionCard;
