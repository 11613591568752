import React from 'react'
import TablePage from '@/components/pages/TablePage'
import moment from 'moment'
export default () => {
    const columns=[
        {
            selector:(row)=>row?.airport_id?.code,
            name:"Airport Code",
            id:'airport_id',
            compact:true
        },
        {
            selector:(row)=>row?.airport_id?.name,
            name:"Airport Name",
            id:'airport_id'
        },
        {
            selector:(row)=>row?.tahun,
            name:"Year",
            id:'tahun',
            center:true
        },
        {
            selector:(row)=>row?.bidang,
            name:"Bidang",
            id:'bidang'
        },
        {
            selector:(row)=>row?.instansi,
            name:"Iinstansi",
            id:'instansi'
        },
        {
            selector:(row)=>row?.total_staff,
            name:"Total Staff",
            id:'total_staff',
            right:true
        },
        {
            selector:(row)=>row?.total_staff_daily,
            name:"Total Staff Daily",
            id:'total_staff_daily',
            right:true
        },
        
        // {
        //     selector:(row)=>(row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
        //     name:"CreatedAt",
        //     id:'created_at'
        // },
    ]
    return (
        <TablePage
            title={"Master Staff"}
            url="api/v1/staff"
            actionPosition={'first'}
            createPath="create"
            editPath={"edit"}
            columns={columns}        
        />
    )
}