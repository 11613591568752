import { RiHome2Line } from "react-icons/ri";
import { CgDatabase } from "react-icons/cg";
import { Link } from "react-router-dom";
import { HiOutlineDatabase, HiOutlineHome } from "react-icons/hi";
import { RxDotFilled } from "react-icons/rx";
import { HiOutlineBuildingStorefront, HiOutlineMinusSmall } from "react-icons/hi2";
const SIZE = 20;
export default (languages) => [
  {
    key: "home",
    icon: <HiOutlineHome size={SIZE} />,
    label: <Link to={"home"}>Home</Link>,
    level: 0x1ff0,
  },
  {
    key: "master",
    icon: <HiOutlineDatabase size={SIZE} />,
    label: "Master",
    level: 0x1ff0,
    children: [
      {
        key: "master/airports",
        icon: <RxDotFilled size={SIZE} />,
        label: <Link to={"master/airports"}>Airports</Link>,
        level: 0x1ff0,
      },
      {
        key: "master/airlines",
        icon: <RxDotFilled size={SIZE} />,
        label: <Link to={"master/airlines"}>Airlines</Link>,
        level: 0x1ff0,
      },
      {
        key: "master/operated_airport",
        icon: <RxDotFilled size={SIZE} />,
        label: <Link to={"master/operated_airport"}>Operated Airport</Link>,
        level: 0x1ff0,
      },
      {
        key: "master/commodity",
        icon: <RxDotFilled size={SIZE} />,
        label: "Commodity",
        level: 0x1ff0,
        children:[
          
          {
            key: "master/commodity/shc",
            icon: <HiOutlineMinusSmall size={SIZE} />,
            label: <Link to={"master/commodity/shc"}>SHC</Link>,
            level: 0x1ff0,
          },
          {
            key: "master/commodity/category",
            icon: <HiOutlineMinusSmall size={SIZE} />,
            label: <Link to={"master/commodity/category"}>Category</Link>,
            level: 0x1ff0,
          },
          {
            key: "master/commodity/Goods",
            icon: <HiOutlineMinusSmall size={SIZE} />,
            label: <Link to={"master/commodity/Goods"}>Goods</Link>,
            level: 0x1ff0,
          },
          {
            key: "master/commodity/hscode",
            icon: <HiOutlineMinusSmall size={SIZE} />,
            label: <Link to={"master/commodity/hscode"}>HS Code</Link>,
            level: 0x1ff0,
          },
         
         
        ]
      },
      
      {
        key: "master/cto",
        icon: <RxDotFilled size={SIZE} />,
        label: <Link to={"master/cto"}>CTO</Link>,
        level: 0x1ff0,
      },
      {
        key: "master/jpt",
        icon: <RxDotFilled size={SIZE} />,
        label: <Link to={"master/jpt"}>JPT</Link>,
        level: 0x1ff0,
      },
      {
        key: "master/staff",
        icon: <RxDotFilled size={SIZE} />,
        label: <Link to={"master/staff"}>Staff</Link>,
        level: 0x1ff0,
      },
      {
        key: "master/users",
        icon: <RxDotFilled size={SIZE} />,
        label: <Link to={"master/users"}>Users</Link>,
        level: 0x1ff0,
      },
    ],
  },
  {
    key: "cabang-params",
    icon: <HiOutlineBuildingStorefront size={SIZE} />,
    label: <Link to={"cabang-params"}>Cabang</Link>,
    level: 0x1ff0,
  },
];
