import { Col, Row, Spin, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useFontSize, useHeightCard } from "../../hooks/useDimension";
import Config from "../../config/env";
import numberSparator from "../../helper/numberSparator";
import { BiChevronsDown, BiChevronsUp } from "react-icons/bi";

const useStyles = createUseStyles({
  container: {
    // border: '1px solid',
    width: "100%",
    borderRadius: 8,
    background: "white",
    // padding: 8
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
  },
  cardHeader: {
    textAlign: "center",
    background: Config.colorPrimary,
    borderRadius: "8px 8px 0px 0px",
    padding: 4,
  },
  titleHeader: {
    color: "white",
    fontWeight: "bold",
    // height: 15,
    fontSize: ({fontSize})=>fontSize.sm,
  },
  cardBody: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: ({fontSize})=>fontSize.lg,
    // height: '30px',
  },
});

function ShippingCard({ title, body, data, loading }) {
  const fontSize = useFontSize()
  const classes = useStyles({fontSize});
  const heightCard = useHeightCard("30px", "40px", "45px", "55px");

  return (
    <div className={classes.container}>
      <div className={classes.cardHeader}>
        <Typography className={classes.titleHeader}>{title}</Typography>
      </div>
      <div style={{ height: heightCard }} className={classes.cardBody}>
        <Typography style={{ fontWeight: "bold", fontSize: "inherit" }}>{body}</Typography> { loading && <Spin />}
      </div>
    </div>
  );
}

export default ShippingCard;
