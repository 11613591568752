import { Col, Form, Input, InputNumber, Row, Select } from "antd";
import React, { useMemo, useState } from "react";
import FormPage from "@/components/pages/FormPage";
import AutoComplete from "@/components/autocomplete/Offline";
import AutoCompleteOnline from "@/components/autocomplete/Online";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
const DefModel = {
  code: "",
  icao_code: "",
  name: "",
  kota: "",
  country: "",
  lat: "",
  lon: "",
  timezone: "",
  unix_offset: 0,
};

export default () => {
  const [model, setModel] = useState(DefModel);
  const tz = useMemo(() => moment.tz.names(), []);
  const tzs = useMemo(() =>
    tz.map((item) => {
      return { label: item, value: item };
    })
  );

  return (
    <FormPage
      url={"api/v1/airports"}
      callbackPath={"master/airports"}
      formValid={false}
      model={model}
      setModel={setModel}
      wrapperCol={{ span: 24 }}
      labelCol={{ span: 12 }}
      title={"Airport"}
      emptyModel={DefModel}
    >
      <Row gutter={32}>
        <Col lg={8}>
          <Form.Item name={"name"} label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={8}>
          <Form.Item
            name={"code"}
            label="IATA Code"
            rules={[{ required: true }]}
          >
            <Input autoFocus />
          </Form.Item>
        </Col>
        <Col lg={8}>
          <Form.Item name={"icao_code"} label="ICAO Code">
            <Input />
          </Form.Item>
        </Col>
        <Col lg={8}>
          <Form.Item name={"kota"} label="City">
            <Input />
          </Form.Item>
        </Col>
        <Col lg={8}>
          <Form.Item
            name={"country"}
            label="Country"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={8}>
          <Form.Item
            name={"timezone"}
            label="Timezone"
            rules={[{ required: true }]}
          >
            {/* <AutoComplete 
                    data={tzs}
                    value={model.timezone}
                    getKey={(tz)=>tz}
                    getSelectedOptions={(opt, val)=>opt===val?.name}
                    getValues={(opt)=>opt.name}
                    onChange={(val)=>{
                        const offset=moment().tz(val?.name)._offset;
                        const unix_offset=(offset && offset * 60)||0;
                        setModel({...model, timezone:val?.name, unix_offset})
                    }}
                /> */}
            <Select
              showSearch
              style={{ width: "100%" }}
              value={model.timezone}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return input.length > 3
                  ? (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  : false;
              }}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={tzs}
            />
          </Form.Item>
        </Col>
        <Col lg={12}>
          <Form.Item
            name={"lat"}
            label="Latitude"
            rules={[{ required: true }]}
            style={{ width: "100%" }}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={12}>
          <Form.Item
            style={{ width: "100%" }}
            name={"lon"}
            label="Longitude"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </FormPage>
  );
};
