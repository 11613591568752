export const getDataSet = (
  data_source,
  view,
  id,
  fields = [],
  parseId = false
) => {
  if (!data_source || !data_source[view])
    return { trendKgs: [], trendPjkp2u: [] };
  const idx = [];
  const trendKgs = [];
  for (let iii = 0; iii < data_source[view].length; iii++) {
    const item = data_source[view][iii];
    const {
      _id,
      total_charge_weight: totalChw,
      total_pjkp2u: totalPjkp2u,
    } = item;
    const other = [];
    for (let iii = 0; iii < fields.length; iii++) {
      const fn = fields[iii];
      other.push(_id[fn]);
    }
    if (id === "_id") {
      idx.push(_id);
      trendKgs.push([totalChw, totalPjkp2u, _id, ...other]);
    } else {
      let code = !!parseId ? parseId(_id[id]) : _id[id];
      const i = idx.indexOf(code);
      if (i >= 0) {
        code = idx[i] + "_";
      }
      idx.push(code);
      trendKgs.push([totalChw, totalPjkp2u, code, ...other]);
    }
  }

  const dataset = [
    {
      dimensions: ["kgs", "pjkp2u", "code", ...fields],
      source: trendKgs,
    },
    {
      transform: {
        type: "sort",
        config: { dimension: "kgs", order: "asc" },
      },
    },
  ];
  return { trendKgs: dataset, trendPjkp2u: [] };
};
