import { Col, Row, Typography } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import Config from "../../config/env";
import formatNumberToK from "../../helper/formatNumber";
import { BiChevronsDown, BiChevronsUp } from "react-icons/bi";
const { Theme } = Config;

const useStyles = createUseStyles({
  container: {
    padding: "0px 20px",
  },
  titleWrapper: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  titleStyle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#094780",
  },
  qtyStyle: {
    fontSize: 25,
    fontWeight: "bold",
    lineHeight: "30px",
  },
  descWrapper: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  iconWrapper: {
    background: "#5CD7F2",
    padding: "5px",
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
  },
});

const SummaryCardV2 = ({
  title,
  current,
  prev,
  isBorder = true,
  icon,
  ...props
}) => {
  const classes = useStyles(props);
  const selisih = current - prev;
  const persentase = !!selisih ? (selisih / prev) * 100 : 0;

  return (
    <div
      className={classes.container}
      style={{
        borderRight: isBorder && "2px solid #DBDADA",
      }}
    >
      <Row className={classes.titleWrapper}>
        <Col>
          <Typography className={classes.titleStyle}>{title}</Typography>
        </Col>
      </Row>
      <Row>
        <Typography
          style={{ color: selisih === 0 ? "" : selisih > 0 ? "green" : "red" }}
          className={classes.qtyStyle}
        >
          {formatNumberToK(current)}
        </Typography>
      </Row>
      <Row className={classes.descWrapper}>
        <Col>
          <Row>
            <Typography style={{ fontSize: 15, fontWeight: "bold" }}>
              {formatNumberToK(prev)}
            </Typography>
            {selisih === 0 ? (
              <div style={{ paddingLeft: 4 }}></div>
            ) : selisih > 0 ? (
              <BiChevronsUp size={15} style={{ color: "green" }} />
            ) : (
              <BiChevronsDown size={15} style={{ color: "red" }} />
            )}
            <Typography
              style={{
                color: selisih === 0 ? "" : selisih > 0 ? "green" : "red",
                fontWeight: 600,
              }}
            >
              {selisih > 0 ? "+" : ""}
              {persentase.toFixed(2)}%
            </Typography>
          </Row>
        </Col>
        {!!icon && <Col className={classes.iconWrapper}>{icon}</Col>}
      </Row>
    </div>
  );
};

export default SummaryCardV2;
