import { Col, Row, Spin, Typography } from "antd";
import React, { useMemo, useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { createUseStyles } from "react-jss";
import Filters from "../../stakeholder/components/FiltersStakeholder";
import CardSummary from "../../stakeholder/components/CardSummary";
import RouteComparison from "../../stakeholder/components/RouteComparison";
import TopPartner from "../../stakeholder/components/TopPartners";
import TopPartnerBar from "../../stakeholder/components/TopPartnersBar";
import { useDashboardOverview } from "@/hooks/useDashboard";
import Config from "@/config/env";
import { setActivePage } from "@/redux";
import { useFontSize } from "@/hooks/useDimension";
import CardmapsV2 from "../../stakeholder/components/CardmapsV2";

const { colorPrimary } = Config;

const useStyles = createUseStyles({
  container: {
    padding: "60px 0px 100px 0px",
    // border: "1px solid red",
    // height: "100%",
  },
  select: {
    // zIndex: 9999,
    "& .dropdown-container": {
      borderRadius: 8,
      position: "relative",
      zIndex: 9999,
      width: "200px",
    },
  },
});

const defValue = {
  airlines: "",
  agents: "",
  jaster: "",
};

function Stakeholders({ filterValue }) {
  const [map, mLoading] = useDashboardOverview({
    ...filterValue,
    type: "map",
    view: "stakeholder",
  });
  const classes = useStyles();
  const fontSize = useFontSize();
  const [origin, setOrigin] = useState(map.incoming || []);
  const [dest, setDest] = useState(map.outgoing || []);
  const [state, setState] = useState(defValue);
  const [route, rLoading] = useDashboardOverview({
    ...filterValue,
    type: "route",
    view: "stakeholder",
  });
  const [panel, pLoading] = useDashboardOverview({
    ...filterValue,
    type: "panel",
    view: "stakeholder",
  });
  const [demograph, dLoading] = useDashboardOverview({
    ...filterValue,
    type: "demograph",
    view: "stakeholder",
  });
  const [commodities, cLoading] = useDashboardOverview({
    ...filterValue,
    type: "commodity",
    view: "stakeholder",
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActivePage("Stakeholders"));
    // setOrigin(map?.incoming);
  }, []);

  const handleOnChange = (type, value) => {
    switch (type) {
      case "airlines":
        setState((state) => ({ ...state, airlines: value }));
        break;

      default:
        break;
    }
  };

  const optionSelect = useMemo(() => {
    const originOptions =
      !!map &&
      map?.incoming.map((item) => {
        const newObj = {
          label: item.airport.name,
          value: item,
        };
        return newObj;
      });
    const destOptions =
      !!map &&
      map?.outgoing.map((item) => {
        const newObj = {
          label: item.airport.name,
          value: item,
        };
        return newObj;
      });
    return { originOptions, destOptions };
  }, [origin, map]);

  // console.log({
  //   origin: optionSelect?.origin.map((item) => {
  //     return item.value;
  //   }),
  // });
  const mapDataIncoming = useMemo(() => {
    const container = origin.map((item) => {
      return item.value;
    });
    return container;
  }, [origin]);

  const mapDataOutgoing = useMemo(() => {
    const container = dest.map((item) => {
      return item.value;
    });
    return container;
  }, [dest]);

  return (
    <div className={classes.container}>
      <Row style={{ flexDirection: "column" }}>
        <Col sm={{ span: 24 }} style={{ marginBottom: 20 }}>
          <Filters
            isMobile
            onChange={handleOnChange}
            state={state}
            data={panel}
          />
        </Col>
        <Col sm={{ span: 24 }}>
          <div style={{ height: "inherit", padding: "0px 10px" }}>
            <CardSummary isMobile data={panel} loading={pLoading} />
          </div>
        </Col>
        <Col sm={{ span: 24 }}>
          <div style={{ height: "inherit", padding: 10 }}>
            <RouteComparison data={route} loading={rLoading} />
          </div>
        </Col>
        <Col sm={{ span: 24 }} style={{ height: "inherit", padding: 10 }}>
          <div style={{ height: "300px" }}>
            <TopPartner isMobile data={demograph} loading={dLoading} />
          </div>
        </Col>
        <Col sm={{ span: 24 }} style={{ height: "inherit", padding: 10 }}>
          <div style={{ height: "400px" }}>
            <TopPartnerBar data={commodities} loading={cLoading} />
          </div>
        </Col>
        <Col sm={{ span: 24 }} style={{ height: "inherit", padding: 10 }}>
          <div style={{ height: "400px" }}>
            <CardmapsV2
              disabled={mLoading}
              options={optionSelect.originOptions}
              value={origin}
              setValue={setOrigin}
              title={<Fragment>Origin Route {mLoading && <Spin />}</Fragment>}
              mapData={origin.length === 0 ? map?.incoming : mapDataIncoming}
            />
          </div>
        </Col>
        <Col
          sm={{ span: 24 }}
          style={{ height: "inherit", padding: 10, marginTop: 0 }}
        >
          <div style={{ height: "400px" }}>
            <CardmapsV2
              disabled={mLoading}
              options={optionSelect.destOptions}
              value={dest}
              setValue={setDest}
              title={
                <Fragment>Destination Route {mLoading && <Spin />}</Fragment>
              }
              mapData={dest.length === 0 ? map?.outgoing : mapDataOutgoing}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Stakeholders;
