import React, { Fragment } from "react";
import CardMaps from "@/components/Maps/CardMaps";
import { Col, Divider, Row, Spin, Typography } from "antd";
import { useFontSize } from "@/hooks/useDimension";

import Config from "@/config/env";

const { colorPrimary } = Config;

export default function ShippingMapV2({
  mapData,
  connectedData,
  connectedLoading,
  loadingMap,
  isMobile = false,
}) {
  const fontSize = useFontSize();
  return (
    <CardMaps
      isMobile={isMobile}
      data={mapData}
      fullscreen
      title={
        <Fragment>
          Shipping Volumes by Destination Route {loadingMap && <Spin />}
        </Fragment>
      }
      height={isMobile ? "65%" : "100%"}
      otherComponent={() => {
        return (
          <Row
            gutter={[16, 16]}
            style={{
              padding: 4,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Col style={{ flex: 1 }}>
              <Typography style={{ fontSize: fontSize.md, fontWeight: "bold" }}>
                {connectedData?.connected_city || 0}{" "}
                {connectedLoading && <Spin />}
              </Typography>
              <Typography style={{ fontSize: fontSize.sm, color: "gray" }}>
                Connected City
              </Typography>
            </Col>
            <Divider
              type="vertical"
              style={{
                margin: 8,
                height: "3rem",
                border: "1px solid #dadada",
                background: "red",
              }}
            />
            <Col style={{ flex: 1 }}>
              <Typography style={{ fontSize: fontSize.md, fontWeight: "bold" }}>
                {connectedData?.dom_route || 0} {connectedLoading && <Spin />}
              </Typography>
              <Typography style={{ fontSize: fontSize.sm, color: "gray" }}>
                Domestic Route
              </Typography>
            </Col>
            <Divider
              type="vertical"
              style={{
                margin: 8,
                height: "3rem",
                border: "1px solid #dadada",
                background: "red",
              }}
            />
            <Col style={{ flex: 1 }}>
              <Typography style={{ fontSize: fontSize.md, fontWeight: "bold" }}>
                {connectedData?.international_route || 0}{" "}
                {connectedLoading && <Spin />}
              </Typography>
              <Typography style={{ fontSize: fontSize.sm, color: "gray" }}>
                International Route
              </Typography>
            </Col>
            <Divider
              type="vertical"
              style={{
                margin: 8,
                height: "3rem",
                border: "1px solid #dadada",
                background: "red",
              }}
            />
            <Col style={{ flex: 1 }}>
              <Typography style={{ fontSize: fontSize.md, fontWeight: "bold" }}>
                {connectedData?.connected_continent || 0}{" "}
                {connectedLoading && <Spin />}
              </Typography>
              <Typography style={{ fontSize: fontSize.sm, color: "gray" }}>
                Connected Continent
              </Typography>
            </Col>
          </Row>
        );
      }}
    />
  );
}
