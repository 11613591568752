import { Col, Radio, Row, Skeleton, Typography } from "antd";
import React, { Fragment, useState } from "react";
import ReactECharts from "echarts-for-react";

import ArrowDown from "../../assets/images/arrowDown.png";
import ArrowUp from "../../assets/images/arrowUp.png";
import Stuck from "../../assets/images/stuck.png";
import MainCard from "../../components/MainCard";
import { usePostApi } from "../../hooks/usePostApi";
import Config from "../../config/env";
import { parseFormatedNumber } from "../../helper/parseFormatedNumber";
import { createUseStyles } from "react-jss";

const { colorPrimary } = Config;

const useStyles = createUseStyles({
  radioStyle: {
    "& .ant-radio-button-wrapper": {
      borderRadius: "4px !important",
      background: "white !important",
      width: "6.25rem",
      // borderRadius: "0.5rem",
      marginRight: "0.5rem",
      color: colorPrimary,
      fontWeight: 600,
      textAlign: "center",
      fontSize: "0.8rem",
      letterSpacing: 0,
    },
    "& .ant-radio-button-checked": {
      background: `${colorPrimary} !important`,
    },
  },
});

const tabOptions = [
  { label: "AIRPORT", value: "airport" },
  { label: "AIRLINE", value: "airline" },
  { label: "EMPU", value: "empu", disabled: true },
];

export default function Pareto({ filterValue, className }) {
  const [tab, setTab] = useState("airport");

  const classes = useStyles();

  const { result: pareto, loading } = usePostApi({
    url: "api/dashboard/overview",
    data: { ...filterValue, type: `pareto ${tab}` },
  });

  const optionPareto = {
    grid: {
      x: 120,
      x2: 60,
      y: 20,
      y2: 20,
    },
    xAxis: {
      show: false,
      // data: ['UPG', 'BPN', 'BDJ', 'CGK', 'SUB', 'MDC', 'SIN', 'BTH', 'KNO', 'PNK']
    },
    yAxis:
      // {
      //   type: "category",
      //   data: [
      //     "UPG",
      //     "BPN",
      //     "BDJ",
      //     "CGK",
      //     "SUB",
      //     "MDC",
      //     "SIN",
      //     "BTH",
      //     "KNO",
      //     "PNK",
      //   ],
      // },
      [
        {
          // type: 'value',
          type: "category",
          position: "left",
          data: pareto?.standings || [],
          axisLabel: {
            align: "left",
            margin: 90,
            formatter: (data) => {
              const dataSplited = data.split("/");
              const [val, status, num] = dataSplited;
              // return `${val} {${status}|}{${status}Text| ${num || ""}}`
              return `{val|${val}} {${status}|}{${status}Text| ${num || ""}}`;
            },
            rich: {
              val: {
                width: 30,
              },

              down: {
                // color: 'red',
                // lineHeight: 10,
                height: 8,
                margin: "0px 4px",
                backgroundColor: {
                  image: ArrowDown,
                },
              },
              downText: {
                color: "red",
              },
              up: {
                // color: 'red',
                // lineHeight: 10,
                height: 8,
                margin: "0px 4px",
                backgroundColor: {
                  image: ArrowUp,
                },
              },
              upText: {
                color: "green",
              },
              stuck: {
                // color: 'red',
                // lineHeight: 10,
                width: 16,
                height: 4,
                margin: "0px 4px",
                backgroundColor: {
                  image: Stuck,
                },
              },
            },
          },
        },
      ],
    tooltip: {
      trigger: "axis",
      formatter: function (params) {
        // console.log(params);
        const [name, condition, position] = params[0]?.name.split("/");
        // console.log({ name, condition, position });
        return `<strong>Production Total</strong><br />
            <strong>${name} : </strong> ${params[0]?.value} (${condition} ${
          condition === "stuck" ? "" : position + " " + "positions"
        })
				  `;
      },
    },
    series: [
      {
        name: "category",
        type: "bar",
        yAxisIndex: 0,
        data: pareto?.totalList?.sort((a, b) => a - b) || [],
      },
      // {
      //   data: [100, 220, 264, 228, 165, 117, 210, 224, 123, 567],
      //   type: 'bar'
      // }
    ],
  };

  return (
    <Fragment>
      {!!loading ? (
        Array(1)
          .fill(0)
          .map((item, idx) => (
            <Skeleton.Button
              key={idx}
              block
              style={{ height: "15rem" }}
              active={!!loading}
            />
          ))
      ) : (
        <MainCard>
          <div
            style={{
              padding: "16px 16px 0px 16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
                color: colorPrimary,
              }}
            >
              Pareto Analysis
            </Typography>
            <Row style={{ justifyContent: "center" }}>
              <Col>
                <Radio.Group
                  size="small"
                  defaultValue="airport"
                  className={classes.radioStyle}
                  options={tabOptions}
                  onChange={({ target: { value } }) => {
                    setTab(value);
                  }}
                  value={tab}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Col>
            </Row>
          </div>
          <ReactECharts
            style={{ width: "100%", height: "12.8rem" }}
            option={optionPareto}
          />
        </MainCard>
      )}
    </Fragment>
  );
}
